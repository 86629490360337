/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import MaterialRoot from 'components/materialTheme/MaterialRoot'

import TermsOfUseAvailabilityCheck from './TermsOfUseAvailabilityCheck'

const root = createRoot(document.getElementById('termsOfUseAvailabilityCheck'))
root.render(
    <MaterialRoot seed="terms_of_use_availability_check">
        <TermsOfUseAvailabilityCheck userIsStaff={window.USER_IS_STAFF} />
    </MaterialRoot>
)
