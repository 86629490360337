/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'

import useListMutation from 'components/editable/useListMutation'

import ListTile from './list_tile/ListTile'
import TileCarousel from './TileCarousel'

export default function ListCarousel({
    lists,
    title,
    url,
    onListRemoved
}: {
    lists: { id: number }[]
    title: string
    url: string
    onListRemoved: (...args: any) => any
}) {
    const patchList = useListMutation()

    return (
        <TileCarousel
            objects={lists}
            title={title}
            url={url}
            renderTile={list => (
                <ListTile list={list} onListRemoved={onListRemoved} triggerMutation={patchList.mutate} />
            )}
        />
    )
}
