/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography, Rating } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import type { CourseModule } from 'hooks/data/modules'

import ContentRatingDialog from './ContentRatingDialog'

const useStyles = makeStyles({
    container: {
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center'
    },
    ratingCount: {
        position: 'relative',
        bottom: 2
    },
    ratingOverlay: {
        cursor: 'pointer'
    }
})

export default function ContentRating({
    content,
    starSize = 'medium',
    onContentRated = noop
}: {
    content: CourseModule
    starSize: 'small' | 'medium' | 'large'
    onContentRated: (...args: any) => any
}) {
    const [showModal, setShowModal] = useState(false)
    const classes = useStyles()

    if (content.ratingsAvg === undefined || content.ratingsCount === undefined) {
        return null
    }

    return (
        <div className={classes.container} title={gettext('Click to rate')}>
            <div
                onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    setShowModal(true)
                }}
                className={classes.ratingOverlay}
            >
                <Rating value={content.ratingsAvg || 0} readOnly size={starSize} />
            </div>
            <Typography variant="caption" className={classes.ratingCount}>
                ({content.ratingsCount})
            </Typography>
            {showModal && (
                <ContentRatingDialog
                    content={content}
                    onContentRated={onContentRated}
                    onCloseModal={() => setShowModal(false)}
                />
            )}
        </div>
    )
}
