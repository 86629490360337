/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Popover } from '@mui/material'
import React from 'react'

import useStyles from './styles/Popover'

export default function StatusPopover({ statusMessages }: { statusMessages: string[] }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'status-popover' : undefined

    return (
        <React.Fragment>
            <Popover
                key="status"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Box p={2}>
                    <h4>{gettext('Progress')}</h4>
                    {statusMessages.map((message, index) => (
                        /* eslint-disable react/no-array-index-key */
                        <p key={index}>{message}</p>
                    ))}
                </Box>
            </Popover>
            <FontAwesomeIcon className={classes.StatusIcon} icon={faFileAlt} onClick={handleClick} />
        </React.Fragment>
    )
}
