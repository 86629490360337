/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { ExpandMore as MenuOpenIcon, ChevronRight as MenuClosedIcon } from '@mui/icons-material'
import { Collapse, Divider, ListItem, ListItemText } from '@mui/material'
import React from 'react'

export default function CollapsibleMenuItem({
    className,
    text,
    children
}: {
    className?: string
    text: string
    children: React.ReactNode
}) {
    const [itemOpen, setItemOpen] = React.useState(false)

    return (
        <React.Fragment>
            <ListItem
                component="a"
                key={text}
                onClick={event => {
                    event.stopPropagation()
                    setItemOpen(!itemOpen)
                }}
            >
                <ListItemText classes={{ primary: className }} primary={text} />
                {itemOpen ? <MenuOpenIcon /> : <MenuClosedIcon />}
            </ListItem>
            <Divider />
            <Collapse in={itemOpen}>{children}</Collapse>
        </React.Fragment>
    )
}
