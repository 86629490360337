/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'

import utils from 'components/react_components/utils'
import useUrls from 'hooks/useUrls'

import mutateContentItem from './mutateContentItem'

/** Mutate a course by patching changes and updating SWR */
export default function useCourseMutation() {
    const urls = useUrls()
    const queryClient = useQueryClient()
    const { enqueueSnackbar } = useSnackbar()
    return useMutation({
        mutationFn: ({ id, changes }: { id: number; changes: Partial<Course> | Partial<CourseDetails> }) =>
            mutateContentItem(urls.courseDetail(id), changes),
        onSuccess: response => {
            queryClient.setQueriesData<Array<Course>>(['Course', 'list'], oldCourses =>
                oldCourses?.map((oldCourse: Course) =>
                    oldCourse.id === response.id ? { ...oldCourse, ...response } : oldCourse
                )
            )
            queryClient.setQueryData(['Course', 'detail', response.id], response)
            queryClient.setQueryData<Array<Course>>(['CourseDetail', 'detail', response.id], oldResponse => ({
                ...oldResponse,
                ...response
            }))
        },

        onError: (error: AxiosError) => {
            // 400 means server validation failed. Show errors in form
            if (error.isAxiosError && error.response?.status === 400 && typeof error.response.data === 'object') {
                const message = Object.values(error.response.data).join(', ')
                enqueueSnackbar(message, { variant: 'error' })
            } else {
                utils.defaultAjaxErrorHandler(gettext('Sorry! Saving data failed.'))(error)
            }
        }
    })
}
