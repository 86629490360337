/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation } from '@tanstack/react-query'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import useCourseMutation from 'components/editable/useCourseMutation'

type CourseWithExpirationDates = Course & {
    effectiveExpirationDate?: string
    expirationDate?: string
    isStartable?: boolean
}

const useStyles = makeStyles(theme => ({
    expired: {
        color: theme.palette.error.main
    }
}))

function formatDate(date) {
    return new Date(date).toLocaleDateString(window.LANGUAGE)
}

function CourseExpirationDate({ course }: { course: CourseWithExpirationDates }) {
    const { mutate: patchCourse, ...rest } = useCourseMutation()
    const { mutate: patchExpirationDate } = useMutation({
        // @ts-ignore
        mutationFn: ({ id, changes }: { id: number; changes: Pick<CourseDetails, 'expirationDate'> }) => {
            changes.expirationDate = changes.expirationDate || null
            return patchCourse({ id, changes })
        },
        ...rest
    })
    if (!course.expirationDate) {
        return null
    }
    return (
        <abbr
            style={{ border: 'none' }}
            title={gettext(
                'After this date, the course is no longer accessible. Your personal access may expire before this date.'
            )}
        >
            <EditableText
                contentItem={course}
                triggerMutation={patchExpirationDate}
                name="expirationDate"
                defaultValue={course.expirationDate}
            >
                <Typography variant="body2">
                    {interpolate(gettext('Course expiration date: %s'), [formatDate(course.expirationDate)])}
                </Typography>
            </EditableText>
        </abbr>
    )
}

function EffectiveCourseExpirationDate({ course }: { course: CourseWithExpirationDates }) {
    const classes = useStyles()
    if (!course.effectiveExpirationDate || course.effectiveExpirationDate === '0001-01-01') {
        return null
    }
    const title = gettext('Your access to the course will expire after this date.')
    if (course.isStartable) {
        return (
            <abbr title={title} style={{ border: 'none' }}>
                <Typography variant="body2">
                    {interpolate(gettext('Access expires on: %s'), [formatDate(course.effectiveExpirationDate)])}
                </Typography>
            </abbr>
        )
    }
    return (
        <Typography variant="caption" className={classes.expired}>
            {gettext('Your access to this course expired')}
        </Typography>
    )
}

export default function CourseAccess({
    course,
    userIsAuthenticated
}: {
    course: CourseWithExpirationDates
    userIsAuthenticated: boolean
}) {
    return (
        <React.Fragment>
            <CourseExpirationDate course={course} />
            {userIsAuthenticated && <EffectiveCourseExpirationDate course={course} />}
        </React.Fragment>
    )
}
