/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import Highlighter from 'react-highlight-words'

export default function HighlighterComponent({
    searchWords = [],
    textToHighlight
}: {
    searchWords: any[]
    textToHighlight: string
}) {
    return (
        <Highlighter
            highlightStyle={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                backgroundColor: 'transparent',
                color: 'inherit'
            }}
            autoEscape
            searchWords={searchWords}
            textToHighlight={textToHighlight}
        />
    )
}
