/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { LinearProgress } from '@mui/material'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    progressBar: {
        height: 6,
        width: '100%',
        // Add transparency to progress bar.
        // Do not use opacity to avoid transparency on overlaying progress bar.
        backgroundColor: `${alpha(theme.palette.background.paper, 0.5)}`
    }
}))

export default function CourseProgress({ course }: { course: Course }) {
    const classes = useStyles()
    const { completionInPercent } = course

    if (typeof completionInPercent === 'number') {
        return <LinearProgress className={classes.progressBar} variant="determinate" value={completionInPercent} />
    }

    return <LinearProgress className={classes.progressBar} variant="determinate" value={0} />
}
