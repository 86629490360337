/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Link } from 'react-router-dom'

import { useStyles } from './ConfigurableCourseCarouselHeader'

export default function CourseCarouselHeader({ title, url }: { title: string; url: string }) {
    const classes = useStyles()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <div className={classes.header}>
            <Typography variant="subtitle1">{title}</Typography>
            <div className={classes.flex}>
                <Link className={classes.alignCenter} to={url}>
                    <Button size="small" variant="outlined" color="secondary" className={classes.button}>
                        {isSmallScreen ? gettext('all') : gettext('show all')}
                    </Button>
                </Link>
            </div>
        </div>
    )
}
