/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { CardContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import CaseType from './CaseType'
import CourseTitle from './CourseTitle'

import 'react-perfect-scrollbar/dist/css/styles.css'

const useStyles = makeStyles(theme => ({
    courseInfo: {
        padding: `${theme.spacing(1)} 0`,
        overflowWrap: 'anywhere',
        overflowY: 'auto',
        '&:last-child': {
            padding: `${theme.spacing(2)} 0`
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
}))

export default function Caption({
    course,
    triggerMutation
}: {
    course: Course
    triggerMutation: (...args: any) => any
}) {
    const classes = useStyles()
    return (
        <div>
            <CardContent className={classes.courseInfo}>
                <div className={classes.container}>
                    <CaseType course={course} triggerMutation={triggerMutation} dense />
                    <CourseTitle course={course} triggerMutation={triggerMutation} />
                </div>
            </CardContent>
        </div>
    )
}
