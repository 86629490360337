/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

async function main() {
    if (JSON.parse(document.getElementById('userIsAuthenticated').textContent)) {
        await import('utils/init_google_analytics')
    }

    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/)) {
        await import('utils/ios_fullscreen_helper')
    }

    const settingsElement = document.getElementById('globalSettings')
    if (settingsElement) {
        const jsonText = settingsElement.textContent || settingsElement.innerText
        const globalSettings = JSON.parse(jsonText)
        globalSettings.LANGUAGE = globalSettings.LANGUAGE.replace(/-.*/, '')
        for (const key in globalSettings) {
            if (globalSettings.hasOwnProperty(key) && window[key] === undefined) {
                window[key] = globalSettings[key]
            }
        }
    }
}

main()
