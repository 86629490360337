/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import CourseTile from 'components/course_tile/CourseTile'
import useCourseMutation from 'components/editable/useCourseMutation'
import PageContainer from 'components/react_components/PageContainer'
import { useBookmarkedCourses, useLastSeenCourses, usePurchasedCourses } from 'hooks/data/courses'

export function BookmarkedCoursesGrid() {
    const { data: courses } = useBookmarkedCourses()
    if (!courses) {
        return null
    }
    return <CourseGrid courses={courses} title={gettext('my bookmarked content')} />
}

export function LastSeenCoursesGrid() {
    const { data: courses } = useLastSeenCourses()
    if (!courses) {
        return null
    }
    return <CourseGrid courses={courses} title={gettext('last seen')} />
}

export function PurchasedCoursesGrid() {
    const { data: courses } = usePurchasedCourses()
    if (!courses) {
        return null
    }
    return <CourseGrid courses={courses} title={gettext('my activated content')} />
}

export default function CourseGrid({ courses, title }: { courses: Course[]; title: string }) {
    const patchCourse = useCourseMutation()
    return (
        <PageContainer fullWidth>
            <Box display="flex" alignItems="center" mb={1} mt={2}>
                <Link to="/">
                    <IconButton style={{ color: 'inherit' }} edge="start" size="large">
                        <ArrowBackIcon />
                    </IconButton>
                </Link>
                <Typography variant="h1">{title}</Typography>
            </Box>
            <Grid container spacing={2}>
                {courses.map(course => (
                    <Grid item key={course.id}>
                        <CourseTile
                            course={course}
                            userIsAuthenticated={JSON.parse(document.getElementById('userIsAuthenticated').textContent)}
                            triggerMutation={patchCourse.mutate}
                        />
                    </Grid>
                ))}
            </Grid>
        </PageContainer>
    )
}
