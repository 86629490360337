/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'

import CourseTile from 'components/course_tile/CourseTile'

import useCourseMutation from './editable/useCourseMutation'
import TileCarousel from './TileCarousel'

export default function CourseCarousel({
    courses,
    title,
    url,
    userIsAuthenticated
}: {
    courses: { id: number }[]
    title: string
    url: string
    userIsAuthenticated: boolean
}) {
    const patchCourse = useCourseMutation()
    return (
        <TileCarousel
            objects={courses}
            title={title}
            url={url}
            renderTile={course => (
                <CourseTile
                    course={course}
                    userIsAuthenticated={userIsAuthenticated}
                    triggerMutation={patchCourse.mutate}
                />
            )}
        />
    )
}
