/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Clear as ClearIcon } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, Autocomplete } from '@mui/material'
import range from 'lodash/range'
import React from 'react'

export function TitleInput({ value, handleChange }: { value?: string; handleChange: (...args: any) => any }) {
    return (
        <TextField
            name="title"
            label={gettext('Title')}
            type="text"
            variant="outlined"
            fullWidth
            required
            defaultValue={value}
            onChange={handleChange}
            inputProps={{ 'data-testid': 'title' }}
        />
    )
}

export function CoursesInput({
    value,
    handleChange,
    clearCourses,
    error
}: {
    value?: string
    handleChange: (...args: any) => any
    clearCourses: (...args: any) => any
    error: boolean
}) {
    return (
        <TextField
            name="courses"
            label={gettext('Individual courses')}
            variant="outlined"
            fullWidth
            value={value}
            onChange={handleChange}
            helperText={gettext('Please enter a comma separated list of course ids')}
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            type="button"
                            title={gettext('Clear search and filter fields')}
                            onClick={clearCourses}
                            edge="end"
                            size="large"
                        >
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

const AUTOCOMPLETE_PROPS = {
    fullWidth: true,
    forcePopupIcon: true,
    multiple: true
}

export function KeywordsInput({
    availableKeywords,
    value,
    handleChange,
    disabled
}: {
    availableKeywords: string[]
    value?: string[]
    handleChange: (...args: any) => any
    disabled: boolean
}) {
    return (
        <Autocomplete
            {...AUTOCOMPLETE_PROPS}
            data-testid="keywords"
            options={availableKeywords}
            // @ts-ignore
            name="keywords"
            value={value}
            onChange={handleChange}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    label={pgettext('Themen', 'Keywords')}
                    variant="outlined"
                    {...params}
                    placeholder={gettext('Type or select keywords')}
                />
            )}
        />
    )
}

export function TypesInput({
    availableTypes,
    value,
    handleChange,
    disabled
}: {
    availableTypes: string[]
    value?: string[]
    handleChange: (...args: any) => any
    disabled: boolean
}) {
    return (
        <Autocomplete
            {...AUTOCOMPLETE_PROPS}
            data-testid="types"
            options={availableTypes}
            // @ts-ignore
            name="types"
            value={value}
            onChange={handleChange}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    label={pgettext('Course types', 'Types')}
                    variant="outlined"
                    {...params}
                    placeholder={gettext('Type or select course types')}
                />
            )}
        />
    )
}

export function AdditionalFiltersInput({
    availableAdditionalFilters,
    value,
    handleChange,
    disabled
}: {
    availableAdditionalFilters: string[]
    value?: string[]
    handleChange: (...args: any) => any
    disabled: boolean
}) {
    return (
        <Autocomplete
            {...AUTOCOMPLETE_PROPS}
            data-testid="additionalFilters"
            options={availableAdditionalFilters}
            // @ts-ignore
            name="additionalFilters"
            value={value}
            onChange={handleChange}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    label={gettext('Additional Filters')}
                    variant="outlined"
                    {...params}
                    placeholder={gettext('Type or select additional filters')}
                />
            )}
        />
    )
}

export function TargetAudiencesInput({
    availableTargetAudiences,
    value = [],
    handleChange,
    disabled
}: {
    availableTargetAudiences: string[]
    value?: string[]
    handleChange: (...args: any) => any
    disabled: boolean
}) {
    return (
        <Autocomplete
            {...AUTOCOMPLETE_PROPS}
            data-testid="targetAudiences"
            options={availableTargetAudiences}
            // @ts-ignore
            name="targetAudiences"
            value={value}
            onChange={handleChange}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    label={gettext('Target audiences')}
                    variant="outlined"
                    {...params}
                    placeholder={gettext('Type or select target audiences')}
                />
            )}
        />
    )
}

export function PublishingYearsInput({
    value,
    handleChange,
    disabled
}: {
    value?: string[]
    handleChange: (...args: any) => any
    disabled: boolean
}) {
    const availablePublishingYears = range(2015, new Date().getFullYear() + 1)
    return (
        <Autocomplete
            {...AUTOCOMPLETE_PROPS}
            data-testid="publishingYears"
            options={availablePublishingYears}
            // @ts-ignore
            name="publishingYears"
            value={value}
            onChange={handleChange}
            getOptionLabel={option => option.toString()}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    label={gettext('Publishing years')}
                    variant="outlined"
                    {...params}
                    placeholder={gettext('Type or select publishing years')}
                />
            )}
        />
    )
}
