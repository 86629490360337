/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { MoreVert, ThumbsUpDown as ThumbsUpDownIcon } from '@mui/icons-material'
import { Box, Divider, IconButton, Link, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import FeedbackModal from 'components/feedback/FeedbackModal'
import { useActiveUser } from 'hooks/data/user'
import useUrls from 'hooks/useUrls'

import SettingsMenu from './SettingsMenu'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        boxSizing: 'border-box',
        height: '5rem',
        alignItems: 'center',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        paddingRight: theme.spacing(1),
        borderColor: theme.palette.footer.border,
        background: theme.palette.background.card,
        color: theme.palette.footer.text,
        fontWeight: 300,
        fontSize: 12,
        lineHeight: 1.6,
        '& hr': {
            margin: 0
        },
        '& a': {
            '&:hover': {
                color: theme.palette.primary.contrastText
            }
        }
    },
    footerLink: {
        color: `${theme.palette.footer.text} !important`,
        '&:hover': {
            color: `${theme.palette.text.primary} !important`
        }
    },
    logo: {
        height: '100%',
        minWidth: 0
    },
    oemLogo: {
        objectFit: 'contain',
        filter: theme.palette.invertImage
    },
    bottomRightLogo: {
        objectFit: 'cover',
        maxWidth: 200,
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    copyright: {
        minWidth: 'max-content', // Do not wrap
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.between('xs', 'xl')]: {
            marginInline: 'auto'
        }
    },
    'display-contents': {
        display: 'contents'
    }
}))

export default function Footer({
    dataPrivacyLinks = [],
    settingsMenuItems,
    softwareRevision,
    logoUrl,
    oemUrl,
    oemName,
    shortOemName,
    bottomRightLogoURL
}: {
    dataPrivacyLinks?: object[]
    settingsMenuItems: object[]
    softwareRevision: { revision_number: string; revision_date: string }
    logoUrl: string
    oemUrl?: string
    oemName?: string
    shortOemName?: string
    bottomRightLogoURL?: string
}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showFeedbackModal, setShowFeedbackModal] = React.useState(false)
    const open = Boolean(anchorEl)
    const urls = useUrls()
    const { data: user } = useActiveUser() as { data: AuthenticatedUser }
    const classes = useStyles()
    const theme = useTheme()
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

    if (!user) {
        return null
    }

    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const onShowFeedbackModal = () => {
        setShowFeedbackModal(true)
    }
    const onHideFeedbackModal = () => {
        setShowFeedbackModal(false)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const footerItems: any[] = [
        {
            text: gettext('Contact'),
            href: urls.overview('contact')
        },
        {
            text: gettext('Terms of Use'),
            href: urls['termsofuse:show']()
        },
        {
            text: gettext('Imprint'),
            href: gettext('http://www.mevis.de/en/imprint/'),
            target: 'blank',
            rel: 'noopener noreferrer'
        },
        ...dataPrivacyLinks
    ]

    const oemNameForDisplay = lgUp ? oemName : shortOemName || oemName
    const mevisNameForDisplay = lgUp ? 'MeVis Medical Solutions AG, Bremen.' : 'MeVis, Bremen.'
    const isAuthenticated = Boolean(user.id)

    return (
        <Box borderTop={1} className={classes.container}>
            {showFeedbackModal && <FeedbackModal onHide={onHideFeedbackModal} />}

            <a
                href={oemUrl || undefined}
                className={classes['display-contents']}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={logoUrl} className={clsx(classes.logo, classes.oemLogo)} height="48" />
            </a>

            <Box className={classes.copyright} lineHeight={1.6}>
                <div>{`© 2013-${new Date().getFullYear()}`}</div>

                {/* Copyright */}
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {oemNameForDisplay && (
                        <React.Fragment>
                            <Link
                                className={classes.footerLink}
                                href={oemUrl || undefined}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {oemNameForDisplay}
                            </Link>
                            {` ${gettext('and')} `}
                        </React.Fragment>
                    )}
                    <Link
                        className={classes.footerLink}
                        href="https://www.mevis.de"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {mevisNameForDisplay}
                    </Link>
                    <div>{gettext('All rights reserved.')}</div>
                </Box>
            </Box>

            <Box sx={{ marginInline: 'auto', display: { xs: 'none', xl: 'block' } }}>
                {softwareRevision && (
                    <div>
                        Version:{' '}
                        {user.is_staff ? (
                            <Link
                                className={classes.footerLink}
                                href={`http://gitlab.mevis.lokal/cloud/academy-web/commits/master#commit-${softwareRevision.revision_number}`}
                            >
                                {`${softwareRevision.revision_number} (${softwareRevision.revision_date})`}
                            </Link>
                        ) : (
                            softwareRevision.revision_date
                        )}
                    </div>
                )}
            </Box>

            <Box sx={{ display: { xs: 'none', xl: 'contents' } }}>
                <Box display="contents">
                    {footerItems.map(({ text, ...linkProps }) => (
                        <React.Fragment key={text}>
                            <Typography variant="caption" noWrap>
                                <Link className={classes.footerLink} variant="caption" {...linkProps}>
                                    {text}
                                </Link>
                            </Typography>
                        </React.Fragment>
                    ))}
                </Box>
            </Box>

            <Box
                className="footer__buttons"
                display="flex"
                alignItems="inherit"
                height="inherit"
                gap={theme.spacing(1)}
            >
                <Box sx={{ display: { xs: 'block', xl: 'none' } }}>
                    <IconButton onClick={handleMenu} color="inherit" size="small">
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={open}
                        onClose={handleClose}
                    >
                        {footerItems.map(({ text, ...linkProps }) => (
                            <MenuItem key={text}>
                                <Link className={classes.footerLink} {...linkProps}>
                                    {text}
                                </Link>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Divider orientation="vertical" variant="middle" />
                </Box>

                {isAuthenticated && (
                    <React.Fragment>
                        <IconButton data-testid="feedback" onClick={onShowFeedbackModal} color="inherit" size="small">
                            <ThumbsUpDownIcon />
                        </IconButton>
                        <Divider orientation="vertical" variant="middle" />
                    </React.Fragment>
                )}
                <SettingsMenu settingsMenuItems={settingsMenuItems} />
            </Box>

            {bottomRightLogoURL && (
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <img
                        src={bottomRightLogoURL}
                        alt="Course Logo"
                        height="48"
                        className={clsx(classes.logo, classes.bottomRightLogo)}
                    />
                </Box>
            )}
        </Box>
    )
}
