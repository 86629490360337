/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'

import FormModal from './FormModal'

export default function ConfirmDeletionModal({
    contentText = '',
    children,
    open = true,
    onSubmit,
    onReject,
    object = {}
}: {
    contentText?: string
    children?: React.ReactNode
    open?: boolean
    onSubmit: (...args: any) => any
    onReject: (...args: any) => any
    object?: object
}) {
    return (
        <FormModal
            title={gettext('Delete')}
            contentText={contentText}
            submitText={gettext('Delete')}
            open={open}
            onReject={onReject}
            onSubmit={() => onSubmit(object)}
            variant="danger"
        >
            {children}
        </FormModal>
    )
}
