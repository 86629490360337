/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'

import type { CourseModule } from './modules'

const urls = window.Urls

export type ModuleActions = {
    accept?: string
    assignReviewer?: string
    clone?: string
    delete?: string
    edit?: string
    editViewerCourseModules?: string
    preview?: string
    reassignReviewer?: string
    reject?: string
    resetToDraft?: string
    setReadyForReview?: string
}

export function useModuleActions({ id, model }: Partial<CourseModule>, enabled: boolean = true) {
    return useReactQuery<ModuleActions>(urls.moduleActions(model, id), ['ModuleActions', 'detail', { id, model }], {
        reactQueryOptions: { enabled }
    })
}

export function useViewerCourseModuleCasesCount(module: CourseModule) {
    return useReactQuery<number>(urls.casesCount(module.id), ['CasesCount', 'detail', module.id], {
        reactQueryOptions: { enabled: module.model === 'ViewerCourseModule' }
    })
}
