/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { useParams } from 'react-router-dom'

import PageContainer from 'components/react_components/PageContainer'
import { useCarouselConfigs } from 'hooks/data/misc'

import { useCarouselCourses } from './ConfigurableCourseCarousel'
import CourseGrid from './CourseGrid'

export default function SingleCarousel() {
    const { data: carouselConfigs } = useCarouselConfigs()
    const { id } = useParams<{ id: string }>()
    const config = Array.isArray(carouselConfigs) ? carouselConfigs.find(c => c.id === Number(id)) : undefined
    const courses = useCarouselCourses(config)
    if (!config || !courses) {
        return null
    }

    return (
        <PageContainer fullWidth>
            <CourseGrid title={config.title} courses={courses} />
        </PageContainer>
    )
}
