/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import * as Yup from 'yup'

const testEmptyString = s => s.trim() !== ''

export const validateTitle = Yup.object().shape({
    title: Yup.string().test({ message: 'Title is required.', test: testEmptyString })
})

export const validateText = Yup.object().shape({
    text: Yup.string().test({ message: 'Text is required.', test: testEmptyString })
})

export const validateLongDescription = Yup.object().shape({
    longDescription: Yup.string().required(gettext('Description is required.'))
})

export const validateShortDescription = Yup.object().shape({
    shortDescription: Yup.string().test({ message: 'Short description is required.', test: testEmptyString })
})

export const validateChoice = Yup.object().shape({
    choiceText: Yup.string().test({ message: 'Choice text is required.', test: testEmptyString })
})

export const validateQuestion = Yup.object().shape({
    questionText: Yup.string().required(gettext('Question text is required.'))
})

export const validateCMECredits = Yup.object().shape({
    cmeCredits: Yup.number()
        .required(gettext('cmeCredits field is required.'))
        .min(0, gettext('Value must be 0 or greater.'))
        .integer(gettext('Value must be an integer.'))
})
