/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import React from 'react'
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useSlideShowConfigs } from 'hooks/data/misc'
import useUrls from 'hooks/useUrls'

import { useStyles } from './ConfigurableCourseCarousel'

import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination])

const description = {
    large: {
        fontSize: '16px',
        lineHeight: '1.5'
    },
    small: {
        fontSize: '14px',
        lineHeight: '1.33'
    },
    hidden: {
        display: 'none'
    }
}
const swiperButtons = {
    bottom: {
        top: 'auto',
        bottom: '5px',
        background: 'transparent',
        border: 'none',
        zIndex: 1200
    }
}
const alignment = {
    center: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        margin: '0',
        padding: '16px'
    },
    left: {
        left: 0,
        padding: '30px',
        paddingLeft: '80px',
        marginRight: '50%'
    },
    right: {
        right: 0,
        padding: '30px',
        paddingRight: '80px',
        marginLeft: '50%'
    },
    verticalMedium: {
        paddingTop: '60px'
    },
    verticalLarge: {
        padding: '16px',
        maxWidth: '600px'
    }
}

const useSlideShowStyles = makeStyles(theme => ({
    swiper: {
        maxWidth: '100vw',
        height: '40vh',
        margin: '0 !important',
        zIndex: `${theme.zIndex.drawer} !important` as any,
        [theme.breakpoints.down(1280)]: {
            height: '50vh'
        },
        [theme.breakpoints.down(630)]: {
            height: '350px',
            minHeight: '40vh'
        },
        '& .swiper-slide img': {
            objectFit: 'cover',
            display: 'block',
            width: '100vw',
            height: '100%',
            objectPosition: 'center'
        },
        '& .swiper-pagination-bullets': {
            width: 'auto',
            left: '25px',
            bottom: '20px',
            [theme.breakpoints.down(769)]: {
                width: '100%',
                left: '0'
            }
        },
        '& .swiper-pagination-bullet': {
            height: '6px',
            width: '6px',
            color: 'white',
            borderStyle: 'solid',
            borderWidth: '1px',
            background: 'transparent',
            opacity: 1,
            margin: '0 5px !important'
        },
        '& .swiper-pagination-bullet-active': {
            color: 'white',
            background: 'white'
        },
        '& .swiper-button-next': {
            [theme.breakpoints.down(769)]: {
                ...swiperButtons.bottom,
                right: 0
            }
        },
        '& .swiper-button-prev': {
            [theme.breakpoints.down(769)]: {
                ...swiperButtons.bottom,
                left: 0
            }
        }
    },
    slide: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    header: {
        margin: '30px 0px',
        fontWeight: 300,
        fontSize: 'calc(22px + 1vw )',
        lineHeight: '1.33'
    },
    description: {
        marginInline: 'auto',
        maxWidth: '900px',
        fontWeight: 300,
        marginBottom: '30px',
        [theme.breakpoints.up(850)]: description.large,
        [theme.breakpoints.down(850)]: description.small,
        [theme.breakpoints.down(414)]: description.hidden
    },
    whiteText: {
        color: 'white',
        textShadow: '0 0 10px rgb(0 0 0 / 80%)'
    },
    blackText: {
        color: 'black',
        textShadow: '0 0 10px rgb(255 255 255 / 80%)'
    },
    whiteButton: {
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.2)',
        '&:is(a) *': { color: 'white' }
    },
    blackButton: {
        color: 'black',
        backgroundColor: 'rgba(255,255, 255,0.2)',
        '&:is(a) *': { color: 'black' }
    },
    positionleft: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        [theme.breakpoints.up(850)]: alignment.left,
        [theme.breakpoints.down(850)]: alignment.center,
        [theme.breakpoints.down(769)]: alignment.verticalLarge,
        [theme.breakpoints.down(630)]: alignment.verticalMedium
    },
    positionright: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        [theme.breakpoints.up(850)]: alignment.right,
        [theme.breakpoints.down(850)]: alignment.center,
        [theme.breakpoints.down(769)]: alignment.verticalLarge,
        [theme.breakpoints.down(630)]: alignment.verticalMedium
    },
    positioncenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        [theme.breakpoints.up(768)]: alignment.center,
        [theme.breakpoints.down(769)]: alignment.verticalLarge,
        [theme.breakpoints.down(630)]: alignment.verticalMedium
    },
    alignmentleft: {
        textAlign: 'left',
        [theme.breakpoints.down(850)]: {
            textAlign: 'center'
        }
    },
    alignmentright: {
        textAlign: 'right',
        [theme.breakpoints.down(850)]: {
            textAlign: 'center'
        }
    },
    alignmentcenter: {
        textAlign: 'center'
    }
}))

export default function SlideShow() {
    const slideShowClasses = useSlideShowStyles()
    const carouselClasses = useStyles()
    const { data: slideShowConfigs } = useSlideShowConfigs()
    const urls = useUrls()

    function getPositionClass(position) {
        if (!position || isEmpty(position)) {
            position = 'center'
        }
        return `position${position.toLowerCase()}`
    }
    function getAlignmentClass(alignment) {
        if (!alignment || isEmpty(alignment)) {
            alignment = 'center'
        }
        return `alignment${alignment.toLowerCase()}`
    }

    if (!slideShowConfigs || slideShowConfigs.length === 0) {
        return null
    }

    return (
        <React.Fragment>
            <Swiper
                pagination={{ clickable: true }}
                navigation
                autoplay={{
                    delay: 10000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                }}
                loop
                spaceBetween={0}
                slidesPerView={1}
                className={clsx(carouselClasses.swiper, slideShowClasses.swiper)}
                effect="fade"
                fadeEffect={{
                    crossFade: true
                }}
                speed={800}
            >
                {slideShowConfigs.map(config => (
                    <SwiperSlide key={config.id} className={slideShowClasses.slide}>
                        <img src={config.slideImage} />
                        <Box
                            className={clsx(
                                slideShowClasses[getPositionClass(config.position)],
                                slideShowClasses[getAlignmentClass(config.alignment)]
                            )}
                        >
                            <Typography
                                className={clsx(
                                    slideShowClasses.header,
                                    config.textColor === 'black'
                                        ? slideShowClasses.blackText
                                        : slideShowClasses.whiteText
                                )}
                            >
                                {config.header}
                            </Typography>

                            {config.description && (
                                <Typography
                                    className={clsx(
                                        slideShowClasses.description,
                                        config.textColor === 'black'
                                            ? slideShowClasses.blackText
                                            : slideShowClasses.whiteText
                                    )}
                                >
                                    {config.description}
                                </Typography>
                            )}
                            {config.buttonText && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={clsx(
                                        config.textColor === 'black'
                                            ? slideShowClasses.blackButton
                                            : slideShowClasses.whiteButton
                                    )}
                                    href={config.course ? urls.courseDescription(config.course) : config.externalLink}
                                >
                                    {config.buttonText}
                                </Button>
                            )}
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>
        </React.Fragment>
    )
}
