/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import Cookies from 'js-cookie'

import { createDarkThemeForOtherPortalsThanDRG, currentPortal } from './commonTheme'
import createDarkThemeForDRG from './drgDarkTheme'
import createLightThemeForDRG from './drgLightTheme'

function currentThemeHasLightMode() {
    if (document.querySelector('.doNotTheme')) {
        return false
    }
    /* Light theme is only available for DRG. For all other portals we do use dark theme. */
    if (currentPortal() === 'drg') {
        const cookie = Cookies.get('lightMode')
        if (cookie) {
            return JSON.parse(cookie)
        }
    }
    return false
}

function updateTheme(sendEvent = true) {
    const theme = currentThemeHasLightMode() ? createLightThemeForDRG() : createDarkTheme()
    window.currentTheme = theme
    document.querySelector<HTMLElement>(':root').style.setProperty('--textColor', theme.palette.text.primary)
    if (sendEvent) {
        window.dispatchEvent(new Event('theme_changed'))
    }
}

export function switchDarkLightMode() {
    const lightMode = !currentThemeHasLightMode()
    Cookies.set('lightMode', lightMode, { path: window.COOKIE_PATH })
    updateTheme()
}

function createDarkTheme() {
    const portal = currentPortal()
    /* DRG has a special dark theme */
    if (portal === 'drg') {
        return createDarkThemeForDRG()
    }
    return createDarkThemeForOtherPortalsThanDRG()
}

/* Default export for all un-themed pages */
export default createDarkThemeForOtherPortalsThanDRG()

/* Update theme once without sending event */
updateTheme(false)
