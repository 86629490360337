/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import TAG_DICT from './dataDictionary.json'

export function str2ab(str) {
    const buffer = new Uint8Array(str.length)
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        buffer[i] = str.charCodeAt(i)
    }
    return buffer
}

export function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf))
}

export function getTag(tag) {
    return TAG_DICT[getReadableTag(tag)]
}

export function getReadableTag(tag) {
    const group = tag.substring(1, 5)
    const element = tag.substring(5, 9)
    const tagIndex = `(${group},${element})`.toUpperCase()
    return tagIndex
}
