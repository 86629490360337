/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { MenuItem, Select } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import noop from 'lodash/noop'
import React, { useCallback, useRef } from 'react'

import useEditableInput from 'components/editable/useEditableInput'

import EditableComponentContainer from './EditableComponentContainer'
import useApplyStyles from './useApplyStyles'

interface EditableSelectProps {
    contentItem: Record<string, any>
    children: React.ReactNode
    triggerMutation: (
        opts: { id: number; changes: Partial<Record<string, any>> },
        postMutation: { onSettled: Function }
    ) => void
    options: Array<any>
    defaultSelection: string
    name: string
    editOnClick?: boolean
}

/** EditableSelect is a React component that allows to select values inline */
export default function EditableSelect({
    contentItem,
    children,
    triggerMutation = noop,
    options,
    defaultSelection,
    name,
    editOnClick = true
}: EditableSelectProps) {
    const { canEdit, isEditing, enterEditingMode, leaveEditingMode, setMouseEntered } = useEditableInput(contentItem)

    const containerRef = useRef<HTMLDivElement>()
    const { inputStyle, formStyle, iconStyle, buttonStyle, applyStylesOnClick, copyStylesOnMouseEnter } =
        useApplyStyles(containerRef)
    const useInputStyle = makeStyles({ input: { ...inputStyle, padding: 0 } })
    const inputClasses = useInputStyle()

    const onClick = useCallback(
        event => {
            applyStylesOnClick(event)
            enterEditingMode(event)
        },
        [applyStylesOnClick, enterEditingMode]
    )

    const onMouseEnter = useCallback(() => {
        copyStylesOnMouseEnter()
        setMouseEntered(true)
    }, [copyStylesOnMouseEnter, setMouseEntered])

    return isEditing ? (
        <div style={formStyle} onClick={event => event.stopPropagation()}>
            <Select
                classes={{ root: inputClasses.input }}
                MenuProps={{
                    style: { maxHeight: '40vh' },
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' }
                }}
                onClose={leaveEditingMode}
                name={name}
                autoFocus
                open={isEditing}
                value={defaultSelection}
                fullWidth
                onChange={event => {
                    triggerMutation(
                        { id: contentItem.id, changes: { [name]: event.target.value } },
                        { onSettled: leaveEditingMode }
                    )
                }}
            >
                {options.map(o => (
                    <MenuItem key={o} value={o}>
                        {o}
                    </MenuItem>
                ))}
            </Select>
        </div>
    ) : (
        <EditableComponentContainer
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
            containerRef={containerRef}
            onClick={onClick}
            editOnClick={editOnClick}
            onMouseEnter={onMouseEnter}
            canEdit={canEdit}
        >
            {children}
        </EditableComponentContainer>
    )
}
