/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Link, Menu, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import noop from 'lodash/noop'
import pick from 'lodash/pick'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import { useCourseBookmark } from 'components/bookmarks/CourseBookmark'
import EditCoursePropertiesModal from 'components/course/EditCoursePropertiesModal'
import ShareCourseModal from 'components/course_tile/ShareCourseModal'
import utils from 'components/react_components/utils'
import UploadCases from 'components/UploadCases'
import { useCourseActions } from 'hooks/data/courses'

const useStyles = makeStyles(theme => ({
    menu: {
        '& li': {
            color: theme.palette.secondary.main
        }
    }
}))

export const onCloneCourse = async (closeSnackbar, enqueueSnackbar, url) => {
    let snackId
    try {
        snackId = enqueueSnackbar(
            gettext(
                'Started copying course. This may take some time. When the course has been copied, the editing page opens in a new tab ...'
            ),
            {
                variant: 'info',
                persist: true
            }
        )
        const response = await axios.post(url, {})
        window.open(response.data.url, '_blank')
        closeSnackbar(snackId)
    } catch (error) {
        closeSnackbar(snackId)
        const message = gettext('Sorry! Something went wrong while cloning the course')
        let reason
        if (error.response && error.response.data && error.response.data.message) {
            reason = error.response.data.message
        }

        utils.defaultAjaxErrorHandler(message)(reason)
    }
}

export const resetToDraft = async (course, resetUrl, onCourseStatusChanged) => {
    try {
        await axios.post(resetUrl)
        onCourseStatusChanged({ ...course, status: 'draft' })
    } catch (error) {
        const message = gettext('Sorry! Something went wrong while changing course status')
        let reason
        if (error.response && error.response.data && error.response.data.message) {
            reason = error.response.data.message
        }

        utils.defaultAjaxErrorHandler(message)(reason)
    }
}

function ExtraActions({
    course,
    onClose,
    openCourseDetailsModal
}: {
    course: { id: number }
    onClose: (...args: any) => any
    openCourseDetailsModal: (...args: any) => any
}) {
    const { courseIsBookmarked, onBookmarkChangedCb } = useCourseBookmark(course)
    return (
        <React.Fragment>
            <Link
                onClick={async event => {
                    event.stopPropagation()
                    await onBookmarkChangedCb()
                    onClose()
                }}
            >
                <MenuItem>{courseIsBookmarked ? gettext('Remove Bookmark') : gettext('Add Bookmark')}</MenuItem>
            </Link>
            <Link key="ShowDetails" onClick={openCourseDetailsModal}>
                <MenuItem>{gettext('Show details')}</MenuItem>
            </Link>
        </React.Fragment>
    )
}

export default function CourseActionsMenu({
    course,
    anchorEl,
    onClose,
    extraActions = false,
    openCourseDetailsModal = noop,
    onResetToDraft
}: {
    course: { id: number }
    anchorEl?: Element
    onClose: (...args: any) => any
    extraActions?: boolean
    openCourseDetailsModal?: (...args: any) => any
    onResetToDraft: (...args: any) => any
}) {
    const [uploadCasesOpen, setUploadCasesOpen] = useState(false)
    const [shareCourseOpen, setShareCourseOpen] = useState(false)
    const [editCoursePropertiesOpen, setEditCoursePropertiesOpen] = useState(false)

    return (
        <React.Fragment>
            {uploadCasesOpen && <UploadCases close={() => setUploadCasesOpen(false)} course={course} />}
            {shareCourseOpen && <ShareCourseModal courseId={course.id} onHide={() => setShareCourseOpen(false)} />}
            {editCoursePropertiesOpen && (
                <EditCoursePropertiesModal courseId={course.id} onHide={() => setEditCoursePropertiesOpen(false)} />
            )}
            {anchorEl && (
                <CourseActionsMenuComponent
                    anchorEl={anchorEl}
                    course={course}
                    onClose={onClose}
                    extraActions={extraActions}
                    openCourseDetailsModal={openCourseDetailsModal}
                    onResetToDraft={onResetToDraft}
                    setEditCoursePropertiesOpen={setEditCoursePropertiesOpen}
                    setUploadCasesOpen={setUploadCasesOpen}
                    setShareCourseOpen={setShareCourseOpen}
                />
            )}
        </React.Fragment>
    )
}

function CourseActionsMenuComponent({
    course,
    anchorEl,
    onClose,
    extraActions,
    openCourseDetailsModal,
    onResetToDraft,
    setEditCoursePropertiesOpen,
    setUploadCasesOpen,
    setShareCourseOpen
}: {
    course: { id: number }
    anchorEl: Element
    onClose: (...args: any) => any
    extraActions: boolean
    openCourseDetailsModal: (...args: any) => any
    onResetToDraft: (...args: any) => any
    setEditCoursePropertiesOpen: (open: boolean) => void
    setUploadCasesOpen: (open: boolean) => void
    setShareCourseOpen: (open: boolean) => void
}) {
    const open = Boolean(anchorEl)
    const classes = useStyles()
    let { data: courseActions } = useCourseActions(course.id)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    if (!courseActions) {
        return null
    }

    courseActions = pick(courseActions, [
        'cloneCourse',
        'editCaseOrder',
        'editCourseContent',
        'editCourseDetails',
        'editCourseProperties',
        'manageCertificates',
        'importCases',
        'resetToDraft',
        'shareCourse'
    ])
    const menuIsEmpty = Object.values(courseActions).every(v => Boolean(v) === false) && extraActions === false

    const menuProps = {
        className: classes.menu,
        onClick: onClose,
        open,
        anchorEl,
        onClose
    }
    if (menuIsEmpty) {
        return (
            <Menu {...menuProps}>
                <MenuItem>{gettext('No available actions')}</MenuItem>
            </Menu>
        )
    }
    return (
        <Menu {...menuProps}>
            {courseActions.editCourseDetails && (
                <Link href={courseActions.editCourseDetails}>
                    <MenuItem data-testid="editCourse">{gettext('Edit course')}</MenuItem>
                </Link>
            )}
            {(courseActions.editCourseProperties || courseActions.manageCertificates) && (
                <Link onClick={() => setEditCoursePropertiesOpen(true)}>
                    <MenuItem>{gettext('Edit course properties')}</MenuItem>
                </Link>
            )}
            {courseActions.editCourseContent && (
                <Link href={courseActions.editCourseContent}>
                    <MenuItem data-testid="editCourseContent">{gettext('Edit Content')}</MenuItem>
                </Link>
            )}
            {courseActions.resetToDraft && (
                <Link onClick={() => onResetToDraft(courseActions.resetToDraft)}>
                    <MenuItem data-testid="resetToDraft">{gettext('Reset to draft')}</MenuItem>
                </Link>
            )}
            {courseActions.cloneCourse && (
                <Link onClick={() => onCloneCourse(closeSnackbar, enqueueSnackbar, courseActions.cloneCourse)}>
                    <MenuItem data-testid="cloneCourse">{gettext('Clone course')}</MenuItem>
                </Link>
            )}
            {courseActions.editCaseOrder && (
                <Link data-testid="editCaseOrder" href={courseActions.editCaseOrder}>
                    <MenuItem>{gettext('Edit case and module order')}</MenuItem>
                </Link>
            )}
            {courseActions.importCases && (
                <Link onClick={() => setUploadCasesOpen(true)}>
                    <MenuItem data-testid="importCases">{gettext('Import cases')}</MenuItem>
                </Link>
            )}
            {courseActions.shareCourse && (
                <Link onClick={() => setShareCourseOpen(true)}>
                    <MenuItem>{gettext('Share course')}</MenuItem>
                </Link>
            )}
            {extraActions && (
                <ExtraActions course={course} onClose={onClose} openCourseDetailsModal={openCourseDetailsModal} />
            )}
        </Menu>
    )
}
