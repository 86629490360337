/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { ArrowBack as ArrowBackIcon, Search as SearchIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import SearchFormComponent from './SearchFormComponent'

const useStyles = makeStyles(theme => ({
    form: {
        margin: `0 ${theme.spacing(4)} 0 ${theme.spacing(2)}`,
        flexGrow: 1,
        maxWidth: 500,
        '& input': {
            margin: 0
        }
    },
    fullWidth: {
        maxWidth: 'none',
        width: '100%'
    }
}))

function SearchFormWrapper({ fullWidth }: { fullWidth?: boolean }) {
    const classes = useStyles()
    return (
        <div
            className={clsx(classes.form, {
                [classes.fullWidth]: fullWidth
            })}
        >
            <SearchFormComponent showFilterSection={false} showFilterIcon />
        </div>
    )
}

export default function NavbarSearch({
    fullWidthSearch,
    isSmallScreen,
    setFullWidthSearch
}: {
    fullWidthSearch: boolean
    isSmallScreen: boolean
    setFullWidthSearch: (...args: any) => any
}) {
    const theme = useTheme()

    if (isSmallScreen) {
        if (fullWidthSearch) {
            return (
                <React.Fragment>
                    <IconButton onClick={() => setFullWidthSearch(false)} size="large">
                        <ArrowBackIcon htmlColor={theme.palette.secondary.main} />
                    </IconButton>
                    <SearchFormWrapper fullWidth />
                </React.Fragment>
            )
        }

        return (
            <IconButton color="inherit" onClick={() => setFullWidthSearch(prevValue => !prevValue)} size="large">
                <SearchIcon htmlColor={theme.palette.secondary.main} />
            </IconButton>
        )
    }

    return <SearchFormWrapper />
}
