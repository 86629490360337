/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Divider, ListItem, ListItemText } from '@mui/material'
import React from 'react'

export default function MenuItem({
    className,
    href,
    text,
    target = '_self'
}: {
    className?: string
    target?: string
    href: string
    text: string
}) {
    return (
        <React.Fragment>
            <ListItem key={text} component="a" href={href} target={target}>
                <ListItemText classes={{ primary: className }} primary={text} />
            </ListItem>
            <Divider />
        </React.Fragment>
    )
}
