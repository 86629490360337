/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

const ProgressState = {
    IDLE: 'idle',
    UPLOADING: 'uploading',
    PROCESSING: 'processing',
    SUCCESS: 'success',
    ERROR: 'error'
}

const Errors = {
    AnonymizationFailed: 'Anonymization failed',
    ConvertFilesFailed: 'Convert files failed',
    ReadingFileFailed: 'Reading file failed',
    NotASupportedImageFile: 'Not a supported image file',
    UploadFailed: 'Upload failed'
}

const Tags = {
    Modality: 'x00080060',
    StudyDate: 'x00080020',
    AccessionNumber: 'x00080050',
    PatientID: 'x00100020',
    PatientName: 'x00100010'
}

export { Errors, ProgressState, Tags }
