/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import * as EmailValidator from 'email-validator'
import find from 'lodash/find'
import * as Yup from 'yup'

export default function useCheckEmail() {
    Yup.addMethod(Yup.mixed, 'checkEmail', function (message) {
        return this.test('checkEmail', message, function (value) {
            const { path, createError } = this
            const emailList = value ? value.split(',') : []
            let invalidEmail = ''
            if (emailList.length > 0) {
                invalidEmail = find(emailList, email => {
                    const trimmedMail = email.trim()
                    const valid = trimmedMail ? EmailValidator.validate(trimmedMail) : true
                    return !valid
                })
            }

            return (
                !invalidEmail ||
                createError({ path, message: gettext(`${invalidEmail} is not a valid email address.`) })
            )
        })
    })

    return null
}
