/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import type { StyleRules } from '@mui/styles'

import { ProgressState } from '../constants'

const stateColor = {
    [ProgressState.UPLOADING]: '#7390A5',
    [ProgressState.PROCESSING]: '#0a5285',
    [ProgressState.SUCCESS]: 'darkgreen',
    [ProgressState.ERROR]: 'darkred'
}

export function ProgressIndicator(progress, state): React.CSSProperties {
    return {
        zIndex: 1,
        width: `${progress}%`,
        backgroundColor: stateColor[state],
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        transition: 'background-color 600ms, width 100ms',
        transitionTimingFunction: 'ease-in-out'
    }
}

export default {
    Dropzone: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed lightgrey',
        padding: 10,
        height: 160,
        boxSizing: 'border-box',
        '& *': {
            zIndex: 2
        }
    },

    DropzoneText: { whiteSpace: 'pre', textAlign: 'center' },

    OpenFolderButton: { marginLeft: 5 },

    ProgressSummary: {
        textAlign: 'center'
    },

    Spinner: { zIndex: 1 }
} as StyleRules
