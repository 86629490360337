/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button as MaterialUiButton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

const styles = theme => ({
    root: {
        background: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    }
})

type ColorType = React.ComponentProps<typeof MaterialUiButton>['color'] | 'danger'

export const DangerButton = withStyles(styles)(MaterialUiButton)

export const Button = (props: Omit<React.ComponentProps<typeof MaterialUiButton>, 'color'> & { color?: ColorType }) =>
    props.color === 'danger' ? (
        <DangerButton {...props} color="primary" />
    ) : (
        <MaterialUiButton {...(props as React.ComponentProps<typeof MaterialUiButton>)} />
    )
