/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { makeValidate, Radios, TextField } from 'mui-rff'
import { useSnackbar } from 'notistack'
import React from 'react'
import * as Yup from 'yup'

import FormModal from 'components/modals/FormModal'
import utils from 'components/react_components/utils'
import { useCourse } from 'hooks/data/courses'

import 'react-perfect-scrollbar/dist/css/styles.css'

export default function FeedbackModal({ onHide }: { onHide: (...args: any) => any }) {
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()
    const courseId = queryClient.getQueryData<{ id: number }>(['run_course', 'course'])?.id
    const { data: course } = useCourse(courseId, Boolean(courseId))

    const schema = Yup.object().shape({
        text: Yup.string().required(gettext('Required')),
        satisfied: Yup.string().oneOf(['true', 'false'], gettext('Required')).required(gettext('Required'))
    })

    const onSubmit = async values => {
        try {
            values.course = course?.id
            if (window.workflowController) {
                const { getCurrentCase, getCurrentCaseIndex } = await import('pages/run_course/cases')
                values.case = getCurrentCase().id
                values.caseNumber = getCurrentCaseIndex() + 1
                values.workflowStepNumber = window.workflowController.getCurrentWorkflowStepIndex() + 1
            }
            values.currentUrl = window.location.href
            await axios.post(window.Urls['feedbacks:feedback_list'](), values)
            enqueueSnackbar(gettext('Thank you for your feedback.'), { variant: 'success' })
        } catch (error) {
            utils.defaultAjaxErrorHandler(error)()
        }
        onHide()
    }

    const validate = makeValidate(schema)

    return (
        <FormModal
            title={gettext('Feedback')}
            contentText={gettext('Thank you for taking the time to provide feedback.')}
            submitText={gettext('Send')}
            open
            onReject={onHide}
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
                text: '',
                satisfied: 'true'
            }}
            autoComplete="off"
        >
            <React.Fragment>
                <TextField
                    name="text"
                    label={gettext('Enter your message.')}
                    type="text"
                    variant="outlined"
                    multiline
                    rows={10}
                />
                <Radios
                    label={gettext('Are you satisfied with your experience?')}
                    name="satisfied"
                    data={[
                        { label: gettext('Yes'), value: 'true' },
                        { label: gettext('No'), value: 'false' }
                    ]}
                />
            </React.Fragment>
        </FormModal>
    )
}
