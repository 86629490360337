/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Close as CloseIcon } from '@mui/icons-material'
import type { AlertProps } from '@mui/lab'
import { Collapse, IconButton, Alert } from '@mui/material'
import React, { useState } from 'react'

/**
 * An alert which can be closed with a transition
 */
// eslint-disable-next-line import/prefer-default-export
export function ClosableAlert({ severity, children }: { severity: AlertProps['severity']; children: React.ReactNode }) {
    const [open, setOpen] = useState(true)

    return (
        <Collapse in={open}>
            <Alert
                action={
                    <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity={severity}
            >
                {children}
            </Alert>
        </Collapse>
    )
}
