/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'

import useUrls from '../useUrls'

import type { CourseModule } from './modules'

export function getFieldName(model: CourseModule['model']) {
    if (model === 'PDFCourseModule') {
        return 'pdfCourseModule'
    }
    return model.slice(0, 1).toLowerCase() + model.slice(1)
}

export function useRating(module: CourseModule) {
    const urls = useUrls()
    const { id, model } = module
    const fieldName = getFieldName(model)
    const params = { [fieldName]: id.toString() }
    return useReactQuery<object>(urls['rating:ratingList'](), ['Rating', 'list', params], { queryParams: params })
}
