/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import useButtonStyles from 'components/buttonStyles'

const useStyles = makeStyles({
    button: {
        width: '115px',
        padding: '10px',
        height: '38px',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 700
    }
})

export default function StartButton({ config }: { config: { url?: string; text: string } }) {
    const classes = useStyles()
    const buttonClasses = useButtonStyles()

    if (!config) {
        return null
    }

    return (
        <Button className={clsx([buttonClasses.bordered, classes.button])} href={config.url} disabled={!config.url}>
            {config.text}
        </Button>
    )
}
