/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import makeStyles from '@mui/styles/makeStyles'

export const baseIcon = {
    fontSize: 'x-large',
    position: 'absolute',
    zIndex: 2,
    top: 3,
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',

    '&:hover': {
        color: 'white'
    }
} as const

const useStyles = makeStyles({
    InfoCircleIcon: {
        ...baseIcon,
        left: 5
    },
    StatusIcon: {
        ...baseIcon,
        right: 5
    }
})

export default useStyles
