/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { Button, Menu, MenuItem, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'

import useButtonStyles from 'components/buttonStyles'
import SaveItemToList from 'components/SaveItemToList'
import useCaseActions from 'hooks/data/cases'

const useMenuStyles = makeStyles(theme => ({ menu: { '& li': { color: theme.palette.secondary.main } } }))

function useStyles() {
    return {
        ...useButtonStyles(),
        ...useMenuStyles()
    }
}

export default function CaseActionsButton({ theCase }: { theCase: Case }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [saveDialogOpen, setSaveDialogOpen] = useState(false)
    const { data: caseActions } = useCaseActions(theCase.id, Boolean(anchorEl))
    const classes = useStyles()

    return (
        <React.Fragment>
            {saveDialogOpen && <SaveItemToList item={theCase} onClose={() => setSaveDialogOpen(false)} />}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                className={classes.menu}
            >
                <MenuItem onClick={() => setSaveDialogOpen(true)}>{gettext('Add to / Remove from list')}</MenuItem>
                {caseActions?.editViewerCourseModules && (
                    <Link href={caseActions.editViewerCourseModules}>
                        <MenuItem>{gettext('Edit case and module order')}</MenuItem>
                    </Link>
                )}
            </Menu>

            <Button
                variant="contained"
                className={clsx([classes.button, classes.bordered])}
                onClick={event => setAnchorEl(event.currentTarget)}
            >
                <MoreVertIcon />
            </Button>
        </React.Fragment>
    )
}
