/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useQueryClient, useMutation } from '@tanstack/react-query'

import useUrls from 'hooks/useUrls'

import mutateContentItem from './mutateContentItem'

export default function useListMutation() {
    const urls = useUrls()
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ id, changes }: { id: number; changes: Partial<List> }): Promise<List> =>
            mutateContentItem(urls['lists:listDetail'](id), changes),
        onSuccess: response => {
            queryClient.setQueriesData(['List', 'list'], (oldLists: List[]) =>
                oldLists.map((oldList: List) => (oldList.id === response.id ? response : oldList))
            )
            queryClient.setQueryData(['List', 'detail', response.id], response)
        }
    })
}
