/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import axios from 'axios'
import React from 'react'
import { createRoot } from 'react-dom/client'

import MaterialRoot from 'components/materialTheme/MaterialRoot'
import type { AnnouncementType } from 'sections/announcements/announcement'
import Announcement from 'sections/announcements/announcement'

async function main() {
    const isInFrame = window.parent !== window
    if (isInFrame) {
        return
    }

    const domElement = document.getElementById('announcement-banner')
    if (!domElement) {
        return
    }
    const announcements: AnnouncementType[] = (await axios.get(window.Urls['announcements:announcements']())).data
    if (announcements.length === 0) {
        return
    }

    const root = createRoot(domElement)
    root.render(
        <MaterialRoot seed="announcements">
            {announcements.map(announcement => (
                <Announcement key={announcement.message} announcement={announcement} />
            ))}
        </MaterialRoot>
    )
}

main()
