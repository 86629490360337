/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button, Link, Snackbar, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import React, { useState } from 'react'

import MaterialRoot from 'components/materialTheme/MaterialRoot'

function CookieDisclaimer() {
    const [open, setOpen] = useState(true)
    if (Cookies.get('cookieDisclaimerRead')) {
        return null
    }
    const handleClose = () => {
        setOpen(false)
        Cookies.set('cookieDisclaimerRead', true, { path: window.COOKIE_PATH, expires: 365 })
    }
    const message = (
        <React.Fragment>
            <Typography color="inherit">
                {gettext(
                    'Cookies help us deliver our services. By using our services, you agree to our use of cookies.'
                )}{' '}
                <Link href={`${window.Urls['termsofuse:show']()}#data_privacy`}>{gettext('More Info')}</Link>
            </Typography>
        </React.Fragment>
    )
    const action = (
        <Button color="primary" size="small" variant="contained" onClick={handleClose}>
            {gettext('Close')}
        </Button>
    )
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            onClose={handleClose}
            action={action}
            message={message}
            ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
        />
    )
}

export default function (props) {
    return (
        <MaterialRoot seed="cookie_disclaimer">
            <CookieDisclaimer {...props} />
        </MaterialRoot>
    )
}
