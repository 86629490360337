/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Build as SettingsIcon } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    menuItem: {
        '&, & *': {
            color: theme.palette.footer.text,
            // Override global CSS
            fontWeight: 'normal !important'
        },
        fontSize: 11,
        letterSpacing: 'normal'
    },
    button: {
        boxSizing: 'border-box'
    },
    settingsMenu: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    }
}))

export default function SettingsMenu({ settingsMenuItems }: { settingsMenuItems: any[] }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    if (!settingsMenuItems || !settingsMenuItems.length) {
        return null
    }

    return (
        <div data-testid="settingsMenu" className={classes.settingsMenu}>
            <IconButton onClick={handleMenu} color="inherit" data-testid="settingsMenuButton" size="small">
                <SettingsIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                {settingsMenuItems.map(({ text, ...linkProps }) => (
                    <Link key={text} {...linkProps}>
                        <MenuItem className={classes.menuItem}>{text}</MenuItem>
                    </Link>
                ))}
            </Menu>
        </div>
    )
}
