/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import InformationModal from 'components/modals/InformationModal'

export default function ErrorModal({
    contactInformation,
    errorMessage = gettext('Sorry, there has been an error.'),
    reason = ''
}: {
    contactInformation: string
    errorMessage?: string
    reason?: string
}) {
    const [open, setOpen] = useState(true)

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(errorMessage)
        if (reason) {
            // eslint-disable-next-line no-console
            console.log(reason)
        }
    }, [errorMessage, reason])

    const onClose = () => {
        setOpen(false)
    }

    return (
        <InformationModal title={gettext('Sorry, an error occurred')} open={open} onClose={onClose}>
            {errorMessage && <Typography>{errorMessage}</Typography>}
            {reason && <Typography>{reason}</Typography>}
            <Typography>{gettext('The site administrators have been notified.')}</Typography>
            <Typography dangerouslySetInnerHTML={{ __html: contactInformation }} />
        </InformationModal>
    )
}
