/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import type { Root } from 'react-dom/client'
import { createRoot } from 'react-dom/client'

import ErrorModal from './ErrorModal'
import MeVisReactComponentsMaterialRoot from './MeVisReactComponentsMaterialRoot'

let root: Root = null

function defaultAjaxErrorHandler(errorMessage = undefined) {
    return function (reason?: any) {
        // In Firefox, these errors come up when leaving a page while requests are still pending
        // We don't really care about these errors
        if (reason?.code === 'ECONNABORTED') {
            return
        }
        const reasonString = reason ? String(reason) : undefined
        const errorMessageString = errorMessage ? String(errorMessage) : undefined
        const contactElement = document.getElementById('contact_information')
        const contactInformation = contactElement ? contactElement.innerHTML : ''

        root?.unmount()
        root = createRoot(document.getElementById('errorModal'))
        root.render(
            <MeVisReactComponentsMaterialRoot seed="error_modal">
                <ErrorModal
                    contactInformation={contactInformation}
                    errorMessage={errorMessageString}
                    reason={reasonString}
                />
            </MeVisReactComponentsMaterialRoot>
        )
        /* eslint-disable no-console */
        if (errorMessage) {
            console.error(errorMessage)
        }
        if (reason) {
            console.error(reason)
        }
        /* eslint-enable no-console */
    }
}

export default { defaultAjaxErrorHandler }
