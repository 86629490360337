/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, GetApp as DownloadIcon } from '@mui/icons-material'
import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React, { useState, useRef } from 'react'

import ConfirmDeletionModal from 'components/modals/ConfirmDeletionModal'
import utils from 'components/react_components/utils'
import useCertificateList from 'hooks/data/certificates'
import usePromoCodes from 'hooks/data/promocodes'
import useUrls from 'hooks/useUrls'

export default function Certificates({ courseId }: { courseId: number }) {
    const urls = useUrls()
    const { data: promoCodes } = usePromoCodes({ courses: courseId })
    const { data: certificates } = useCertificateList({ course: courseId })
    const queryClient = useQueryClient()

    const uploadInputRef = useRef(null)
    const [certificateToDelete, setCertificateToDelete] = useState(null)
    const [promoCodeCode, setPromoCodeCode] = useState(null)
    const [certificateId, setCertificateId] = useState(null)

    const onCloseModal = () => {
        setCertificateToDelete(null)
    }

    const deleteCertificate = async certificateId => {
        try {
            await axios.delete(urls['publishing:certificate_detail'](certificateId))
            queryClient.invalidateQueries(['Certificate'])
        } catch (error) {
            utils.defaultAjaxErrorHandler(gettext('Sorry! Deleting Certificate failed.'))(error)
        }
    }

    const onDeleteCertificate = certificateToDelete => {
        deleteCertificate(certificateToDelete.id)
        setCertificateToDelete(null)
    }

    const defaultCertificate = () => {
        const defaultCertificates = certificates.filter(c => !c.promoCode)
        return defaultCertificates.length === 0 ? null : defaultCertificates[0]
    }

    const promoCodesWithoutCertificate = () =>
        promoCodes.filter(p => certificates.filter(c => c.promoCode === p.code).length === 0)

    const createOrEditCertificate = async selectedTemplate => {
        try {
            const formData = new FormData()
            formData.append('template', selectedTemplate)

            if (certificateId) {
                await axios.patch(urls['publishing:certificate_detail'](certificateId), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            } else {
                formData.append('course', courseId.toString())
                if (promoCodeCode) {
                    formData.append('promoCode', promoCodeCode)
                }
                await axios.post(urls['publishing:certificateList'](), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            }
            queryClient.invalidateQueries(['Certificate'])
        } catch (error) {
            utils.defaultAjaxErrorHandler(
                certificateId
                    ? gettext('Sorry! Editing Certificate failed.')
                    : gettext('Sorry! Creating Certificate failed.')
            )(error)
        } finally {
            setPromoCodeCode(null)
            setCertificateId(null)
        }
    }

    if (!certificates || !promoCodes) {
        return null
    }

    return (
        <div>
            <input
                data-testid="fileInput"
                ref={uploadInputRef}
                type="file"
                accept="application/pdf"
                value=""
                onChange={event => {
                    createOrEditCertificate(event.target.files[0])
                }}
                style={{ display: 'none' }}
            />
            {certificateToDelete && (
                <ConfirmDeletionModal
                    object={certificateToDelete}
                    contentText={
                        certificateToDelete.promoCode
                            ? `${gettext('Are you sure you want to delete the certificate for promo code: ')} ${
                                  certificateToDelete.promoCode
                              }?`
                            : `${gettext('Are you sure you want to delete the default certificate?')}`
                    }
                    onReject={onCloseModal}
                    onSubmit={onDeleteCertificate}
                />
            )}
            <Typography variant="h2">{gettext('Manage certificates')}</Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{gettext('Promo Code')}</TableCell>
                            <TableCell align="center">{gettext('Edit')}</TableCell>
                            <TableCell align="center">{gettext('Create')}</TableCell>
                            <TableCell align="center">{gettext('Delete')}</TableCell>
                            <TableCell align="center">{gettext('Download Certificate Preview')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {certificates.map(certificate => (
                            <TableRow key={certificate.id}>
                                <TableCell>
                                    {certificate.promoCode ? certificate.promoCode : gettext('Default Certificate')}
                                </TableCell>
                                <TableCell align="center">
                                    <Link
                                        component="button"
                                        type="button"
                                        onClick={event => {
                                            event.preventDefault()
                                            setCertificateId(certificate.id)
                                            setPromoCodeCode(certificate.promoCode)
                                            if (uploadInputRef.current) {
                                                uploadInputRef.current.click()
                                            }
                                        }}
                                        title={gettext('Change PDF Template')}
                                    >
                                        <EditIcon />
                                    </Link>
                                </TableCell>
                                <TableCell />
                                <TableCell align="center">
                                    <Link
                                        component="button"
                                        type="button"
                                        data-testid="deleteCertificate"
                                        onClick={event => {
                                            event.preventDefault()
                                            setCertificateToDelete(certificate)
                                        }}
                                    >
                                        <DeleteIcon />
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <Link href={urls['publishing:download_certificate_preview'](certificate.id)}>
                                        <DownloadIcon style={{ verticalAlign: 'bottom' }} />
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                        {!defaultCertificate() && (
                            <TableRow>
                                <TableCell>{gettext('Default Certificate')}</TableCell>
                                <TableCell />
                                <TableCell align="center">
                                    <Link
                                        component="button"
                                        type="button"
                                        onClick={event => {
                                            event.preventDefault()
                                            if (uploadInputRef.current) {
                                                uploadInputRef.current.click()
                                            }
                                        }}
                                        title={gettext('Add PDF Template')}
                                    >
                                        <AddIcon />
                                    </Link>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        )}

                        {promoCodesWithoutCertificate().map(p => (
                            <TableRow key={p.code}>
                                <TableCell>{p.code}</TableCell>
                                <TableCell />
                                <TableCell align="center">
                                    <Link
                                        component="button"
                                        type="button"
                                        onClick={event => {
                                            event.preventDefault()
                                            setPromoCodeCode(p.code)
                                            if (uploadInputRef.current) {
                                                uploadInputRef.current.click()
                                            }
                                        }}
                                        title={gettext('Add PDF Template')}
                                    >
                                        <AddIcon />
                                    </Link>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
