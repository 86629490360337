/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { DialogContentText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { makeValidate } from 'mui-rff'
import { useSnackbar } from 'notistack'
import React from 'react'
import * as Yup from 'yup'

import TextFieldWrapper from 'components/forms/TextFieldWrapper'
import FormModal from 'components/modals/FormModal'
import utils from 'components/react_components/utils'
import useCheckEmail from 'hooks/useCheckEmail'
import useUrls from 'hooks/useUrls'

import { useList, useListInvitationTemplate } from '../../hooks/data/lists'
import parseCommaSeparatedEmails from '../../utils/parseEmailList'

export default function ListInvitationModal({ listId, onHide }: { listId: number; onHide: (...args: any) => any }) {
    const { enqueueSnackbar } = useSnackbar()
    const urls = useUrls()
    const theme = useTheme()
    useCheckEmail()
    const { data: theList } = useList(listId)
    const { data: template } = useListInvitationTemplate(listId)
    const queryClient = useQueryClient()

    if (!template || !theList) {
        return null
    }

    const schema = Yup.object().shape({
        allowedUsers: Yup.string().required(gettext('Required')).checkEmail('Check Email'),
        subject: Yup.string().required(gettext('Required')),
        content: Yup.string().required(gettext('Required'))
    })
    const validate = makeValidate(schema)

    const onSubmit = async values => {
        try {
            const allowedUsers = parseCommaSeparatedEmails(values.allowedUsers)
            const response = await axios.post(urls['lists:list_inviteUsersToList'](theList.id), {
                allowedUsers,
                subject: values.subject,
                content: values.content
            })

            queryClient.setQueryData(['List', 'detail', listId], response.data)
            queryClient.invalidateQueries(['List', 'list'])
            enqueueSnackbar(gettext('Users have been invited to list successfully'), {
                variant: 'success'
            })
            onHide()
        } catch (error) {
            if (error.response?.status === 400 && error.response.data?.allowedUsers) {
                return {
                    allowedUsers: (
                        <React.Fragment>
                            {error.response.data.allowedUsers.map(errorMessage => (
                                // Show each error on a separate line
                                // This renders inside a p, so we cannot use divs here
                                <span style={{ display: 'block' }} key={errorMessage}>
                                    {errorMessage}
                                </span>
                            ))}
                        </React.Fragment>
                    )
                }
            }
            utils.defaultAjaxErrorHandler(error)(gettext('Sorry! An error occurred while sharing the list.'))
        }

        return {}
    }

    const alreadyInvitedUsers = () => theList.allowedUsers?.map(user => user.userNameForDisplay).join(', ')

    return (
        <FormModal
            title={gettext('Invite users to list')}
            subtitle={
                <React.Fragment>
                    <DialogContentText>
                        {gettext('Invite users who should be able to see the content of the list in their carousels')}
                    </DialogContentText>
                    <DialogContentText>
                        {interpolate(gettext('Already Invited: %s'), [alreadyInvitedUsers()])}
                    </DialogContentText>
                </React.Fragment>
            }
            submitText={gettext('Invite Users')}
            open
            onReject={onHide}
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
                subject: template.subject,
                content: template.body,
                list: theList.id
            }}
        >
            <TextFieldWrapper
                style={{ marginTop: theme.spacing(1) }}
                name="allowedUsers"
                label={gettext('Email address required. Separate multiple addresses with ,')}
                variant="outlined"
                type="text"
            />
            <TextFieldWrapper name="subject" label={gettext('Subject')} variant="outlined" type="text" />
            <TextFieldWrapper name="content" label={gettext('Content')} multiline rows={10} variant="outlined" />
        </FormModal>
    )
}
