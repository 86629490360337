/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Bookmark as BookmarkIcon, BookmarkBorder as BookmarkBorderIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React from 'react'

import { useBookmarkedCourses } from 'hooks/data/courses'

const useStyles = makeStyles(theme => ({
    button: {
        border: `1px solid ${theme.palette.borderedButton}`,
        width: 40,
        minWidth: 40,
        padding: 0,
        color: theme.palette.button.color,
        backgroundColor: theme.palette.button.background,
        '&:hover': {
            backgroundColor: theme.palette.button.background
        }
    }
}))

function useBookmarkCourseMutation(course: { id: number }) {
    const queryClient = useQueryClient()
    return useMutation({
        onMutate: () =>
            queryClient.setQueryData<Array<any>>(['Course', 'list', 'bookmarked'], oldList => [course, ...oldList]),
        mutationFn: () => axios.post(window.Urls.courseBookmark(course.id))
    })
}

function useUnbookmarkCourseMutation(course: { id: number }) {
    const queryClient = useQueryClient()
    return useMutation({
        onMutate: () =>
            queryClient.setQueryData<Array<any>>(['Course', 'list', 'bookmarked'], oldList =>
                oldList.filter(bc => bc.id !== course.id)
            ),
        mutationFn: () => axios.post(window.Urls.courseUnbookmark(course.id))
    })
}

export function useCourseBookmark(course: { id: number }) {
    const { data: courseIsBookmarked, isLoading } = useBookmarkedCourses({
        select: bookmarkedCourses => Boolean(bookmarkedCourses.find(cb => cb.id === course.id))
    })
    const { mutate: bookmarkCourse, isLoading: isBookmarking } = useBookmarkCourseMutation(course)
    const { mutate: unbookmarkCourse, isLoading: isUnbookmarking } = useUnbookmarkCourseMutation(course)

    return {
        saving: isBookmarking || isUnbookmarking,
        loading: isLoading,
        courseIsBookmarked,
        onBookmarkChangedCb: courseIsBookmarked ? unbookmarkCourse : bookmarkCourse
    }
}

export default function CourseBookmark({ course }) {
    const { saving, loading, courseIsBookmarked, onBookmarkChangedCb } = useCourseBookmark(course)
    const classes = useStyles()
    let title
    if (saving) {
        title = gettext('Saving...')
    } else {
        title = courseIsBookmarked ? gettext('Remove Bookmark') : gettext('Add Bookmark')
    }

    return (
        <Button
            onClick={() => onBookmarkChangedCb()}
            title={title}
            disabled={saving || loading}
            className={classes.button}
        >
            {courseIsBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
        </Button>
    )
}
