/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import type { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers'
import { DesktopDatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import deLocale from 'date-fns/locale/de'
import type { DatePickerProps } from 'mui-rff'
import { DatePicker } from 'mui-rff'
import React from 'react'

const localeMap = { de: deLocale }

export function LocalizedDatePicker(props: DatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[window.LANGUAGE]}>
            <DatePicker {...props} />
        </LocalizationProvider>
    )
}

export function StandaloneLocalizedDatePicker(props: MuiDatePickerProps<any, any>) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[window.LANGUAGE]}>
            <MuiDatePicker
                // invalidDateMessage={gettext('Invalid Date Format')}
                // minDateMessage={gettext('Date should not be before minimal date')}
                {...props}
            />
        </LocalizationProvider>
    )
}
