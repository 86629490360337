/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { CardMedia, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import EditableThumbnail from 'components/editable/EditableThumbnail'

import CourseProgress from './CourseProgress'

const useStyles = makeStyles({
    container: {
        height: '50%'
    },
    media: {
        borderRadius: '2px 2px 0 0',
        width: '100%',
        height: '100%'
    }
})

export default function CoursePreview({ course, showProgress = true, triggerMutation }) {
    const classes = useStyles()

    let coursePreview = course.thumbnail ? (
        <CardMedia
            component="img"
            loading="lazy"
            image={course.thumbnail}
            className={classes.media}
            data-testid="thumbnail"
        />
    ) : (
        <div className={classes.media} data-testid="thumbnail" />
    )
    if (course.startButton && course.startButton.url) {
        coursePreview = <Link href={course.startButton.url}>{coursePreview}</Link>
    }
    return (
        <React.Fragment>
            <EditableThumbnail
                contentItem={course}
                name="thumbnail"
                triggerMutation={triggerMutation}
                className={classes.container}
            >
                {coursePreview}
            </EditableThumbnail>
            {showProgress && <CourseProgress course={course} />}
        </React.Fragment>
    )
}
