/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button, Card, CardActions } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { noop } from 'lodash'
import React, { useState } from 'react'

import CourseBookmark from 'components/bookmarks/CourseBookmark'
import useButtonStyles from 'components/buttonStyles'
import CourseDetailsModal from 'components/course/CourseDetailsModal'
import CourseActionsButton from 'components/course_actions/CourseActionsButton'
import { CoursePreview } from 'components/course_tile'
import StartButton from 'components/StartButton'

import Caption from './Caption'
import { cardMediaQueries } from './mediaQueries'

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: '2px',
        padding: '0',
        marginBottom: '10px',
        border: `1px solid`,
        // @ts-ignore
        background: theme.palette.background.card,
        ...cardMediaQueries(theme)
    },
    courseNotPublished: {
        borderColor: theme.palette.primary.main
    },
    coursePublished: {
        // @ts-ignore
        borderColor: theme.palette.background.card
    },
    cardActions: {
        justifyContent: 'space-between',
        alignItems: 'stretch',
        overflow: 'hidden',
        padding: '0 16px 16px 16px'
    },
    startButtonContainer: {
        display: 'flex',
        alignItems: 'stretch',
        gap: theme.spacing(1)
    },
    actionButtons: { display: 'flex', alignItems: 'stretch', gap: theme.spacing(1) }
}))

export default function CourseTile({
    course,
    preview = false,
    userIsAuthenticated = false,
    triggerMutation = noop
}: {
    course: Course
    preview?: boolean
    userIsAuthenticated?: boolean
    triggerMutation?: (...args: any) => any
}) {
    const [showCourseDetailsModal, setShowCourseDetailsModal] = useState(false)
    const classes = useStyles()
    const buttonClasses = useButtonStyles()

    const openCourseDetailsModal = () => {
        setShowCourseDetailsModal(true)
    }
    const closeCourseDetailsModal = () => {
        setShowCourseDetailsModal(false)
    }

    return (
        <React.Fragment>
            {showCourseDetailsModal && (
                <CourseDetailsModal
                    highlights={course.highlights}
                    courseId={course.id}
                    onHide={closeCourseDetailsModal}
                    userIsAuthenticated={userIsAuthenticated}
                    triggerMutation={triggerMutation}
                />
            )}
            <Card
                className={clsx([
                    classes.card,
                    course.status === 'published' ? classes.coursePublished : classes.courseNotPublished
                ])}
                style={{ pointerEvents: preview ? 'none' : 'all' }}
                data-testid="courseTile"
            >
                <CoursePreview course={course} showProgress={userIsAuthenticated} triggerMutation={triggerMutation} />
                <Caption course={course} triggerMutation={triggerMutation} />
                {!preview && (
                    <CardActions className={classes.cardActions}>
                        <div className={classes.startButtonContainer}>
                            <StartButton config={course.startButton} />
                            {userIsAuthenticated && <CourseBookmark course={course} />}
                        </div>
                        <div className={classes.actionButtons}>
                            {userIsAuthenticated && (
                                <CourseActionsButton
                                    course={course}
                                    openCourseDetailsModal={openCourseDetailsModal}
                                    extraActions
                                />
                            )}
                            <Button
                                className={clsx([buttonClasses.button, buttonClasses.bordered])}
                                title={gettext('Show details')}
                                onClick={openCourseDetailsModal}
                            >
                                <i className="fa-solid fa-info fa-xl" />
                            </Button>
                        </div>
                    </CardActions>
                )}
            </Card>
        </React.Fragment>
    )
}
