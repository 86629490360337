/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button } from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import useStyles from 'components/buttonStyles'

const iconLookup = {
    [gettext('Restricted')]: 'fa-key',
    [gettext('Buy')]: 'fa-euro'
}

export default function IconOnlyStartButton({ startButton }: { startButton: { text: string; url: string } }) {
    const classes = useStyles()
    if (!startButton.url) {
        return null
    }

    if (startButton.text !== gettext('Restricted') && startButton.text !== gettext('Buy')) {
        return null
    }

    const icon = iconLookup[startButton.text]

    return (
        <a href={startButton.url}>
            <Button
                variant="contained"
                className={clsx([classes.button, classes.bordered])}
                color="primary"
                title={startButton.text}
            >
                <i className={`fas ${icon}`} />
            </Button>
        </a>
    )
}
