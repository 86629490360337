/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import axios from 'axios'

export default function mutateContentItem(url: string, changes: any) {
    if (Object.values(changes).some(v => v instanceof File)) {
        const formData = new FormData()
        formData.append('thumbnail', changes.thumbnail)
        return axios
            .patch(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(res => res.data)
    }
    return axios.patch(url, changes).then(res => res.data)
}
