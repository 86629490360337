/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import EditableSelect from 'components/editable/EditableSelect'
import { useContentTypes } from 'hooks/data/misc'

export default function CaseType({
    course,
    triggerMutation,
    dense = false
}: {
    course: Course & { numberOfCases?: number }
    triggerMutation: (...args: any) => any
    dense?: boolean
}) {
    const { data: contentTypes } = useContentTypes({ courseType: true })

    if (!contentTypes) {
        return null
    }

    let s = ''
    if (course.type) {
        s += course.type.toUpperCase()
    }
    if (course.type && course.numberOfCases > 0) {
        s += ' - '
    }
    if (course.numberOfCases > 0) {
        s += `${course.numberOfCases} ${ngettext('Case', 'Cases', course.numberOfCases)} `
    }
    return (
        <EditableSelect
            contentItem={course}
            triggerMutation={triggerMutation}
            name="type"
            options={contentTypes.map(o => o.name)}
            defaultSelection={course.type}
        >
            <Typography variant="overline" style={{ minHeight: '1lh', fontSize: dense ? '12px' : '14px' }}>
                {s}
            </Typography>
        </EditableSelect>
    )
}
