/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Typography } from '@mui/material'
import noop from 'lodash/noop'
import React from 'react'

import ContentAuthors from 'components/ContentAuthors'
import CMEPoints from 'components/course/CMEPoints'
import AdditionalFilters from 'components/course_tile/AdditionalFilters'
import CaseType from 'components/course_tile/CaseType'
import CourseAccess from 'components/course_tile/CourseAccess'
import PublishingStatus from 'components/course_tile/PublishingStatus'
import PublishingYear from 'components/course_tile/PublishingYear'
import EditableText from 'components/editable/EditableText'
import Highlighter from 'components/highlighter/Highlighter'
import Keywords from 'components/Keywords'
import InformationModal from 'components/modals/InformationModal'
import TargetAudiences from 'components/TargetAudiences'
import { useCourseDetails } from 'hooks/data/courses'
import { validateTitle, validateShortDescription } from 'utils/validation'

import ClinicalAuthor from './ClinicalAuthor'
import CourseAccessInformation from './CourseAccessInformation'

import 'react-perfect-scrollbar/dist/css/styles.css'

export default function CourseDetailsModal({
    courseId,
    highlights = [],
    onHide = noop,
    userIsAuthenticated = true,
    triggerMutation
}: {
    courseId: number
    highlights?: any[]
    onHide: (...args: any) => any
    userIsAuthenticated?: boolean
    triggerMutation?: (...args: any) => any
}) {
    const { data: course } = useCourseDetails(courseId)

    if (!course) {
        return null
    }

    return (
        <InformationModal
            title={
                <EditableText
                    contentItem={course}
                    triggerMutation={triggerMutation}
                    name="title"
                    defaultValue={course.title}
                    validationSchema={validateTitle}
                >
                    <Highlighter searchWords={highlights} textToHighlight={course.title} />
                </EditableText>
            }
            subtitle={
                <EditableText
                    contentItem={course}
                    triggerMutation={triggerMutation}
                    name="shortDescription"
                    defaultValue={course.shortDescription}
                    validationSchema={validateShortDescription}
                >
                    {course.shortDescription}
                </EditableText>
            }
            onClose={onHide}
        >
            <Box display="flex" flexDirection="column">
                <CaseType course={course} triggerMutation={triggerMutation} />
                <Typography paragraph />
                <CourseAccessInformation course={course} triggerMutation={triggerMutation} />
                <CourseAccess course={course} userIsAuthenticated={userIsAuthenticated} />
                <Typography paragraph />
                <PublishingYear contentItem={course} triggerMutation={triggerMutation} />
                <PublishingStatus course={course} />
                <Typography paragraph />
                <ClinicalAuthor course={course} triggerMutation={triggerMutation} />
                <ContentAuthors
                    contentItem={course}
                    triggerMutation={triggerMutation}
                    name="courseAuthors"
                    label={gettext('Course Author(s)')}
                />
                <AdditionalFilters course={course} highlights={highlights} />
                <TargetAudiences contentItem={course} triggerMutation={triggerMutation} />
                <Keywords contentItem={course} triggerMutation={triggerMutation} />
                <CMEPoints course={course} triggerMutation={triggerMutation} />
            </Box>
        </InformationModal>
    )
}
