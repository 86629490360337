/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import MaterialRoot from 'components/materialTheme/MaterialRoot'

import BrowserCompatibility from './BrowserCompatibilityTest'

const root = createRoot(document.getElementById('browserCompatibilityTest'))
root.render(
    <MaterialRoot seed="browser_compatibility_test">
        <BrowserCompatibility />
    </MaterialRoot>
)
