/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import MaterialRoot from 'components/materialTheme/MaterialRoot'

import HostMismatchModal from './HostMismatchModal'

const host = document.body.getAttribute('data-host')
const root = createRoot(document.getElementById('hostMismatchModal'))
root.render(
    <MaterialRoot seed="host_mismatch_modal">
        <HostMismatchModal host={host} />
    </MaterialRoot>
)
