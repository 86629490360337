/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'

import CourseCarousel from 'components/CourseCarousel'
import ListCarousel from 'components/ListCarousel'
import { useBookmarkedCourses, useLastSeenCourses, usePurchasedCourses } from 'hooks/data/courses'
import { useMyLists } from 'hooks/data/lists'

export default function UserCarousels() {
    const { data: bookmarkedCourses } = useBookmarkedCourses()
    const { data: lastSeenCourses } = useLastSeenCourses()
    const { data: purchasedCourses } = usePurchasedCourses()
    const { data: lists } = useMyLists()
    const queryClient = useQueryClient()
    const theme = useTheme()

    const removeList = list =>
        queryClient.setQueriesData<List[]>(['List', 'list'], oldLists => oldLists.filter(l => l.id !== list.id))

    return (
        <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
            {lastSeenCourses && lastSeenCourses.length > 0 && (
                <CourseCarousel
                    courses={lastSeenCourses}
                    title={gettext('last seen')}
                    url="lastseen/"
                    userIsAuthenticated
                />
            )}
            {purchasedCourses && purchasedCourses.length > 0 && (
                <CourseCarousel
                    courses={purchasedCourses}
                    title={gettext('my activated content')}
                    url="purchased/"
                    userIsAuthenticated
                />
            )}
            {bookmarkedCourses && bookmarkedCourses.length > 0 && (
                <CourseCarousel
                    courses={bookmarkedCourses}
                    title={gettext('my bookmarked content')}
                    url="bookmarked/"
                    userIsAuthenticated
                />
            )}
            {lists && lists.length > 0 && (
                <ListCarousel lists={lists} title={gettext('my lists')} url="lists" onListRemoved={removeList} />
            )}
        </Box>
    )
}
