/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import trim from 'lodash/trim'
import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom'

import AddCarousel from 'components/AddCarousel'
import { BookmarkedCoursesGrid, LastSeenCoursesGrid, PurchasedCoursesGrid } from 'components/CourseGrid'
import EditCarousel from 'components/EditCarousel'
import ListDetail from 'components/ListDetail'
import ListGrid from 'components/ListGrid'
import MaterialRoot from 'components/materialTheme/MaterialRoot'
import SingleCarousel from 'components/SingleCarousel'

import CoursesOverview from './CoursesOverview'

function ScrollToTop() {
    const { pathname } = useLocation()

    useEffect(() => {
        const contentMain = document.getElementById('content-main')
        if (contentMain) {
            contentMain.scrollTo(0, 0)
        }
    }, [pathname])
    return null
}

const basename = `/${window.location.pathname.split('/').filter(Boolean)[0]}`

const root = createRoot(document.getElementById('root'))
root.render(
    <Router basename={basename}>
        <MaterialRoot seed="courses_overview">
            <ScrollToTop />
            <Switch>
                <Route exact path="/edit_carousel/:id" component={EditCarousel} />
                <Route exact path="/add_carousel/:index" component={AddCarousel} />
                <Route exact path="/single_carousel/:id" component={SingleCarousel} />
                <Route exact path="/bookmarked/" component={BookmarkedCoursesGrid} />
                <Route exact path="/purchased/" component={PurchasedCoursesGrid} />
                <Route exact path="/lastseen/" component={LastSeenCoursesGrid} />
                <Route exact path="/lists/" component={ListGrid} />
                <Route exact path="/lists/:id" component={ListDetail} />
                <Route
                    path="*/"
                    render={({ location }) => {
                        if (location.hash) {
                            return <Redirect to={location.pathname + trim(location.hash.replace('#', ''), '/')} />
                        }
                        return <CoursesOverview />
                    }}
                />
            </Switch>
        </MaterialRoot>
    </Router>
)
