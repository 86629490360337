/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button, Typography } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'

import useButtonStyles from 'components/buttonStyles'
import ContentAuthors from 'components/ContentAuthors'
import PublishingYear from 'components/course_tile/PublishingYear'
import EditableText from 'components/editable/EditableText'
import Keywords from 'components/Keywords'
import InformationModal from 'components/modals/InformationModal'
import TargetAudiences from 'components/TargetAudiences'
import { validateTitle as schema } from 'utils/validation'

interface ListDetailsModalProps {
    list: List
    onHide: (...args: any[]) => any
    triggerMutation?: (opts: { id: number; changes: Partial<List> }) => void
}

function ListDetailsModal({ list, onHide, triggerMutation }: ListDetailsModalProps) {
    return (
        <InformationModal
            title={
                <EditableText
                    contentItem={list}
                    triggerMutation={triggerMutation}
                    name="title"
                    defaultValue={list.title}
                    validationSchema={schema}
                >
                    {list.title}
                </EditableText>
            }
            subtitle={list.shortDescription}
            onClose={onHide}
        >
            <div>
                <Typography variant="overline">{gettext('List')}</Typography>
                <Typography paragraph />
                <PublishingYear contentItem={list} triggerMutation={triggerMutation} />
                <Typography variant="body2" component="div">
                    {`${gettext('Publishing status')}: ${list.isPublic ? gettext('Public') : gettext('Private')}`}
                </Typography>
                <Typography paragraph />
                <ContentAuthors
                    contentItem={list}
                    triggerMutation={triggerMutation}
                    name="listAuthors"
                    label={gettext('List Author(s)')}
                />
                <TargetAudiences contentItem={list} triggerMutation={triggerMutation} />
                <Keywords contentItem={list} triggerMutation={triggerMutation} />
            </div>
        </InformationModal>
    )
}

interface ListDetailButtonProps {
    list: List
    triggerMutation?: (opts: { id: number; changes: Partial<List> }) => void
}

export default function ListDetailButton({ list, triggerMutation }: ListDetailButtonProps) {
    const buttonClasses = useButtonStyles()

    const [showDetails, setShowDetails] = useState(false)

    return (
        <React.Fragment>
            {showDetails && (
                <ListDetailsModal list={list} onHide={() => setShowDetails(false)} triggerMutation={triggerMutation} />
            )}
            <Button
                className={clsx(['listInfoButton', buttonClasses.button, buttonClasses.bordered])}
                title={gettext('Show details')}
                onClick={() => setShowDetails(true)}
            >
                <i className="fa-solid fa-info fa-xl" />
            </Button>
        </React.Fragment>
    )
}
