/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import MaterialRoot from 'components/materialTheme/MaterialRoot'

import Navbar from './Navbar'

const navbarRoot = document.getElementById('navbarRoot')

async function main() {
    const isInFrame = window.parent !== window
    if (isInFrame) {
        if (navbarRoot) {
            navbarRoot.style.display = 'none'
        }
        return
    }

    const root = createRoot(navbarRoot)
    root.render(
        <MaterialRoot seed="navbar">
            <Navbar />
        </MaterialRoot>
    )
}

main()
