/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Link } from 'react-router-dom'

import useListMutation from 'components/editable/useListMutation'
import PageContainer from 'components/react_components/PageContainer'
import { useMyLists } from 'hooks/data/lists'

import ListTile from './list_tile/ListTile'

export default function ListGrid() {
    const { data: lists } = useMyLists()
    const queryClient = useQueryClient()
    const patchList = useListMutation()

    if (!lists) {
        return null
    }

    return (
        <PageContainer fullWidth>
            <Box display="flex" alignItems="center" mb={1} mt={2}>
                <Link to="/">
                    <IconButton style={{ color: 'inherit' }} edge="start" size="large">
                        <ArrowBackIcon />
                    </IconButton>
                </Link>
                <Typography variant="h1">{gettext('my lists')}</Typography>
            </Box>
            <Grid container spacing={2}>
                {lists.map(list => (
                    <Grid item key={list.id}>
                        <ListTile
                            list={list}
                            onListRemoved={() =>
                                queryClient.setQueriesData(['List', 'list'], (oldLists: any[]) =>
                                    oldLists.filter(l => l.id !== list.id)
                                )
                            }
                            triggerMutation={patchList.mutate}
                        />
                    </Grid>
                ))}
            </Grid>
        </PageContainer>
    )
}
