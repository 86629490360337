/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

/**
 * Parses and cleans a string of comma separated emails. Returns a list of emails
 */
export default function parseCommaSeparatedEmails(emails: string): Array<string> {
    const recipients = emails
        .split(',')
        .map(r => r.trim())
        .filter(r => r !== '')

    return [...new Set(recipients)]
}
