/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Edit as EditIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles(theme => ({
    editButton: {
        marginLeft: 'auto',
        marginRight: '0 !important',
        alignSelf: 'flex-start',
        padding: 0,
        zIndex: theme.zIndex.drawer + 1, // set above scroll bar
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    container: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        '& button': {
            display: 'none'
        },
        '&:hover button': {
            display: 'flex'
        }
    }
}))

interface EditableComponentContainerProps {
    containerRef: React.RefObject<HTMLDivElement>
    children: React.ReactNode
    className?: string
    onClick: React.MouseEventHandler
    editOnClick?: boolean
    onMouseEnter: React.MouseEventHandler
    canEdit: boolean
    buttonStyle: object
    iconStyle: object
}

export default function EditableComponentContainer({
    containerRef,
    className,
    onClick,
    editOnClick = true,
    onMouseEnter,
    children,
    canEdit,
    buttonStyle,
    iconStyle
}: EditableComponentContainerProps) {
    const classes = useStyles()
    return (
        <div
            ref={containerRef}
            className={clsx(className, classes.container)}
            onMouseEnter={onMouseEnter}
            onClick={canEdit && editOnClick ? onClick : undefined}
        >
            {children}
            {canEdit && (
                <IconButton
                    className={classes.editButton}
                    title={gettext('Edit')}
                    onClick={onClick}
                    style={buttonStyle}
                    size="large"
                >
                    <EditIcon style={iconStyle} />
                </IconButton>
            )}
        </div>
    )
}
