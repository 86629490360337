/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'

import useUrls from '../useUrls'

export default function useCoursesForUserApi(userId: number) {
    const urls = useUrls()
    return useReactQuery<{
        accessibleCourses: any[]
        coursesWithoutLimitedAccess: any[]
        visibleCourses: any[]
    }>(urls['users_and_courses:courses_for_user_api'](userId), ['CoursesForUser', 'list', userId])
}

export function useBasicCourseList(params?, enabled = true) {
    const urls = useUrls()
    return useReactQuery<BasicCourse[]>(urls.basicCourseList(), ['Course', 'list', 'basic', params], {
        queryParams: params,
        reactQueryOptions: { enabled }
    })
}

export function useBasicCourse(courseId: number) {
    const urls = useUrls()
    return useReactQuery<BasicCourse>(urls.basicCourseDetail(courseId), ['Course', 'detail', 'basic', courseId])
}

export function usePublicCourses(params, { enabled = true }) {
    const urls = useUrls()
    return useReactQuery<Course[]>(urls.publicCourses(), ['Course', 'list', 'public', params], {
        queryParams: params,
        reactQueryOptions: { enabled }
    })
}

export function useBookmarkedCourses({ select }: { select?: (data: Course[]) => any } = {}) {
    const urls = useUrls()
    return useReactQuery<Course[]>(urls.bookmarkedCourses(), ['Course', 'list', 'bookmarked'], {
        reactQueryOptions: { select }
    })
}

export function useLastSeenCourses() {
    const urls = useUrls()
    return useReactQuery<Course[]>(urls.lastseenCourses(), ['Course', 'list', 'lastSeen'])
}

export function usePurchasedCourses() {
    const urls = useUrls()
    return useReactQuery<Course[]>(urls.activatedCourses(), ['Course', 'list', 'purchased'])
}

export function useCourse(courseId: number, enabled = true) {
    const urls = useUrls()
    return useReactQuery<Course>(urls.courseDetail(courseId), ['Course', 'detail', courseId], {
        reactQueryOptions: { enabled }
    })
}

export function useCourseDetails(courseId: number) {
    const urls = useUrls()
    return useReactQuery<Course>(urls.courseDetailDetail(courseId), ['CourseDetail', 'detail', courseId])
}

export function useCourseActions(courseId: number, enabled = true) {
    const urls = useUrls()
    return useReactQuery<CourseActions>(urls.courseActions(courseId), ['CourseActions', 'detail', courseId], {
        reactQueryOptions: { enabled }
    })
}
