/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import Highlighter from 'components/highlighter/Highlighter'
import { validateTitle as schema } from 'utils/validation'

const useStyles = makeStyles({
    courseTitle: {
        height: '52px',
        overflow: 'hidden'
    }
})

function wrapTitle(course) {
    const variant = 'subtitle2'
    const highlighter = <Highlighter searchWords={course.highlights} textToHighlight={course.title} />
    if (course.startButton && course.startButton.url) {
        return (
            <Link href={course.startButton.url} color="inherit" variant={variant}>
                {highlighter}
            </Link>
        )
    }
    return <Typography variant={variant}>{highlighter}</Typography>
}

export default function CourseTitle({
    course,
    triggerMutation
}: {
    course: Course
    triggerMutation: (...args: any) => any
}) {
    const classes = useStyles()

    return (
        <EditableText
            className={classes.courseTitle}
            contentItem={course}
            triggerMutation={triggerMutation}
            name="title"
            defaultValue={course.title}
            editOnClick={false}
            validationSchema={schema}
        >
            {wrapTitle(course)}
        </EditableText>
    )
}
