/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import clsx from 'clsx'
import { noop } from 'lodash'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import EditableThumbnail from 'components/editable/EditableThumbnail'
import StartButton from 'components/StartButton'
import { useActiveUser } from 'hooks/data/user'
import useUrls from 'hooks/useUrls'
import useStyles from 'styles/card'
import { preventFollowingLink } from 'utils/misc'
import { validateTitle as schema } from 'utils/validation'

import EditListButton from './EditListButton'
import ListDetailButton from './ListDetailButton'

export default function ListTile({
    list,
    onListRemoved,
    wide = false,
    triggerMutation = noop
}: {
    list: List
    onListRemoved: (...args: any) => any
    wide?: boolean
    triggerMutation?: (...args: any) => any
}) {
    const classes = useStyles()
    const { data: currentUser } = useActiveUser()
    const urls = useUrls()
    const theme = useTheme()

    if (!currentUser) {
        return null
    }

    const openListURL = `${urls.home()}lists/${list.id}/`

    return (
        <div className={clsx('card', wide ? '' : 'narrow', classes.card)} data-testid="listTile">
            <EditableThumbnail contentItem={list} name="thumbnail" triggerMutation={triggerMutation}>
                <a href={openListURL} className="cardImage">
                    <img src={list.thumbnail} loading="lazy" />
                </a>
            </EditableThumbnail>
            <a
                href={openListURL}
                className="cardContent"
                draggable="false" // make text selectable (beneficial when editing)
                onClick={preventFollowingLink}
            >
                <hgroup>
                    <Typography component="h4" className="overtitle">
                        {gettext('List')}
                    </Typography>
                    <EditableText
                        contentItem={list}
                        triggerMutation={triggerMutation}
                        name="title"
                        defaultValue={list.title}
                        editOnClick={false}
                        validationSchema={schema}
                    >
                        <Typography component="h3" title={list.title} className="title" noWrap>
                            {list.title}
                        </Typography>
                    </EditableText>
                </hgroup>
                <Typography title={list.metadata} className="metadata">
                    {list.metadata}
                </Typography>
            </a>
            <div className="cardFooter">
                {!wide && <StartButton config={{ url: openListURL, text: gettext('Open') }} />}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: theme.spacing(1) }}>
                    {currentUser.id === list.user && <EditListButton list={list} onListRemoved={onListRemoved} />}
                    {!wide && <ListDetailButton list={list} triggerMutation={triggerMutation} />}
                </div>
            </div>
        </div>
    )
}
