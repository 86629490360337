/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const SPINNER_SIZE = 80

const useStyles = makeStyles(() => ({
    spinner: {
        color: 'rgba(255,255,255, 0.3)',
        marginInline: 'auto',
        position: 'absolute'
    }
}))

export default function UploadSpinner() {
    const classes = useStyles()
    return <CircularProgress className={classes.spinner} size={SPINNER_SIZE} />
}
