/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'

const urls = window.Urls

export default function useCertificateList(params) {
    return useReactQuery<Certificate[]>(urls['publishing:certificateList'](), ['Certificate', 'list', params], {
        queryParams: params
    })
}
