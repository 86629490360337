/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import BigNumber from 'bignumber.js'
import sha from 'sha.js'

import { Tags } from './constants'
import dataDictionary from './dataDictionary.json'
import { ab2str, getReadableTag } from './utils'
import whiteList from './whitelist.json'

function hashUid(uid) {
    // Same as in DicomDeidentify
    const hexDigest = sha('sha1')
        .update(`MEVIS@${uid.trim().replace('\0', '')}`)
        .digest('hex')
    return `1.3.6.1.4.1.34261.7.${new BigNumber(`0x${hexDigest}`).toFixed()}`.slice(0, uid.length)
}

export function getPrivateTagPatterns(dataSet, group, creator) {
    const patterns = []
    // eslint-disable-next-line no-bitwise
    const startTag = (group << 16) + 0x10
    // eslint-disable-next-line no-bitwise
    const endTag = (group << 16) + 0xff
    for (let tag = startTag; tag <= endTag; ++tag) {
        const value = dataSet.string(`x${tag.toString(16).padStart(8, '0')}`)
        if (value && value.toLowerCase().includes(creator.toLowerCase())) {
            patterns.push(new RegExp(`^x00${tag.toString(16)}$`))
            // eslint-disable-next-line no-bitwise
            patterns.push(new RegExp(`^x00${group.toString(16)}${(tag & 0xff).toString(16)}`))
        }
    }
    return patterns
}

export function getExemptPatterns(dataSet) {
    let exemptPatterns = [/^x60/]
    exemptPatterns = exemptPatterns.concat(getPrivateTagPatterns(dataSet, 0x21, 'SIEMENS'))
    exemptPatterns = exemptPatterns.concat(getPrivateTagPatterns(dataSet, 0x29, 'SIEMENS'))

    return exemptPatterns
}

export function tagShallBeAnonymized(dataSet, element, options) {
    const { tag } = element
    const vr = getVR(element)

    const exemptPatterns = getExemptPatterns(dataSet)

    if (whiteList.includes(tag) || exemptPatterns.some(pattern => pattern.test(tag))) {
        return false
    }

    if (tag === Tags.StudyDate) {
        return options.anonymizeStudyDate
    }

    // Attribute Tag
    if (vr === 'AT') {
        return false
    }

    return true
}

export function getVR(element) {
    if (element.vr) {
        return element.vr
    }
    const readableTag = getReadableTag(element.tag)
    const dataDictionaryEntry = dataDictionary[readableTag]
    if (dataDictionaryEntry) {
        return dataDictionaryEntry.vr
    }
    return undefined
}

export function anonymizeTag(dataSet, element, options?) {
    const { dataOffset, length, tag } = element
    const vr = getVR(element)

    if (!tagShallBeAnonymized(dataSet, element, options)) {
        return undefined
    }
    if (tag === Tags.AccessionNumber || tag === Tags.PatientID) {
        return `${options.caseId}`.padEnd(length, ' ')
    }
    if (tag === Tags.PatientName) {
        return `Pat.${options.caseId}`.padEnd(length, ' ')
    }
    switch (vr) {
        // all string types
        case 'AE': // Application Entity
        case 'CS': // Code String
        case 'DS': // Decimal String
        case 'IS': // Integer String
        case 'LO': // Long String
        case 'LT': // Long Text
        case 'PN': // Person Name
        case 'SH': // Short String
        case 'ST': // Short Text
        case 'UT': // Unlimited text
            return ab2str(dataSet.byteArray.slice(dataOffset, dataOffset + length))
                .split('/')
                .map(e => ' '.padEnd(e.length, ' '))
                .join('/')
        case 'AS': // Age String
            return ab2str(dataSet.byteArray.slice(dataOffset, dataOffset + length))
                .split('/')
                .map(() => '000Y')
                .join('/')
        case 'DA': // Date
            return ab2str(dataSet.byteArray.slice(dataOffset, dataOffset + length))
                .split('/')
                .map(e => `${e.slice(0, 4)}0101`)
                .join('/')
        case 'DT': // Date Time
            return ab2str(dataSet.byteArray.slice(dataOffset, dataOffset + length))
                .split('/')
                .map(e => `${e.slice(0, 4)}0101.000000`.padEnd(e.length, ' '))
                .join('/')
        case 'TM': // Time
            return ab2str(dataSet.byteArray.slice(dataOffset, dataOffset + length))
                .split('/')
                .map(e => '000000'.padEnd(e.length, ' '))
                .join('/')
        case 'FL': // Floating Point Single
        case 'FD': // Floating Point Double
        case 'OB': // Other Byte String
        case 'OD': // Other Double String
        case 'OF': // Other Float String
        case 'OW': // Other Word String
        case 'SL': // Signed Long
        case 'SS': // Signed Short
        case 'UL': // Unsigned Long
        case 'US': // Unsigned Short
        case 'UN': // Unknown
            return dataSet.byteArray
                .slice(dataOffset, dataOffset + length)
                .map(u => (u === '/'.charCodeAt(0) ? u : '\0'))
        case 'UI': {
            return ab2str(dataSet.byteArray.slice(dataOffset, dataOffset + length))
                .split('/')
                .map(hashUid)
                .join('/')
        }
        default:
            return undefined
    }
}

export function processElement(dataSet, element, options?) {
    if (element.fragments) {
        return
    }
    if (element.items) {
        element.items.forEach(elem => anonymizeDataset(elem.dataSet, options))
        return
    }
    const anonymizedValue = anonymizeTag(dataSet, element, options)
    if (anonymizedValue !== undefined) {
        if (typeof anonymizedValue === 'string') {
            for (let i = 0; i < element.length; i++) {
                dataSet.byteArray[element.dataOffset + i] = anonymizedValue.charCodeAt(i)
            }
        } else {
            // Uint8Array
            for (let i = 0; i < element.length; i++) {
                dataSet.byteArray[element.dataOffset + i] = anonymizedValue[i]
            }
        }
    }
}

export default function anonymizeDataset(dataSet, options) {
    Object.values(dataSet.elements).forEach(element => processElement(dataSet, element, options))
}
