/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Typography } from '@mui/material'
import { union } from 'lodash'
import React from 'react'

import EditableMultiSelect from 'components/editable/EditableMultiSelect'
import Highlighter from 'components/highlighter/Highlighter'
import { useDefaultKeywords } from 'hooks/data/misc'

/** Display the keyworkds of content item. */
export default function Keywords({
    contentItem,
    triggerMutation
}: {
    contentItem: Record<string, any>
    triggerMutation: (...args: any) => void
}) {
    const { data: availableKeywords } = useDefaultKeywords()
    const contentItemKeywords = contentItem.keywords || []
    const keywordOptions = union(
        availableKeywords?.map(o => o.name),
        contentItemKeywords
    )
    const highlights = contentItem.highlights || []

    if (!availableKeywords) {
        return null
    }

    return (
        <EditableMultiSelect
            contentItem={contentItem}
            triggerMutation={triggerMutation}
            name="keywords"
            options={keywordOptions}
            defaultSelection={contentItemKeywords}
            freeSolo
            label={gettext('Keywords')}
        >
            <Typography variant="body2">{`${gettext('Keywords')}:\u00A0`}</Typography>

            <Box display="flex" flexDirection="column">
                {contentItemKeywords.length === 0 && <Typography variant="body2">{gettext('No keywords')}</Typography>}
                {contentItemKeywords.map(k => (
                    <Typography variant="body2" key={k}>
                        <Highlighter searchWords={highlights} textToHighlight={k} />
                    </Typography>
                ))}
            </Box>
        </EditableMultiSelect>
    )
}
