/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'

import TextFieldWrapper from 'components/forms/TextFieldWrapper'

export default function CMECreditsInput() {
    return (
        <TextFieldWrapper
            name="cmeCredits"
            label={gettext('CME Credits')}
            type="number"
            variant="outlined"
            inputProps={{ step: 1, min: 0 }}
        />
    )
}
