/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

/* eslint-disable react/prop-types */

import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material'
import type { InputBaseComponentProps } from '@mui/material'
import { ClickAwayListener, IconButton, InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type { UseMutateFunction } from '@tanstack/react-query'
import noop from 'lodash/noop'
import { useSnackbar } from 'notistack'
import React, { useCallback, useRef } from 'react'

import useEditableInput from 'components/editable/useEditableInput'

import EditableComponentContainer from './EditableComponentContainer'
import useApplyStyles from './useApplyStyles'

const useStyles = makeStyles(theme => ({
    buttons: {
        gridGap: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        zIndex: theme.zIndex.drawer + 1
    },
    button: {
        padding: 0,
        margin: '0 !important',
        height: 'auto !important',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}))

interface EditableTextProps {
    contentItem: any
    children: React.ReactNode
    className?: string
    triggerMutation: UseMutateFunction<any, any, { id: number; changes: Record<string, any> }>
    defaultValue: string | number
    name: string
    editOnClick?: boolean
    type?: 'text' | 'number'
    inputProps?: InputBaseComponentProps
    validationSchema?: any
}

/** EditableText is a React component that allows text to be edited. */
export default function EditableText({
    contentItem,
    children,
    className,
    triggerMutation = noop,
    defaultValue,
    name,
    editOnClick = true,
    type = 'text',
    inputProps,
    validationSchema
}: EditableTextProps) {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const { canEdit, isEditing, enterEditingMode, leaveEditingMode, setMouseEntered } = useEditableInput(contentItem)
    const containerRef = useRef<HTMLDivElement>()
    const textFieldRef = useRef(null)
    const { inputStyle, formStyle, iconStyle, buttonStyle, applyStylesOnClick, copyStylesOnMouseEnter } =
        useApplyStyles(containerRef)

    const onClick = useCallback(
        event => {
            applyStylesOnClick(event)
            enterEditingMode(event)
        },
        [applyStylesOnClick, enterEditingMode]
    )

    const onMouseEnter = useCallback(() => {
        copyStylesOnMouseEnter()
        setMouseEntered(true)
    }, [copyStylesOnMouseEnter, setMouseEntered])

    const onSubmit = useCallback(
        async event => {
            event.preventDefault()
            event.stopPropagation()

            if (validationSchema) {
                const validationResult = await validationSchema
                    .validate({ [name]: textFieldRef.current.value })
                    .catch(err => {
                        enqueueSnackbar(err.errors, { variant: 'error' })
                    })
                if (!validationResult) {
                    return
                }
            }

            triggerMutation(
                { id: contentItem.id, changes: { [name]: textFieldRef.current.value } },
                {
                    onSuccess: leaveEditingMode
                }
            )
        },
        [contentItem.id, leaveEditingMode, name, triggerMutation, enqueueSnackbar, validationSchema]
    )

    const onKeyDown = useCallback(
        event => {
            event.stopPropagation() // e.g. to prevent closing modal dialog, start/stop video, ...
            if (event.key === 'Escape') {
                leaveEditingMode()
            }
            if (event.key === 'Enter') {
                onSubmit(event)
            }
        },
        [leaveEditingMode, onSubmit]
    )

    return isEditing ? (
        <ClickAwayListener onClickAway={onSubmit}>
            <div
                className={className}
                style={formStyle}
                onClick={event => {
                    event.stopPropagation()
                }}
            >
                <TextField
                    inputRef={textFieldRef}
                    type={type}
                    autoFocus
                    size="small"
                    name={name}
                    defaultValue={defaultValue}
                    variant="standard"
                    fullWidth
                    autoComplete="off"
                    onKeyDown={onKeyDown}
                    onClick={event => {
                        if (event.target instanceof HTMLInputElement && event.target.type === 'text') {
                            event.preventDefault() // do not follow link when clicking into the text while editing
                        }
                    }}
                    InputProps={{
                        style: inputStyle,
                        endAdornment: (
                            <InputAdornment className={classes.buttons} position="end">
                                <IconButton
                                    className={classes.button}
                                    type="button"
                                    onClick={onSubmit}
                                    title={gettext('Save changes')}
                                    size="large"
                                >
                                    <CheckIcon style={iconStyle} />
                                </IconButton>
                                <IconButton
                                    className={classes.button}
                                    onClick={event => {
                                        event.preventDefault()
                                        leaveEditingMode()
                                    }}
                                    title={gettext('Discard changes')}
                                    size="large"
                                >
                                    <ClearIcon style={iconStyle} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }} // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ style: { padding: 0 }, ...inputProps }}
                    required
                />
            </div>
        </ClickAwayListener>
    ) : (
        <EditableComponentContainer
            buttonStyle={buttonStyle}
            canEdit={canEdit}
            containerRef={containerRef}
            iconStyle={iconStyle}
            onClick={onClick}
            editOnClick={editOnClick}
            onMouseEnter={onMouseEnter}
            className={className}
        >
            {children}
        </EditableComponentContainer>
    )
}
