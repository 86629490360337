/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Mail as MailIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'

import useStyles from 'components/buttonStyles'

import CourseInvitationModal from './CourseInvitationModal'

export default function CourseInvitation({ course }: { course: Course }) {
    const [showModal, setShowModal] = useState(false)
    const classes = useStyles()

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <React.Fragment>
            <Button
                className={clsx([classes.button, classes.bordered])}
                data-testid="sendCourseInvitation"
                title={gettext('Invite Authors')}
                onClick={openModal}
            >
                <MailIcon />
            </Button>
            {showModal && <CourseInvitationModal course={course} onHide={closeModal} />}
        </React.Fragment>
    )
}
