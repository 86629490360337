/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import Highlighter from 'components/highlighter/Highlighter'

export default function AdditionalFilters({ course, highlights }) {
    if (course.additionalFilter) {
        return (
            <Typography variant="body2">
                <Highlighter
                    searchWords={highlights}
                    textToHighlight={`${gettext('Additional Filters')}: ${course.additionalFilters.join(', ')}`}
                />
            </Typography>
        )
    }
    return null
}
