/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Edit as EditIcon, MoreVert as MoreVertIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import clsx from 'clsx'
import noop from 'lodash/noop'
import React, { useState } from 'react'

import useButtonStyles from 'components/buttonStyles'
import CourseActionsMenu, { resetToDraft } from 'components/course_actions/CourseActionsMenu'

import ConfirmModal from '../modals/ConfirmModal'

export default function CourseActionsButton({
    course,
    openCourseDetailsModal = noop,
    extraActions = false,
    onCourseStatusChanged = noop
}: {
    openCourseDetailsModal?: (...args: any) => any
    extraActions?: boolean
    course: Course
    onCourseStatusChanged?: (...args: any) => any
}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [resetUrl, setResetUrl] = useState('')
    const classes = useButtonStyles()

    const onResetToDraft = () => {
        resetToDraft(course, resetUrl, onCourseStatusChanged)
        setResetUrl('')
    }

    return (
        <React.Fragment>
            {course && (
                <CourseActionsMenu
                    course={course}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    openCourseDetailsModal={openCourseDetailsModal}
                    extraActions={extraActions}
                    onResetToDraft={url => setResetUrl(url)}
                />
            )}
            <Button
                variant="contained"
                className={clsx([classes.button, classes.bordered])}
                data-testid="editCourse"
                title={gettext('Edit Course')}
                onClick={event => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                {!extraActions && <EditIcon />}
                {extraActions && <MoreVertIcon />}
            </Button>
            {resetUrl && (
                <ConfirmModal
                    contentText={gettext('Do you really want to reset this course to draft state?')}
                    open
                    submitText={gettext('YES')}
                    onReject={() => setResetUrl('')}
                    onSubmit={onResetToDraft}
                    object={course}
                />
            )}
        </React.Fragment>
    )
}
