/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import isEqual from 'lodash/isEqual'

export function modulesAreEqual(l, r) {
    return l.id === r.id && l.model === r.model
}

export function newestFirst(l, r) {
    return r.id - l.id
}

export const idSelectorOptions = { memoizeOptions: { resultEqualityCheck: isEqual } }

export const filterUnassignedContent = contentList =>
    contentList.filter(content => content.status === 'draft' && content.reviews.length === 0)

export const filterAuthoredContent = (contentList, authorId) =>
    contentList.filter(content => content.author && content.author.id === authorId)

export const filterReviewableContent = (contentList, reviewer) =>
    contentList.filter(
        content => content.status === 'draft' && content.reviews.length > 0 && content.reviews[0].user === reviewer
    )

export const filterNewContent = contentList => contentList.filter(content => content.status === 'new')
export const filterApprovedContent = contentList => contentList.filter(content => content.status === 'approved')

export const getAuthorDisplayName = (author, currentUserId) => {
    if (!author) {
        return ''
    }
    if (currentUserId === author.id) {
        return `(${gettext('Me')})`
    }
    return `(${author.last_name}, ${author.first_name})`
}
