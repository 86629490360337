/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import CloseIcon from '@mui/icons-material/Close'
import { Typography, Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Cookies from 'js-cookie'
import React, { useState } from 'react'

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#d9edf7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 14,
        paddingBottom: 14,
        lineHeight: 'normal',
        paddingLeft: 14,
        paddingRight: 12,
        borderRadius: 4
    },
    text: {
        fontSize: '0.92rem',
        color: '#3a87ad'
    },
    button: {
        color: '#3a87ad'
    },
    closeIcon: {
        fontSize: 15
    }
}))

export type AnnouncementType = {
    message: string
    type: string
    cookie: string
}

export default function Announcement({ announcement }: { announcement: AnnouncementType }) {
    const classes = useStyles()
    const [open, setOpen] = useState(true)

    const closeAnnouncement = () => {
        Cookies.set(announcement.cookie, true, { expires: 0.5, path: window.COOKIE_PATH })
        setOpen(false)
    }

    if (!open) {
        return null
    }
    return (
        <Box className={classes.root}>
            <Typography dangerouslySetInnerHTML={{ __html: announcement.message }} className={classes.text} />
            <IconButton size="small" className={classes.button} onClick={closeAnnouncement}>
                <CloseIcon className={classes.closeIcon} />
            </IconButton>
        </Box>
    )
}
