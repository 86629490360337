/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { Box, Button, Checkbox, Divider, FormControlLabel, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import './SearchForm.css'

const useStyles = makeStyles(() => ({
    formControl: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        '& [class*="MuiFormControlLabel-label"]': {
            fontWeight: 400
        }
    }
}))

export default function FilterListContent({
    allFilters,
    selectedFilters,
    setSelectedFilters,
    category,
    setTabsVisible
}: {
    allFilters: SearchFilters
    selectedFilters: SearchFilters
    setSelectedFilters: (SearchFilters) => void
    category: string
    setTabsVisible: (boolean) => void
}) {
    const classes = useStyles()
    const theme = useTheme()
    const isOneColumn = useMediaQuery(theme.breakpoints.down('md'))
    const isTwoColumns = useMediaQuery(theme.breakpoints.down('lg'))
    let columnCount = 3
    if (isTwoColumns) {
        columnCount = 2
    }
    if (isOneColumn) {
        columnCount = 1
    }

    function isFilterSelected(category, title) {
        return Boolean(selectedFilters[category]?.includes(title))
    }

    const handleFilterChanged = (category, title) => {
        if (isFilterSelected(category, title)) {
            setSelectedFilters(prevFilters => ({
                ...prevFilters,
                [category]: prevFilters[category].filter(t => t !== title)
            }))
        } else {
            setSelectedFilters(prevFilters => ({
                ...prevFilters,
                [category]: [...(prevFilters[category] || []), title]
            }))
        }
    }

    return (
        <React.Fragment>
            <Divider style={{ marginBottom: 0, marginTop: 0 }} />
            {allFilters[category] && (
                <div
                    style={{
                        columnCount,
                        columnGap: theme.spacing(2),
                        padding: theme.spacing(2)
                    }}
                >
                    {allFilters[category].map(filter => {
                        let key
                        let value
                        let label
                        if (typeof filter === 'object') {
                            ;({ key, value, label } = filter)
                        } else {
                            key = category
                            value = filter
                            label = filter
                        }
                        return (
                            <FormControlLabel
                                className={classes.formControl}
                                key={label}
                                control={
                                    <Checkbox
                                        checked={isFilterSelected(key, value)}
                                        onChange={() => handleFilterChanged(key, value)}
                                    />
                                }
                                label={label}
                                title={label}
                            />
                        )
                    })}
                </div>
            )}
            <Divider style={{ marginBottom: 0, marginTop: 0 }} />
            <Box px={2} py={2} display="flex" flexDirection="row" gap={theme.spacing(2)}>
                <Button
                    type="submit"
                    variant="contained"
                    endIcon={<ArrowForwardIcon style={{ marginBottom: 2 }} />}
                    onClick={event => {
                        setTabsVisible(false)
                        const form = (event.target as HTMLButtonElement).closest('form')
                        // Older browsers do not implement requestSubmit.
                        // See https://caniuse.com/?search=requestSubmit
                        if (form.requestSubmit) {
                            form.requestSubmit()
                        } else {
                            form.submit()
                        }
                    }}
                >
                    {gettext('Search')}
                </Button>
                <Button type="button" variant="outlined" onClick={() => setTabsVisible(false)}>
                    {gettext('Hide Filters')}
                </Button>
            </Box>
        </React.Fragment>
    )
}
