/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

const typography = {
    h1: {
        fontSize: '24px',
        // fontWeight: 400,
        fontWeight: 500,
        lineHeight: 1.33,
        padding: 0,
        '@media (min-width:768px)': {
            fontSize: '30px'
        }
    },
    h2: {
        fontSize: '22px',
        lineHeight: 1.33,
        fontWeight: 400,
        padding: 0,
        '@media (min-width:768px)': {
            fontSize: '24px'
        }
    },
    h3: {
        fontSize: '20px',
        lineHeight: 1.33,
        fontWeight: 400,
        padding: 0,
        '@media (min-width:768px)': {
            fontSize: '22px'
        }
    },
    h4: {
        fontSize: '18px',
        lineHeight: 1.33,
        fontWeight: 400,
        padding: 0
    },
    h5: {
        fontSize: '16px',
        lineHeight: 1.33,
        fontWeight: 400,
        padding: 0
    },
    h6: {
        fontSize: '16px',
        lineHeight: 1.33,
        fontWeight: 400,
        padding: 0
    },
    // Paragraph
    body1: {
        fontSize: '14px',
        lineHeight: 1.4,
        fontWeight: 300,
        padding: 0,
        '@media (min-width:768px)': {
            fontSize: '16px'
        }
    },
    body2: {
        fontSize: '12px',
        lineHeight: 1.4,
        fontWeight: 300,
        padding: 0,
        '@media (min-width:768px)': {
            fontSize: '14px'
        }
    },
    subtitle1: {
        fontSize: '22px',
        lineHeight: '32px',
        fontWeight: 500,
        padding: 0,
        '@media (min-width:768px)': {
            fontSize: '26px',
            lineHeight: '36px'
        }
    },
    subtitle2: {
        padding: 0,
        fontSize: '16px',
        lineHeight: '26px',
        fontWeight: 500
    },
    overline: {
        padding: 0,
        opacity: 0.66,
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: 1.4,
        textTransform: 'uppercase',
        letterSpacing: 'normal',
        '@media (min-width:768px)': {
            fontSize: '16px'
        }
    },
    caption: {
        fontSize: '12px',
        lineHeight: 1.4,
        fontWeight: 300,
        padding: 0
    },
    button: {
        fontSize: '16px',
        lineHeight: 1.4,
        fontWeight: 400,
        padding: 0
    }
} as const

export default typography
