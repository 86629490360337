/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import MaterialRoot from 'components/materialTheme/MaterialRoot'

import Footer from './Footer'

async function main() {
    function getBottomRightLogoURL() {
        const propElement = document.getElementById('bottomRightLogoURL')
        if (!propElement) {
            return null
        }
        return JSON.parse(propElement.innerText)
    }

    const isInFrame = window.parent !== window
    if (isInFrame) {
        return
    }

    const { dataPrivacyLinks, settingsMenuItems, softwareRevision, logoUrl, oemUrl, oemName, shortOemName } =
        await fetch(window.Urls.footerContext()).then(response => response.json())
    const root = createRoot(document.getElementById('footerRoot'))
    root.render(
        <MaterialRoot seed="footer">
            <Footer
                dataPrivacyLinks={dataPrivacyLinks}
                settingsMenuItems={settingsMenuItems}
                softwareRevision={softwareRevision}
                logoUrl={logoUrl}
                oemUrl={oemUrl}
                oemName={oemName}
                shortOemName={shortOemName}
                bottomRightLogoURL={getBottomRightLogoURL()}
            />
        </MaterialRoot>
    )
}

main()
