/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'

import { useModuleActions } from 'hooks/data/coursemodules'
import { useBasicCourseList } from 'hooks/data/courses'
import type { CourseModule } from 'hooks/data/modules'
import { useActiveUser } from 'hooks/data/user'

export default function CloneModuleModal({ module, onClose }: { module: Partial<CourseModule>; onClose: () => void }) {
    const { data: actions } = useModuleActions(module)
    const { data: user } = useActiveUser()
    const { data: targetCourses } = useBasicCourseList({ authorships__user: user?.id }, Boolean(user))
    const [targetCourseId, setTargetCourseId] = useState<number>(null)
    const { mutate: cloneModule } = useMutation({
        mutationFn: () => axios.post(actions.clone, { targetCourseId }).then(res => res.data),
        onSuccess: (module: CourseModule) => {
            window.open(module.actions.edit, '_blank')
        },
        onSettled: onClose
    })

    if (!actions || !user || !targetCourses) {
        return null
    }

    return (
        <Dialog open fullWidth onClose={onClose}>
            <DialogTitle variant="h2">{interpolate(gettext('Clone module %s'), [module.title])}</DialogTitle>

            <DialogContent dividers>
                {targetCourses.length > 0 ? (
                    <Select
                        fullWidth
                        label={gettext('Select target course')}
                        onChange={event => setTargetCourseId(event.target.value as number)}
                        value={targetCourseId}
                        displayEmpty
                    >
                        <MenuItem value={null}>{gettext('None')}</MenuItem>
                        {targetCourses.map(c => (
                            <MenuItem key={c.id} value={c.id}>
                                {c.title}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    gettext('No target courses available')
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={onClose}>
                    {gettext('Cancel')}
                </Button>
                <Button disabled={!targetCourseId} variant="contained" onClick={() => cloneModule()}>
                    {gettext('Clone')}
                </Button>
            </DialogActions>
        </Dialog>
    )

    return null
}
