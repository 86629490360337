/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, DialogContentText } from '@mui/material'
import axios from 'axios'
import { makeValidate } from 'mui-rff'
import { useSnackbar } from 'notistack'
import React from 'react'
import * as Yup from 'yup'

import TextFieldWrapper from 'components/forms/TextFieldWrapper'
import FormModal from 'components/modals/FormModal'
import utils from 'components/react_components/utils'
import useReactQuery from 'hooks/react_query'
import useCheckEmail from 'hooks/useCheckEmail'
import useUrls from 'hooks/useUrls'
import parseCommaSeparatedEmails from 'utils/parseEmailList'

export default function ShareCourseModal({ courseId, onHide }: { courseId: number; onHide: (...args: any) => any }) {
    const urls = useUrls()
    const { enqueueSnackbar } = useSnackbar()
    const { data: template, isLoading } = useReactQuery<any>(urls['publishing:shareCourseTemplate'](courseId), [
        'ShareCourseTemplate',
        'detail',
        courseId
    ])
    const { data: existingShares } = useReactQuery<any[]>(urls.courseGetCourseShares(courseId), [
        'CourseShare',
        'list',
        courseId
    ])
    useCheckEmail()

    if (!existingShares) {
        return null
    }

    const schema = Yup.object().shape({
        recipients: Yup.string().required(gettext('Required')).checkEmail('Check Email'),
        subject: Yup.string().required(gettext('Required')),
        content: Yup.string().required(gettext('Required'))
    })
    const validate = makeValidate(schema)

    const onSubmit = async values => {
        try {
            const recipients = parseCommaSeparatedEmails(values.recipients)
            await axios.post(urls.courseShareCourse(courseId), {
                recipients,
                subject: values.subject,
                content: values.content
            })
            enqueueSnackbar(gettext('Course shared successfully'), { variant: 'success' })
            onHide()
        } catch (error) {
            if (error.response?.status === 400 && error.response.data?.recipients) {
                return {
                    recipients: (
                        <React.Fragment>
                            {error.response.data.recipients.map(errorMessage => (
                                // Show each error on a separate line
                                // This renders inside a p, so we cannot use divs here
                                <span style={{ display: 'block' }} key={errorMessage}>
                                    {errorMessage}
                                </span>
                            ))}
                        </React.Fragment>
                    )
                }
            }
            utils.defaultAjaxErrorHandler(error)(gettext('Sorry! An error occurred while sharing the course.'))
        }
        return {}
    }

    return (
        <FormModal
            title={gettext('Share Course')}
            subtitle={
                <React.Fragment>
                    <DialogContentText>{gettext('Share the course with other users')}</DialogContentText>
                    <DialogContentText>
                        {interpolate(gettext('Already shared with: %s'), [existingShares.join(', ')])}
                    </DialogContentText>
                </React.Fragment>
            }
            submitText={gettext('Share Course')}
            open
            onReject={onHide}
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
                subject: template?.subject || '',
                content: template?.body || ''
            }}
        >
            {/* Hack: Label of input is cut off */}
            <Box mt={0.5} />
            <TextFieldWrapper
                name="recipients"
                label={gettext('Email address required. Separate multiple addresses with ,')}
                variant="outlined"
            />
            <TextFieldWrapper
                name="subject"
                label={gettext('Subject')}
                variant="outlined"
                type="text"
                disabled={isLoading}
            />
            <TextFieldWrapper
                name="content"
                label={gettext('Content')}
                multiline
                rows={10}
                variant="outlined"
                disabled={isLoading}
            />
        </FormModal>
    )
}
