/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Button, Divider, Typography, List, ListItem } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import React, { Suspense, useState } from 'react'

import {
    useDefaultKeywords,
    useContentTypes,
    useAdditionalFilters,
    useTargetAudiences,
    useSubscriptionFilters
} from 'hooks/data/misc'
import useUrls from 'hooks/useUrls'

import { ClosableAlert } from '../utils/alerts'

import {
    CoursesInput,
    AdditionalFiltersInput,
    KeywordsInput,
    PublishingYearsInput,
    TargetAudiencesInput,
    TitleInput,
    TypesInput
} from './CarouselFormFields'
import ConfigurableCourseCarousel, { getQueryParams } from './ConfigurableCourseCarousel'

function getHelperTextFragment(name, value) {
    if (Array.isArray(value) && value.length > 0) {
        return interpolate(gettext('with %(name)s (%(value)s)'), { name, value: value.join(gettext(' OR ')) }, true)
    }
    return ''
}

function getHelperText(values) {
    const { courses, keywords, types, additionalFilters, targetAudiences, publishingYears } = values
    if (courses) {
        return gettext('Showing courses with the selected IDs')
    }

    if (
        [keywords, types, additionalFilters, targetAudiences, publishingYears].some(
            x => Array.isArray(x) && x.length > 0
        )
    ) {
        const keywordsText = getHelperTextFragment(gettext('keywords'), keywords)
        const typesText = getHelperTextFragment(gettext('types'), types)
        const additionalFiltersText = getHelperTextFragment(gettext('Additional Filters'), additionalFilters)
        const targetAudiencesText = getHelperTextFragment(gettext('target audiences'), targetAudiences)
        const publishingYearsText = getHelperTextFragment(gettext('publishing years'), publishingYears)
        return [keywordsText, typesText, additionalFiltersText, targetAudiencesText, publishingYearsText]
            .filter(x => Boolean(x))
            .join(gettext(' AND '))
    }

    return 'empty'
}

export function convertCoursesStringToIdArray(str) {
    try {
        const arr = str.split(',')
        if (!Array.isArray(arr) || !arr.every(x => Boolean(x.trim().match(/^[1-9]\d*$/)))) {
            return undefined
        }
        return arr.map(x => parseInt(x, 10))
    } catch {
        return undefined
    }
}

export default function CarouselConfigForm({
    formTitle,
    values,
    setters,
    handleSave,
    handleSaveAndClose
}: {
    formTitle: string
    values: {
        courses?: string
        title?: string
        keywords?: string[]
        types?: string[]
        additionalFilters?: string[]
        targetAudiences?: string[]
        publishingYears?: string[]
    }
    setters: {
        setCourses: (...args: any) => any
        setTitle: (...args: any) => any
        setKeywords: (...args: any) => any
        setTypes: (...args: any) => any
        setAdditionalFilters: (...args: any) => any
        setTargetAudiences: (...args: any) => any
        setPublishingYears: (...args: any) => any
    }
    handleSave: (...args: any) => any
    handleSaveAndClose: (...args: any) => any
}) {
    const { data: availableKeywords } = useDefaultKeywords()
    const { data: availableTypes } = useContentTypes()
    const { data: availableAdditionalFilters } = useAdditionalFilters()
    const { data: subscriptionFilters } = useSubscriptionFilters()
    const { data: availableTargetAudiences } = useTargetAudiences()
    const urls = useUrls()
    const [error, setError] = useState(false)
    const theme = useTheme()

    if (!availableKeywords || !availableTypes || !availableAdditionalFilters || !availableTargetAudiences) {
        return null
    }

    const { courses, title, keywords, types, additionalFilters, targetAudiences, publishingYears } = values
    const {
        setCourses,
        setTitle,
        setKeywords,
        setTypes,
        setAdditionalFilters,
        setTargetAudiences,
        setPublishingYears
    } = setters

    const handleChangeTitle = event => {
        setTitle(event.target.value)
    }
    const handleChangeKeywords = (_, newValue) => {
        setKeywords(newValue)
    }
    const handleChangeTypes = (_, newValue) => {
        setTypes(newValue)
    }
    const handleChangeAdditionalFilters = (_, newValue) => {
        setAdditionalFilters(newValue)
    }
    const handleChangeTargetAudiences = (_, newValue) => {
        setTargetAudiences(newValue)
    }
    const handleChangePublishingYears = (_, newValue) => {
        setPublishingYears(newValue)
    }
    const handleChangeCourses = event => {
        setError(!!event.target.value && !convertCoursesStringToIdArray(event.target.value))
        setCourses(event.target.value)
    }
    const handleCustomizeCoursesClick = async () => {
        const courses = (
            await axios.get(urls.publicCourses(), {
                data: getQueryParams(
                    { keywords, types, additionalFilters, targetAudiences, publishingYears },
                    subscriptionFilters?.[0]
                )
            })
        ).data
        setCourses(courses.map(c => c.id).join(','))
        setError(false)
    }

    const coursesSelected = Boolean(courses)
    const helperText = getHelperText(values)

    return (
        <React.Fragment>
            <Box mb={3}>
                <Typography variant="h1">{formTitle}</Typography>
            </Box>
            <Box mb={3} width="100%">
                <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
                    <TitleInput value={title} handleChange={handleChangeTitle} />
                    <Typography variant="h2">{gettext('Filter courses')}</Typography>
                    <KeywordsInput
                        availableKeywords={availableKeywords.map(k => k.name)}
                        value={keywords}
                        handleChange={handleChangeKeywords}
                        disabled={coursesSelected}
                    />
                    <TypesInput
                        availableTypes={availableTypes.map(t => t.name)}
                        value={types}
                        handleChange={handleChangeTypes}
                        disabled={coursesSelected}
                    />
                    <AdditionalFiltersInput
                        availableAdditionalFilters={availableAdditionalFilters.map(e => e.name)}
                        value={additionalFilters}
                        handleChange={handleChangeAdditionalFilters}
                        disabled={coursesSelected}
                    />
                    <TargetAudiencesInput
                        availableTargetAudiences={availableTargetAudiences.map(t => t.name)}
                        value={targetAudiences}
                        handleChange={handleChangeTargetAudiences}
                        disabled={coursesSelected}
                    />
                    <PublishingYearsInput
                        value={publishingYears}
                        handleChange={handleChangePublishingYears}
                        disabled={coursesSelected}
                    />
                    <Button onClick={handleCustomizeCoursesClick} disabled={coursesSelected}>
                        {gettext('Customize Courses')}
                    </Button>
                    <Typography variant="h2">{gettext('OR Select individual courses')}</Typography>
                    <CoursesInput
                        value={courses}
                        handleChange={handleChangeCourses}
                        clearCourses={() => setCourses('')}
                        error={error}
                    />
                </Box>
            </Box>
            <Divider />
            <Box mt={2} mb={2} display="flex" flexDirection="column">
                <Typography variant="h3">{gettext('Preview')}</Typography>
                <ClosableAlert severity="info">
                    <Typography variant="body2">
                        {gettext('Only courses meeting the following criteria are displayed:')}
                    </Typography>
                    <List dense style={{ listStyle: 'disc' }}>
                        <ListItem style={{ display: 'list-item' }}>{gettext('published')}</ListItem>
                        <ListItem style={{ display: 'list-item' }}>{gettext('not expired')}</ListItem>
                        <ListItem style={{ display: 'list-item' }}>{gettext('not an examination course')}</ListItem>
                        <ListItem style={{ display: 'list-item' }}>
                            {gettext('visible for all OR in any subscription OR in any promocode')}
                        </ListItem>
                    </List>
                </ClosableAlert>
            </Box>
            <Typography variant="body2" style={{ visibility: helperText === 'empty' ? 'hidden' : 'visible' }}>
                {helperText}
            </Typography>
            <Suspense fallback={null}>
                <ConfigurableCourseCarousel
                    carouselConfig={{
                        definition: isEmpty(courses)
                            ? {
                                  keywords,
                                  types,
                                  additionalFilters,
                                  targetAudiences,
                                  publishingYears
                              }
                            : { courses: error ? null : courses.split(',').map(x => parseInt(x, 10)) }
                    }}
                    preview
                />
            </Suspense>
            <Divider />
            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button type="button" disabled={error} onClick={handleSave} variant="outlined">
                    {gettext('Save')}
                </Button>
                <Box mr={1} />
                <Button
                    type="button"
                    disabled={error}
                    onClick={() => {
                        handleSaveAndClose()
                    }}
                    variant="contained"
                >
                    {gettext('Save and Close')}
                </Button>
            </Box>
        </React.Fragment>
    )
}
