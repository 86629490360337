/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Rating
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import get from 'lodash/get'
import noop from 'lodash/noop'
import React, { useEffect, useState } from 'react'

import utils from 'components/react_components/utils'
import type { CourseModule } from 'hooks/data/modules'
import { getFieldName, useRating } from 'hooks/data/ratings'
import useUrls from 'hooks/useUrls'

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        padding: `0 ${theme.spacing(3)}`
    },
    content: {
        textAlign: 'center'
    },
    rating: {
        padding: theme.spacing(1)
    }
}))

const EMPTY_RATING = {
    id: null,
    course: null,
    textCourseModule: null,
    lecture: null,
    pdfCourseModule: null,
    questionnaireCourseModule: null,
    surveyCourseModule: null,
    viewerCourseModule: null,
    videoCourseModule: null,
    rating: 0
}

export default function ContentRatingDialog({
    content,
    onCloseModal,
    onContentRated = noop
}: {
    content: CourseModule
    onCloseModal: (...args: any) => any
    onContentRated?: (...args: any) => any
}) {
    const [ownRating, setOwnRating] = useState({
        ...EMPTY_RATING,
        [getFieldName(content.model)]: content.id
    })
    const { data: storedUserRatings, isLoading } = useRating(content)
    const urls = useUrls()
    const classes = useStyles()

    useEffect(() => {
        const storedUserRating = get(storedUserRatings, '[0]')
        if (storedUserRating) {
            setOwnRating(storedUserRating)
        }
    }, [storedUserRatings])

    if (isLoading) {
        return null
    }

    async function rateContent(ratingValue) {
        // The onChange handler is invoked if the active value is selected, but ratingValue is null in that case
        if (typeof ratingValue !== 'number') {
            return
        }
        try {
            // Optimistic update
            setOwnRating({ ...ownRating, rating: ratingValue })
            if (ownRating.id) {
                const response = await axios.put(urls['rating:ratingDetail'](ownRating.id), {
                    ...ownRating,
                    rating: ratingValue
                })
                setOwnRating(response.data)
            } else {
                const response = await axios.post(urls['rating:ratingList'](), { ...ownRating, rating: ratingValue })
                setOwnRating(response.data)
            }
            const newContentResponse = await axios.get(content.apiURL)
            onContentRated(newContentResponse.data)
        } catch (error) {
            utils.defaultAjaxErrorHandler(error)()
        } finally {
            onCloseModal()
        }
    }

    return (
        <Box display="inline-flex" position="relative" alignItems="center" title={gettext('Click to rate')}>
            <Dialog open onClose={onCloseModal} maxWidth="sm" fullWidth data-testid="ratingModal">
                <DialogTitle className={classes.dialogTitle}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        {gettext('Your Rating')}
                        <IconButton onClick={onCloseModal} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent className={classes.content} dividers>
                    <Typography variant="body1">{content.title}</Typography>
                    <Typography className={classes.rating} variant="body2">
                        {gettext('Click a star to submit your rating.')}
                    </Typography>
                    <Rating
                        className={classes.rating}
                        value={ownRating.rating}
                        onChange={(event, value) => rateContent(value)}
                        name="rating"
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onCloseModal} color="primary">
                        {gettext('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
