/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Edit as EditIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import noop from 'lodash/noop'
import React, { useCallback } from 'react'

import useEditableInput from 'components/editable/useEditableInput'

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        '&:hover button': {
            display: 'block',
            height: 'auto',
            backgroundColor: theme.palette.button.background
        }
    },
    editFileButton: {
        display: canEdit => (canEdit ? 'none' : 'none !important'),
        aspectRatio: '1',
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        backgroundColor: theme.palette.button.background
    }
}))

interface EditableThumbnailProps {
    contentItem: Record<string, any>
    children: React.ReactNode
    triggerMutation: (opts: { id: number; changes: Record<string, any> }) => void
    name: string
    className?: string
}

/** EditableThumbnail allows to edit a thumbnail */
export default function EditableThumbnail({
    contentItem,
    children,
    triggerMutation = noop,
    name,
    className
}: EditableThumbnailProps) {
    const { setMouseEntered, canEdit } = useEditableInput(contentItem)
    const classes = useStyles(canEdit && contentItem.model !== 'Lecture')

    const onChange = useCallback(
        event => {
            event.preventDefault()
            event.stopPropagation()
            triggerMutation({ id: contentItem.id, changes: { [name]: event.target.files[0] } })
        },
        [contentItem.id, name, triggerMutation]
    )

    return (
        <div onMouseEnter={() => setMouseEntered(true)} className={clsx(classes.container, className)}>
            {children}
            <IconButton
                size="small"
                className={classes.editFileButton}
                onClick={event => {
                    event.stopPropagation()
                    document.getElementById(`getFile${contentItem.model}${contentItem.id}`).click()
                }}
            >
                <EditIcon />
            </IconButton>
            <input
                id={`getFile${contentItem.model}${contentItem.id}`}
                type="file"
                onInput={onChange}
                onClick={event => event.stopPropagation()}
                style={{ display: 'none' }}
                accept="image/*"
            />
        </div>
    )
}
