/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { Button, Menu, MenuItem, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import clsx from 'clsx'
import noop from 'lodash/noop'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import useButtonStyles from 'components/buttonStyles'
import useUrls from 'hooks/useUrls'
import ListInvitationModal from 'pages/edit_list/ListInvitationModal'

import ConfirmModal from '../modals/ConfirmModal'

const useStyles = makeStyles(theme => ({
    menu: {
        '& li': {
            color: theme.palette.text.primary
        }
    }
}))

export default function EditListButton({
    list,
    onListRemoved = noop
}: {
    list: List
    onListRemoved?: (list: List) => void
}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [isRemoving, setIsRemoving] = useState(false)
    const [isSharing, setIsSharing] = useState(false)
    const open = Boolean(anchorEl)
    const buttonClasses = useButtonStyles()
    const classes = useStyles()
    const urls = useUrls()
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()

    const { mutate: removeList } = useMutation({
        mutationFn: () => axios.delete(urls['lists:listDetail'](list.id)),
        onSuccess: () => {
            onListRemoved(list)
            enqueueSnackbar(interpolate(gettext('List "%s" has been deleted'), [list.title]), { variant: 'success' })
            setIsRemoving(false)
        }
    })

    const { mutate: toggleIsPublic } = useMutation({
        mutationFn: () => axios.patch(urls['lists:listDetail'](list.id), { isPublic: !list.isPublic }),
        onSuccess: () => {
            const message = list.isPublic
                ? interpolate(gettext('List %s has been unpublished'), [list.title])
                : interpolate(gettext('List %s has been published'), [list.title])
            enqueueSnackbar(message, { variant: 'success' })
            queryClient.invalidateQueries(['List'])
        }
    })

    return (
        <React.Fragment>
            {open && (
                <Menu
                    className={classes.menu}
                    onClick={() => setAnchorEl(null)}
                    onClose={() => setAnchorEl(null)}
                    open={open}
                    anchorEl={anchorEl}
                >
                    <Link data-testid="editListLink" href={urls['lists:editList'](list.id)}>
                        <MenuItem>{gettext('Edit list')}</MenuItem>
                    </Link>

                    <MenuItem onClick={() => toggleIsPublic()}>
                        {list.isPublic ? gettext('Unpublish List') : gettext('Publish List')}
                    </MenuItem>

                    <MenuItem onClick={() => setIsSharing(true)}>{gettext('Share List')}</MenuItem>

                    <MenuItem onClick={() => setIsRemoving(true)}>{gettext('Remove List')}</MenuItem>
                </Menu>
            )}

            <Button
                variant="contained"
                className={clsx([buttonClasses.button, buttonClasses.bordered])}
                title={gettext('Edit List')}
                onClick={event => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                <MoreVertIcon />
            </Button>

            {isRemoving && (
                <ConfirmModal
                    contentText={interpolate(gettext('Do you really want to delete list "%s"?'), [list.title])}
                    open
                    submitText={gettext('YES')}
                    onReject={() => setIsRemoving(false)}
                    onSubmit={removeList}
                    object={list}
                />
            )}
            {isSharing && <ListInvitationModal listId={list.id} onHide={() => setIsSharing(false)} />}
        </React.Fragment>
    )
}
