/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import jssPreset from '@mui/styles/jssPreset'
import StylesProvider from '@mui/styles/StylesProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { Jss } from 'jss'
import { create } from 'jss'
import { SnackbarProvider } from 'notistack'
import React, { useState, useEffect, useRef } from 'react'

import utils from 'components/react_components/utils'

import 'components/materialTheme/theme'

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare global {
    interface Window {
        jss: Jss
        toggleDevtools: () => void
    }
}

const jss = create({
    ...jssPreset(),
    insertionPoint: process.env.NODE_ENV === 'test' ? undefined : 'custom-insertion-point'
})
window.jss = jss

export const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onError: error => utils.defaultAjaxErrorHandler(gettext('Sorry! Saving data failed.'))(error)
        },
        queries: {
            onError: error => utils.defaultAjaxErrorHandler(gettext('Sorry! Retrieving data failed.'))(error),
            refetchOnWindowFocus: false,
            cacheTime: Infinity
        }
    }
})

const ReactQueryDevtools = React.lazy(() =>
    /* eslint-disable-next-line import/no-extraneous-dependencies */
    import('@tanstack/react-query-devtools').then(d => ({
        default: d.ReactQueryDevtools
    }))
)

export default function MaterialRoot({ children, seed }: { children: React.ReactNode; seed: string }) {
    // add action to all snackbars
    const notistackRef = useRef<SnackbarProvider>()
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key)
    }

    const [actualTheme, setActualTheme] = useState(window.currentTheme)

    const handleThemeChanged = () => {
        setActualTheme(window.currentTheme)
    }

    useEffect(() => {
        window.addEventListener('theme_changed', handleThemeChanged)
        return () => window.removeEventListener('theme_changed', handleThemeChanged)
    }, [])

    const [showDevtools, setShowDevtools] = React.useState(false)
    React.useEffect(() => {
        window.toggleDevtools = () => setShowDevtools(old => !old)
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <StylesProvider generateClassName={createGenerateClassName({ seed })} jss={jss}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={actualTheme}>
                        <SnackbarProvider
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            ref={notistackRef}
                            action={key => (
                                <IconButton color="inherit" onClick={onClickDismiss(key)} size="large">
                                    <CloseIcon />
                                </IconButton>
                            )}
                        >
                            {children}
                            {showDevtools && (
                                <React.Suspense fallback={null}>
                                    <ReactQueryDevtools />
                                </React.Suspense>
                            )}
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </StylesProvider>
        </QueryClientProvider>
    )
}
