import axios from 'axios'

const MIN_VALUE_LENGTH = 2

export default async function fetchSuggestions(value: string, signal?: AbortSignal) {
    if (value.length < MIN_VALUE_LENGTH) {
        return []
    }
    const response = await axios.get(window.Urls['search:search_as_you_type'](), {
        signal,
        params: { search: value }
    })
    return response.data
}
