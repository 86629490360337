/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import type { QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

type QueryParams = AxiosRequestConfig['params']

export default function useReactQuery<T>(
    url: string,
    queryKey: QueryKey,
    {
        queryParams,
        reactQueryOptions
    }: {
        queryParams?: QueryParams
        reactQueryOptions?: UseQueryOptions<T>
    } = {}
) {
    return useQuery<T>({
        queryFn: () => axios.get(url, { params: queryParams }).then(res => res.data),
        queryKey,
        ...reactQueryOptions
    })
}
