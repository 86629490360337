/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import { useActiveUser } from 'hooks/data/user'
import useCardStyles from 'styles/card'

import CaseActionsButton from './CaseActionsButton'

const useCaseStyles = makeStyles(theme => ({
    disabled: {
        color: `${theme.palette.text.disabled} !important`,
        cursor: 'not-allowed',
        '& a': { cursor: 'not-allowed' },
        '& button': { display: 'none' }
    }
}))

const useStyles = () => ({ ...useCaseStyles(), ...useCardStyles() })

export default function CaseTile({ theCase, disabled = false }: { theCase: Case; disabled?: boolean }) {
    const classes = useStyles()
    const { data: user } = useActiveUser()
    const noAccessTitle = disabled
        ? `${gettext('You do not have access to this case')}. ${theCase.accessInformation || ''}`
        : undefined
    const caseTitle = noAccessTitle || theCase.title
    const metadataTitle = noAccessTitle || theCase.metadata

    const caseUrl = [
        window.Urls.runCourse(theCase.viewerCourseModule),
        new URLSearchParams({
            caseId: theCase.id.toString()
        }).toString()
    ].join('?')

    return (
        <div className={clsx(classes.card, disabled && classes.disabled)} title={noAccessTitle}>
            <a href={disabled ? undefined : caseUrl} className="cardImage" rel="noreferrer">
                <img src={theCase.previewImage || window.Urls.static('images/case_image.jpg')} />
            </a>
            <a href={disabled ? undefined : caseUrl} className="cardContent" rel="noreferrer">
                <hgroup>
                    <Typography component="h4" className="overtitle">
                        {gettext('Case')}
                    </Typography>
                    <Typography component="h3" title={caseTitle} className="title" noWrap>
                        {theCase.title}
                    </Typography>
                </hgroup>
                <Typography title={metadataTitle} className="metadata">
                    {theCase.metadata}
                </Typography>
            </a>
            <div className="cardFooter">{user?.id && <CaseActionsButton theCase={theCase} />}</div>
        </div>
    )
}
