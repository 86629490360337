/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import Highlighter from 'components/highlighter/Highlighter'

/** Display the target audiences of content item. */
export default function ContentAuthors({
    contentItem,
    triggerMutation,
    name,
    label
}: {
    contentItem: Record<string, any>
    triggerMutation: (opts: { id: number; changes: Record<string, any> }) => void
    name: string
    label: string
}) {
    if (contentItem[name]) {
        const authorsString = interpolate(gettext(`${label}: %s`), [contentItem[name]])
        return (
            <EditableText
                contentItem={contentItem}
                triggerMutation={triggerMutation}
                name={name}
                defaultValue={contentItem[name]}
            >
                <Typography variant="body2" component="div">
                    <Highlighter searchWords={contentItem.highlights || []} textToHighlight={authorsString} />
                </Typography>
            </EditableText>
        )
    }
    return null
}
