/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'

import FormModal from './FormModal'

export default function ConfirmModal({
    title = gettext('Confirm'),
    contentText = '',
    children,
    open = true,
    submitText = gettext('Confirm'),
    submitDisabled = false,
    rejectText = gettext('Cancel'),
    onSubmit,
    onReject,
    variant = 'primary',
    object = {}
}: {
    title?: string
    contentText?: string
    children?: React.ReactNode
    open?: boolean
    submitText?: string
    submitDisabled?: boolean
    rejectText?: string
    onSubmit: (...args: any) => any
    onReject: (...args: any) => any
    variant?: React.ComponentProps<typeof FormModal>['variant']
    object?: object
}) {
    return (
        <FormModal
            title={title}
            contentText={contentText}
            submitText={submitText}
            submitDisabled={submitDisabled}
            rejectText={rejectText}
            open={open}
            onReject={onReject}
            onSubmit={() => onSubmit(object)}
            variant={variant}
        >
            {children}
        </FormModal>
    )
}
