/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */
import { Container } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles(theme => ({
    container: {
        paddingBlock: theme.spacing(6),
        paddingInline: 0,
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(2),
            paddingInline: theme.spacing(2)
        }
    },
    fullWidth: {
        paddingInline: theme.spacing(4)
    }
}))

export default function PageContainer({
    children,
    style = {},
    fullWidth = false
}: {
    children: React.ReactNode
    fullWidth?: boolean
    style?: React.CSSProperties
}) {
    const classes = useStyles()

    return (
        <Container
            maxWidth={fullWidth ? false : 'md'}
            className={clsx(classes.container, fullWidth && classes.fullWidth)}
            style={style}
        >
            {children}
        </Container>
    )
}
