/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Popover } from '@mui/material'
import React from 'react'

import { Tags } from './constants'
import useStyles from './styles/Popover'
import { getTag } from './utils'
import whitelist from './whitelist.json'

/* eslint-disable react/no-danger */
export default function InfoCirclePopover({
    termsUrl,
    anonymizeStudyDate,
    uploadFiles
}: {
    termsUrl: string
    anonymizeStudyDate: boolean
    uploadFiles: boolean
}) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'info-circle-popover' : undefined

    return (
        <React.Fragment>
            <Popover
                key="circle"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Box p={2}>
                    <h4>{gettext('Disclaimer')}</h4>
                    <div>
                        {gettext('Caution:')}
                        <ul>
                            <li>{gettext('Patient data burned into images will not be anonymized.')}</li>
                            {!anonymizeStudyDate && (
                                <li>{gettext('The DICOM tag StudyDate will not be anonymized.')}</li>
                            )}
                        </ul>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: interpolate(
                                gettext(
                                    'By providing this content I agree with the <a href="%(termsUrl)s">Terms of Use</a>.'
                                ),
                                { termsUrl },
                                true
                            )
                        }}
                    />
                    <div>
                        {uploadFiles
                            ? gettext('The values of the following DICOM tags are kept on upload:')
                            : gettext('The values of the following DICOM tags are kept:')}
                        <ul>
                            {whitelist.map(tag => {
                                if (tag === Tags.StudyDate && !anonymizeStudyDate) {
                                    return ''
                                }
                                const lookedUpTag = getTag(tag)
                                return lookedUpTag && <li key={tag}>{`${lookedUpTag.tag} - ${lookedUpTag.name}`}</li>
                            })}
                            <li>
                                {gettext(
                                    'as well as private SIEMENS tags in groups 21 and 29 and all tags in group 60 (overlay data).'
                                )}
                            </li>
                        </ul>
                    </div>
                </Box>
            </Popover>

            <FontAwesomeIcon className={classes.InfoCircleIcon} icon={faInfoCircle} onClick={handleClick} />
        </React.Fragment>
    )
}
