/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import type { Theme } from '@mui/material/styles'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import createGenerateClassName from '@mui/styles/createGenerateClassName'
import StylesProvider from '@mui/styles/StylesProvider'
import React, { useState, useEffect } from 'react'

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export default function MeVisReactComponentsMaterialRoot({ children, seed }: { children: JSX.Element; seed: string }) {
    const [actualTheme, setActualTheme] = useState(window.currentTheme)

    const handleThemeChanged = () => {
        setActualTheme(window.currentTheme)
    }

    useEffect(() => {
        window.addEventListener('theme_changed', handleThemeChanged)
        return () => window.removeEventListener('theme_changed', handleThemeChanged)
    }, [])

    return (
        <StylesProvider generateClassName={createGenerateClassName({ seed })} jss={window.jss}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={actualTheme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </StylesProvider>
    )
}
