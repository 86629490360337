/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

// a little function to help us with reordering the result
export const reorder = <T>(list: Array<T>, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const move = <T>(source: Array<T>, destination: Array<T>, sourceIndex: number, destinationIndex: number) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(sourceIndex, 1)

    destClone.splice(destinationIndex, 0, removed)

    return [sourceClone, destClone]
}
