/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Button } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import useStyles from 'components/buttonStyles'
import ConfirmModal from 'components/modals/ConfirmModal'
import utils from 'components/react_components/utils'
import { publishCourse, approveCourse } from 'pages/my_content/redux/coursesSlice'

export default function ChangeCourseStatusButton({
    course,
    iconType,
    buttonTooltip,
    action,
    confirmModalMessage,
    okButtonText
}: {
    course: Course
    okButtonText: string
    action: 'publish' | 'approve'
    buttonTooltip: string
    iconType: JSX.Element
    confirmModalMessage: string
}) {
    const [showModal, setShowModal] = useState(false)
    const dispatch = useDispatch()
    const classes = useStyles()

    const onOk = async course => {
        try {
            if (action === 'approve') {
                //@ts-ignore
                await dispatch(approveCourse(course.id)).unwrap()
            } else {
                //@ts-ignore
                await dispatch(publishCourse(course.id)).unwrap()
            }
        } catch (error) {
            utils.defaultAjaxErrorHandler(error)()
        } finally {
            closeModal()
        }
    }

    const openModal = () => {
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    return (
        <React.Fragment>
            <Button
                className={clsx([classes.button, classes.bordered])}
                data-testid={`${action}Course`}
                title={buttonTooltip}
                onClick={() => openModal()}
            >
                {iconType}
            </Button>

            <ConfirmModal
                contentText={confirmModalMessage}
                open={showModal}
                submitText={okButtonText}
                onReject={closeModal}
                onSubmit={onOk}
                object={course}
            />
        </React.Fragment>
    )
}
