/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'

import useStyles from 'components/buttonStyles'
import type { mutateModule } from 'components/editable/useModuleMutation'
import ConfirmModal from 'components/modals/ConfirmModal'
import SaveItemToList from 'components/SaveItemToList'
import type { CourseModule } from 'hooks/data/modules'

import CloneModuleModal from './CloneModuleModal'
import ModuleActionsMenu from './ModuleActionsMenu'

export default function ModuleActionsButton({
    module,
    onDelete,
    triggerMutation
}: {
    module: Partial<CourseModule>
    onDelete?: (m: CourseModule) => void
    triggerMutation: mutateModule
}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [cloneDialogOpen, setCloneDialogOpen] = useState(false)
    const [resetDialogOpen, setResetDialogOpen] = useState(false)
    const [saveDialogOpen, setSaveDialogOpen] = useState(false)
    const classes = useStyles()

    return (
        <React.Fragment>
            {module && anchorEl && (
                <ModuleActionsMenu
                    anchorEl={anchorEl}
                    module={module}
                    onDelete={onDelete}
                    onClone={() => setCloneDialogOpen(true)}
                    onClose={() => setAnchorEl(null)}
                    onResetToDraft={() => setResetDialogOpen(true)}
                    onSaveModule={() => setSaveDialogOpen(true)}
                    triggerMutation={triggerMutation}
                />
            )}
            {resetDialogOpen && (
                <ConfirmModal
                    contentText={gettext('Do you really want to reset this module to draft state?')}
                    open
                    submitText={gettext('YES')}
                    onReject={() => setResetDialogOpen(false)}
                    onSubmit={() => {
                        triggerMutation(
                            { module, changes: { status: 'draft' } },
                            { onSettled: () => setResetDialogOpen(false) }
                        )
                    }}
                />
            )}
            {saveDialogOpen && <SaveItemToList item={module} onClose={() => setSaveDialogOpen(false)} />}
            {cloneDialogOpen && <CloneModuleModal module={module} onClose={() => setCloneDialogOpen(false)} />}
            <Button
                variant="contained"
                className={clsx([classes.button, classes.bordered])}
                title={gettext('Edit Module')}
                onClick={event => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                <MoreVertIcon />
            </Button>
        </React.Fragment>
    )
}
