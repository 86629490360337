/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import axios from 'axios'
import type { FormApi } from 'final-form'
import { FORM_ERROR } from 'final-form'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import utils from 'components/react_components/utils'
import { closePage } from 'utils/formUtils'

import useUrls from './useUrls'

async function handleFileFields(values, fileFields, url, setUploadProgress) {
    let fileValues = pick(values, fileFields)
    fileValues = pickBy(fileValues, v => v instanceof FileList || v instanceof File)
    if (isEmpty(fileValues)) {
        return null
    }
    const formData = new FormData()
    for (const [key, value] of Object.entries(fileValues)) {
        formData.append(key, value instanceof FileList ? value[0] : value)
    }
    const response = await axios.patch(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: event => {
            // Don't bother showing percentage progress for small payloads
            if (event.total && event.total > 5000000) {
                setUploadProgress(Math.ceil((event.loaded / event.total) * 100.0))
            }
        }
    })
    return response
}

function getFieldErrors(error) {
    if (isObject(error.response.data)) {
        const errors = {
            ...error.response.data,
            [FORM_ERROR]: error.response.data.non_field_errors
        }
        return errors
    }
    return null
}

/** Handles common submit loop. Shows toast on success, error modal on error. Handles JSON and file fields. */
export default function useOnSubmit({
    detailFn,
    fileFields,
    id,
    listFn,
    mutate,
    redirectOnSaveFn,
    redirectOnSaveAndCloseFn,
    redirectOnPreviewFn,
    defaultRedirect = true,
    handleError = null
}: {
    detailFn: Function
    id?: number
    fileFields: Array<string>
    listFn?: Function
    mutate?: (data: any, revalidate: boolean) => void
    redirectOnSaveFn?: Function
    redirectOnSaveAndCloseFn?: Function
    redirectOnPreviewFn?: Function
    defaultRedirect?: boolean
    handleError?: Function
}): { onSubmit: (values: { action: 'save' | 'preview' }, form: FormApi) => void; uploadProgress: number } {
    const [uploadProgress, setUploadProgress] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const urls = useUrls()
    fileFields = fileFields || []

    return {
        onSubmit: async (values, form) => {
            try {
                const jsonValues = omit(values, fileFields)
                let response = await (id ? axios.put(detailFn(id), jsonValues) : axios.post(listFn(), jsonValues))
                response =
                    (await handleFileFields(values, fileFields, detailFn(response.data.id), setUploadProgress)) ||
                    response
                if (mutate) {
                    mutate(response.data, false)
                }
                form.reset(response.data)
                enqueueSnackbar(gettext('Changes have been saved'), { variant: 'success' })
                if (values.action === 'save') {
                    if (redirectOnSaveFn) {
                        // Rewrite history so the referer isn't the add page
                        window.history.replaceState(null, '', document.referrer)
                        window.location.assign(redirectOnSaveFn(response.data.id))
                    }
                    return undefined
                }

                if (values.action === 'preview') {
                    if (redirectOnPreviewFn) {
                        window.location.assign(redirectOnPreviewFn(response.data.id))
                    }
                    return undefined
                }

                if (redirectOnSaveAndCloseFn) {
                    window.location.assign(redirectOnSaveAndCloseFn(response.data.id))
                } else if (defaultRedirect) {
                    closePage(urls.myContent())
                }
                return undefined
            } catch (error) {
                // 400 means server validation failed. Show errors in form
                if (error.isAxiosError && error.response?.status === 400) {
                    return getFieldErrors(error)
                }
                if (handleError) {
                    handleError(error)
                    return undefined
                }
                utils.defaultAjaxErrorHandler(gettext('Error while saving'))(error)
                return undefined
            }
        },
        uploadProgress
    }
}
