/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import EditableMultiSelect from 'components/editable/EditableMultiSelect'
import { useTargetAudiences } from 'hooks/data/misc'

/** Display the target audiences of content item. */
export default function TargetAudiences({
    contentItem,
    triggerMutation
}: {
    contentItem: any
    triggerMutation: (...args: any) => void
}) {
    const { data: availableTargetAudiences } = useTargetAudiences()

    if (!availableTargetAudiences || !contentItem.targetAudiences) {
        return null
    }
    const label = `${ngettext('Target audience', 'Target audiences', contentItem.targetAudiences.length)}:`
    const textComponent = (
        <Typography variant="body2" component="div">
            {contentItem.targetAudiences.length === 0
                ? `${label} ${gettext('No target audience')}`
                : `${label} ${contentItem.targetAudiences.join(', ')}`}
        </Typography>
    )

    if (!triggerMutation) {
        return textComponent
    }

    return (
        <EditableMultiSelect
            contentItem={contentItem}
            triggerMutation={triggerMutation}
            name="targetAudiences"
            options={availableTargetAudiences.map(o => o.name)}
            defaultSelection={contentItem.targetAudiences}
            label={gettext('Target audiences')}
        >
            {textComponent}
        </EditableMultiSelect>
    )
}
