/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import {
    Close as CloseIcon,
    Lock as LockIcon,
    Public as PublicIcon,
    QueueOutlined as AddToListIcon
} from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import { useListEntries, useLists } from 'hooks/data/lists'
import { useActiveUser } from 'hooks/data/user'
import useUrls from 'hooks/useUrls'

const useStyles = makeStyles(theme => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: `${theme.typography.h6.lineHeight}rem`,
        paddingRight: 0
    },
    closeButton: {
        opacity: 0.5
    },
    newListButton: {
        justifyContent: 'flex-start',
        left: -6, // align with checkboxes
        width: '100%'
    },
    newListForm: {
        paddingTop: `${theme.spacing(1)} !important`,
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1)
    },
    lists: { maxHeight: 300 },
    listLabel: {
        maxWidth: '40ch',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
}))

export default function SaveItemToList({
    item,
    onClose
}: {
    item: Record<string, any>
    onClose: (...args: any) => any
}) {
    const { data: user } = useActiveUser()
    const { data: lists } = useLists({ user: user?.id }, Boolean(user))
    const { data: oldEntries } = useListEntries({ [`${item.model.toLowerCase()}`]: item.id })
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()
    const urls = useUrls()
    const classes = useStyles()

    const [showNewListForm, setShowNewListForm] = useState(false)

    const { mutate: createList } = useMutation({
        mutationFn: (newListData: { isPublic: boolean; title: string }) =>
            axios.post(urls['lists:list_list'](), newListData).then(res => res.data),
        onSuccess: newList => {
            queryClient.setQueriesData(['List', 'list', { user: user.id }], (oldLists: List[]) => [
                newList,
                ...oldLists
            ])
        }
    })

    const { mutate: addItemToList } = useMutation({
        mutationFn: (list: List) =>
            axios
                .post(urls['lists:listentry_list'](), { [item.model.toLowerCase()]: item.id, list: list.id })
                .then(res => res.data),
        onSuccess: newListEntry =>
            queryClient.setQueryData(
                ['ListEntry', 'list', { [`${item.model.toLowerCase()}`]: item.id }],
                (oldListEntries: object[]) => [newListEntry, ...oldListEntries]
            )
    })

    const { mutate: removeItemFromList } = useMutation({
        mutationFn: (list: List) =>
            axios.delete(urls['lists:listentry_detail'](oldEntries.find(e => e.list === list.id).id)),
        onSuccess: (_, list) =>
            queryClient.setQueryData(
                ['ListEntry', 'list', { [`${item.model.toLowerCase()}`]: item.id }],
                (oldListEntries: any[]) => oldListEntries.filter(e => e.list !== list.id)
            )
    })

    if (!lists || !oldEntries) {
        return null
    }

    const oldLists = oldEntries.map(e => e.list)

    const privacy = {
        Private: { icon: LockIcon, label: gettext('Private') },
        Public: { icon: PublicIcon, label: gettext('Public') }
    }
    function getPrivacyIcon(option) {
        const Icon = privacy[option]?.icon || null
        return <Icon />
    }

    function toggleList(list) {
        if (oldLists.includes(list.id)) {
            removeItemFromList(list, {
                onSuccess: () => {
                    enqueueSnackbar(interpolate(gettext('Removed from %s'), [list.title]), { variant: 'success' })
                }
            })
        } else {
            addItemToList(list, {
                onSuccess: () =>
                    enqueueSnackbar(interpolate(gettext('Added to %s'), [list.title]), {
                        variant: 'success'
                    })
            })
        }
    }

    async function addItemToNewList(event) {
        event.preventDefault()
        event.stopPropagation()
        createList(
            {
                isPublic: event.target.elements.privacy.value === 'Public',
                title: event.target.elements.title.value
            },
            {
                onSuccess: async newList => {
                    addItemToList(newList, {
                        onSuccess: () => {
                            enqueueSnackbar(interpolate(gettext('Added to %s'), [newList.title]), {
                                variant: 'success'
                            })
                            onClose()
                        }
                    })
                }
            }
        )
    }

    function getItemType() {
        if (item.model === 'Case') {
            return gettext('Case')
        }
        if (item.model === 'Course') {
            return gettext('Course')
        }
        return gettext('Module')
    }

    return (
        <Dialog onClose={onClose} open>
            <DialogTitle className={classes.title} variant="h6">
                {interpolate(
                    gettext('Add %(itemType)s to / Remove %(itemType)s from ...'),
                    {
                        itemType: getItemType()
                    },
                    true
                )}
                <IconButton className={classes.closeButton} onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.lists}>
                <FormGroup>
                    {lists.map(list => (
                        <Box key={list.id} display="flex" alignItems="center" justifyContent="space-between">
                            <FormControlLabel
                                control={
                                    <Checkbox checked={oldLists.includes(list.id)} onChange={() => toggleList(list)} />
                                }
                                label={list.title}
                                classes={{
                                    label: classes.listLabel
                                }}
                            />
                            {getPrivacyIcon(list.isPublic ? 'Public' : 'Private')}
                        </Box>
                    ))}
                </FormGroup>
            </DialogContent>
            {showNewListForm ? (
                <form onSubmit={addItemToNewList}>
                    <DialogContent className={classes.newListForm}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label={gettext('Title')}
                            name="title"
                            fullWidth
                            required
                        />
                        <FormControl>
                            <InputLabel id="privacy-label">{gettext('Privacy')}</InputLabel>
                            <Select
                                name="privacy"
                                labelId="privacy-label"
                                defaultValue="Private"
                                renderValue={(value: string) => privacy[value].label}
                            >
                                {Object.entries(privacy).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        <ListItemIcon>{getPrivacyIcon(key)}</ListItemIcon>
                                        <ListItemText primary={value.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions disableSpacing>
                        <Button variant="text" color="primary" type="submit">
                            {gettext('Create')}
                        </Button>
                    </DialogActions>
                </form>
            ) : (
                <DialogContent>
                    <Button
                        type="submit"
                        color="primary"
                        variant="text"
                        startIcon={<AddToListIcon />}
                        onClick={() => setShowNewListForm(true)}
                        className={classes.newListButton}
                    >
                        {gettext('Create new list')}
                    </Button>
                </DialogContent>
            )}
        </Dialog>
    )
}
