/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import Highlighter from 'components/highlighter/Highlighter'

export default function ClinicalAuthor({
    course,
    triggerMutation
}: {
    course: Course
    triggerMutation: (...args: any) => any
}) {
    if (course.clinicalAuthor) {
        const authorsString = interpolate(gettext('Clinical Author: %s'), [course.clinicalAuthor])
        return (
            <EditableText
                contentItem={course}
                triggerMutation={triggerMutation}
                name="clinicalAuthor"
                defaultValue={course.clinicalAuthor}
            >
                <Typography variant="body2" component="div">
                    <Highlighter searchWords={course.highlights} textToHighlight={authorsString} />
                </Typography>
            </EditableText>
        )
    }
    return null
}
