/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

const statusMap = {
    new: gettext('new'),
    draft: gettext('drafted'),
    approved: gettext('approved'),
    published: gettext('published'),
    rejected: gettext('rejected')
}

export default function PublishingStatus(props: { course: Course }) {
    const course = props.course
    if (course.status) {
        return <Typography variant="body2">{`${gettext('Publishing status')}: ${statusMap[course.status]}`}</Typography>
    }
    return null
}
