/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography, Box, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import SwiperCore, { Navigation } from 'swiper/core'

import ConfigurableCourseCarousel from 'components/ConfigurableCourseCarousel'
import SlideShow from 'components/SlideShow'
import UserCarousels from 'components/UserCarousels'
import { useCarouselConfigContext, useCarouselConfigs } from 'hooks/data/misc'
import { useActiveUser } from 'hooks/data/user'

import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Navigation])

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.only('xs')]: { marginTop: theme.spacing(4), marginBottom: theme.spacing(4) },
        [theme.breakpoints.up('sm')]: { margin: theme.spacing(4) }
    }
}))

export default function CoursesOverview() {
    const { data: allCarouselConfigs } = useCarouselConfigs()
    const { data: carouselConfigPermissions } = useCarouselConfigContext()
    const [displayedCarousels, setDisplayedCarousels] = useState(2)
    const { data: user } = useActiveUser()
    const classes = useStyles()
    if (!allCarouselConfigs || !carouselConfigPermissions || !user) {
        return null
    }
    const hasMore = displayedCarousels < allCarouselConfigs.length
    const userIsAuthenticated = Boolean(user.id)

    return (
        <React.Fragment>
            <SlideShow />
            <div className={classes.container}>
                <InfiniteScroll
                    style={{ overflow: 'hidden' }}
                    dataLength={displayedCarousels}
                    next={() => setDisplayedCarousels(Math.min(allCarouselConfigs.length, displayedCarousels + 3))}
                    hasMore={hasMore}
                    scrollableTarget="content-main"
                    loader={undefined}
                >
                    {userIsAuthenticated && (
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                {interpolate(gettext('Welcome, %s.'), [(user as AuthenticatedUser).userNameForDisplay])}
                            </Typography>
                            <Box my={2}>
                                <Divider />
                            </Box>

                            <UserCarousels />
                        </React.Fragment>
                    )}

                    {allCarouselConfigs.slice(0, displayedCarousels).map(config => (
                        <ConfigurableCourseCarousel
                            key={config.id}
                            carouselConfig={config}
                            isEditable={carouselConfigPermissions.canEditCarouselConfig}
                            userIsAuthenticated={userIsAuthenticated}
                            showShowAllButton
                        />
                    ))}
                </InfiniteScroll>
                {/* Empty placeholder div to ensure content can be scrolled */}
                {hasMore && <div style={{ height: 350 }} />}
            </div>
        </React.Fragment>
    )
}
