/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'

import ConfirmModal from 'components/modals/ConfirmModal'

export default function HostMismatchModal({ host = '' }) {
    const cookieName = 'hostMismatchDismissed'
    const [open, setOpen] = useState(false)

    const onReload = () => {
        const prefix = window.location.pathname.split('/')[1]
        const fixedURL = `${window.location.protocol}//${host}/${prefix}/`
        window.location.replace(fixedURL)
        setOpen(false)
    }
    const onIgnore = () => {
        Cookies.set(cookieName, true, { path: window.COOKIE_PATH })
        setOpen(false)
    }

    useEffect(() => {
        const cookie = Cookies.get(cookieName)
        if (!cookie && host !== window.location.host) {
            setOpen(true)
        }
    }, [host])

    return (
        <ConfirmModal
            title={gettext('Unexpected Host')}
            contentText={interpolate(
                gettext(
                    'The host in the address bar %s does not match the expected host %s. You are probably trying to use our website through a VPN gateway. This may cause unexpected problems.'
                ),
                [window.location.host, host]
            )}
            submitText={gettext('Reload page (recommended)')}
            rejectText={gettext('Continue at my own peril')}
            onSubmit={onReload}
            onReject={onIgnore}
            open={open}
        >
            <Typography>
                {gettext(
                    'You may either reload the page with the expected host (recommended solution) or continue with the current host.'
                )}
            </Typography>
        </ConfirmModal>
    )
}
