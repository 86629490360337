/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
    button: {
        width: 40,
        minWidth: 40,
        padding: 0,
        color: `${theme.palette.button.color} !important`,
        backgroundColor: theme.palette.button.background,
        '&:hover': {
            backgroundColor: theme.palette.button.background
        }
    },
    bordered: {
        border: `1px solid ${theme.palette.borderedButton}`
    }
}))

export default useStyles
