/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import makeStyles from '@mui/styles/makeStyles'

import { cardMediaQueries } from 'components/course_tile/mediaQueries'
import { multiLineEllipsis } from 'components/utilityStyles'

const useStyles = makeStyles(theme => ({
    card: {
        border: 'none',
        background: theme.palette.background.card,
        borderRadius: 2,
        paddingBottom: '54px !important',
        position: 'relative',
        top: 0,
        left: 0,
        '&.narrow': {
            ...cardMediaQueries(theme),
            paddingBottom: '0 !important'
        },
        '&:not(.narrow)': {
            '@media only screen and (min-width: 415px) and (max-width: 599px)': {
                maxWidth: 'calc(80% + 8px) !important'
            },
            '@media only screen and (min-width: 600px)': {
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '0 !important',
                alignItems: 'stretch !important'
            }
        },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& button, & .editListButton': { height: 38 },
        '& .wideProgress': {
            display: 'none'
        },
        '&:not(.narrow) .wideProgress': {
            '@media only screen and (min-width: 600px)': {
                height: 6,
                width: 'auto',
                background: 'rgba(0, 0 , 0, 0.15)',
                display: 'block',
                position: 'absolute',
                left: 250,
                right: 0,
                bottom: 0
            }
        },
        '& .narrowProgress': {
            height: 6,
            width: '100%',
            background: 'rgba(0, 0, 0, 0.15)'
        },
        '&:not(.narrow) .narrowProgress': {
            '@media only screen and (min-width: 600px)': {
                display: 'none'
            }
        },
        '& .progressBar': {
            background: theme.palette.primary.main,
            display: 'block',
            height: '100%'
        },
        '& .cardFooter': {
            boxSizing: 'border-box',
            /* .card-row .card .card-footer in urbanstudio's css */
            paddingTop: '0 !important',
            display: 'flex',
            textAlign: 'right',
            /* end */
            padding: '12px 16px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 54,
            overflow: 'hidden',
            justifyContent: 'space-between'
        },
        '&:not(.narrow) .cardFooter': {
            '@media only screen and (min-width: 600px)': {
                position: 'relative',
                bottom: 'auto',
                left: 'auto',
                right: 'auto',
                padding: '16px 16px 16px 0',
                height: 'auto',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignSelf: 'flex-end',
                boxSizing: 'content-box',
                flex: '0 0 40px',
                gap: 16
            }
        },

        '& .title': {
            fontSize: 16,
            fontWeight: 500
        },
        '& .overtitle': {
            opacity: 0.66,
            fontSize: 12,
            paddingBottom: 4,
            fontWeight: 300,
            textTransform: 'uppercase'
        },
        '& .cardContent': {
            display: 'block',
            padding: 16,
            flex: '1 1 auto',
            minHeight: 1
        },
        '&:not(.narrow) .cardContent': {
            '@media only screen and (min-width: 600px)': {
                maxWidth: 'calc(100% - 250px - 56px)',
                overflow: 'hidden',
                paddingBottom: 6,
                position: 'relative'
            }
        },
        '& .cardImage': {
            position: 'relative',
            display: 'block',
            height: 'auto',
            overflow: 'hidden !important',
            background: theme.palette.background.card,
            '& img': {
                display: 'block',
                width: '100%',
                borderRadius: '2px 2px 0 0',
                aspectRatio: '2 / 1',
                objectFit: 'cover'
            }
        },
        '&:not(.narrow) .cardImage': {
            '@media only screen and (min-width: 600px)': {
                width: 250,
                minWidth: 250,
                maxWidth: 250,
                borderRadius: '2px 0 0 0 !important',
                flex: '0 0 250px',
                '& img': {
                    borderRadius: '2px 0 0 0 !important'
                }
            }
        },

        '& .metadata': {
            fontSize: 12,
            ...multiLineEllipsis(2)
        }
    }
}))

export default useStyles
