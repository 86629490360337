/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

export function getInitialSearchString() {
    return new URLSearchParams(window.location.search).get('search') || ''
}

export function getSearchFilters(): SearchFilters {
    const searchParams = new URLSearchParams(window.location.search)
    return {
        keywords: searchParams.has('keywords') ? searchParams.getAll('keywords') : undefined,
        targetAudiences: searchParams.has('targetAudiences') ? searchParams.getAll('targetAudiences') : undefined,
        contentTypes: searchParams.has('contentTypes') ? searchParams.getAll('contentTypes') : undefined,
        publishingYears: searchParams.has('publishingYears') ? searchParams.getAll('publishingYears') : undefined,
        additionalFilters: searchParams.has('additionalFilters') ? searchParams.getAll('additionalFilters') : undefined
    }
}
