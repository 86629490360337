/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

// eslint-disable-next-line import/prefer-default-export
export function multiLineEllipsis(lines) {
    // Adapted from Youtube, even though this uses webkit prefixes, it has very good browser support
    return {
        display: '-webkit-box !important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: `${lines}`
    }
}
