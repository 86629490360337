/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box } from '@mui/material'
import React from 'react'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import CarouselPlaceholder from './CarouselPlaceholder'
import { useStyles } from './ConfigurableCourseCarousel'
import CourseCarouselHeader from './CourseCarouselHeader'

import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Navigation])

export default function TileCarousel({
    objects,
    title,
    url,
    renderTile
}: {
    objects?: { id: number }[]
    title: string
    url: string
    renderTile: (...args: any) => any
}) {
    const classes = useStyles()

    return (
        <Box mb={2}>
            <CourseCarouselHeader title={title} url={url} />
            {objects ? (
                <Swiper slidesPerView="auto" spaceBetween={30} navigation className={classes.swiper}>
                    {objects.map(object => (
                        <SwiperSlide className={classes.slide} key={object.id}>
                            {renderTile(object)}
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <CarouselPlaceholder />
            )}
        </Box>
    )
}
