/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'
import { createRoot } from 'react-dom/client'

import CookieDisclaimer from './cookie_disclaimer'

const container = document.createElement('div')
container.id = 'cookieDisclaimerRoot'
document.body.appendChild(container)
const root = createRoot(container)

root.render(<CookieDisclaimer />)
