/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
    logo: {
        boxSizing: 'border-box',
        aspectRatio: 'auto',
        maxHeight: theme.spacing(9),
        minWidth: theme.spacing(14),
        padding: '16px 20px 16px 8px',
        objectFit: 'contain',
        filter: theme.palette.invertImage,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 10,
            maxHeight: theme.spacing(8)
        }
    }
}))

export default useStyles
