/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import PageContainer from 'components/react_components/PageContainer'
import { useCarouselConfigs } from 'hooks/data/misc'
import useUrls from 'hooks/useUrls'
import { closePage } from 'utils/formUtils'

import CarouselConfigForm, { convertCoursesStringToIdArray } from './CarouselConfigForm'

function EditCarousel({ carouselConfig }: { carouselConfig: CarouselConfig }) {
    const { definition } = carouselConfig

    const [title, setTitle] = useState(carouselConfig.title)
    const [courses, setCourses] = useState(definition.courses ? definition.courses.toString() : '')
    const [keywords, setKeywords] = useState(('keywords' in definition && definition.keywords) || [])
    const [types, setTypes] = useState(('types' in definition && definition.types) || [])
    const [additionalFilters, setAdditionalFilters] = useState(
        ('additionalFilters' in definition && definition.additionalFilters) || []
    )
    const [targetAudiences, setTargetAudiences] = useState(
        ('targetAudiences' in definition && definition.targetAudiences) || []
    )
    const [publishingYears, setPublishingYears] = useState(
        ('publishingYears' in definition && definition.publishingYears) || []
    )

    const urls = useUrls()
    const queryClient = useQueryClient()

    const saveCarousel = useMutation({
        mutationFn: () =>
            axios
                .patch(urls.carouselconfigDetail(carouselConfig.id), {
                    title,
                    definition: {
                        keywords: keywords.length > 0 ? keywords : undefined,
                        types: types.length > 0 ? types : undefined,
                        additionalFilters: additionalFilters.length > 0 ? additionalFilters : undefined,
                        targetAudiences: targetAudiences.length > 0 ? targetAudiences : undefined,
                        publishingYears: publishingYears.length > 0 ? publishingYears : undefined,
                        courses: convertCoursesStringToIdArray(courses)
                    }
                })
                .then(res => res.data),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['CarouselConfig'] })
    })

    const handleSaveAndClose = () => {
        saveCarousel.mutate(undefined, { onSuccess: () => closePage(urls.home()) })
    }

    return (
        <PageContainer fullWidth>
            <CarouselConfigForm
                formTitle={gettext('Edit carousel')}
                values={{
                    title,
                    courses,
                    keywords,
                    types,
                    additionalFilters,
                    targetAudiences,
                    publishingYears: publishingYears as any
                }}
                setters={{
                    setTitle,
                    setCourses,
                    setKeywords,
                    setTypes,
                    setAdditionalFilters,
                    setTargetAudiences,
                    setPublishingYears
                }}
                handleSave={saveCarousel.mutate}
                handleSaveAndClose={handleSaveAndClose}
            />
        </PageContainer>
    )
}

export default function EditCarouselWrapper() {
    const { data: carouselConfigs } = useCarouselConfigs()
    const { id } = useParams<{ id: string }>()

    if (!carouselConfigs) {
        return null
    }

    return <EditCarousel carouselConfig={carouselConfigs.find(c => c.id === Number(id))} />
}
