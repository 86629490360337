/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'

const urls = window.Urls

export default function usePromoCodes(params?) {
    return useReactQuery<PromoCode[]>(urls['promocodes:promocodeList'](), ['PromoCode', 'list', params], {
        queryParams: params
    })
}

export function usePromoCode(promoCodeId) {
    return useReactQuery<PromoCode>(urls['promocodes:promocodeDetail'](promoCodeId), [
        'PromoCode',
        'detail',
        promoCodeId
    ])
}

export function usePromoCodeUsages(params) {
    return useReactQuery<PromoCodeUsage[]>(
        urls['promocodes:promocodeusageList'](),
        ['PromoCodeUsage', 'list', params],
        {
            queryParams: params
        }
    )
}
