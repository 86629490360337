/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    Select,
    Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'

import { ProgressState } from 'components/dicom_upload/constants'
import useReactQuery from 'hooks/react_query'

import { useDicomReceiverServerURL } from '../hooks/data/misc'
import useUrls from '../hooks/useUrls'

import UploadCasesCore from './UploadCasesCore'

const useStyles = makeStyles(theme => ({
    title: {
        margin: 0,
        padding: theme.spacing(2),
        paddingRight: theme.spacing(5)
    },
    spacingDialogActions: {
        padding: theme.spacing(2)
    },
    actionButton: {
        minWidth: theme.spacing(18)
    }
}))

export default function UploadCases({ close, course }: { close: (...args: any) => any; course: { id: number } }) {
    const classes = useStyles()
    const theme = useTheme()
    const urls = useUrls()
    const [progressState, setProgressState] = useState(ProgressState.IDLE)
    const [selectedCase, setSelectedCase] = useState(null)
    const { data: dicomReceiverURL } = useDicomReceiverServerURL()
    const params = { courseId: course.id }
    const { data: cases } = useReactQuery<{ id: number; title: string }[]>(
        urls.basicCaseList(),
        ['BasicCase', 'list', params],
        {
            queryParams: params
        }
    )

    if (!dicomReceiverURL || !cases) {
        return null
    }

    const onClose = () => {
        if (progressState === ProgressState.UPLOADING || progressState === ProgressState.PROCESSING) {
            return
        }
        close()
    }

    return (
        <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle className={classes.title} variant="h4">
                {gettext(
                    'Upload cases - Important: Do not close dialog or reload page while import and processing of images is not finished.'
                )}
            </DialogTitle>
            <Divider />
            <DialogContent style={{ display: 'grid' }}>
                <FormControl>
                    <Typography gutterBottom>{gettext('Select template case: ')}</Typography>
                    <Select
                        disabled={
                            progressState === ProgressState.UPLOADING || progressState === ProgressState.PROCESSING
                        }
                        variant="outlined"
                        displayEmpty
                        value={selectedCase}
                        onChange={event => setSelectedCase(Number(event.target.value))}
                        style={{ marginBottom: theme.spacing(3) }}
                    >
                        <MenuItem value={null}>---</MenuItem>
                        {cases.map(c => (
                            <MenuItem key={c.id} value={c.id}>
                                {c.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <UploadCasesCore
                    templateCase={selectedCase}
                    dicomReceiverUrl={dicomReceiverURL}
                    course={course}
                    progressStateChanged={setProgressState}
                />
            </DialogContent>
            <Divider />
            <DialogActions classes={{ spacing: classes.spacingDialogActions }}>
                <Button
                    className={classes.actionButton}
                    onClick={close}
                    disabled={progressState === ProgressState.UPLOADING || progressState === ProgressState.PROCESSING}
                >
                    {gettext('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
