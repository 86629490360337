/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import { validateCMECredits } from 'utils/validation'

export default function CMEPoints({
    course,
    triggerMutation
}: {
    course: Course
    triggerMutation: (opts: { id: number; changes: Partial<Course> }) => void
}) {
    if (!course.cmeCredits) {
        return null
    }
    const cmePoints = ngettext('CME point', 'CME points', course.cmeCredits)

    return (
        <EditableText
            contentItem={course}
            name="cmeCredits"
            defaultValue={course.cmeCredits}
            triggerMutation={triggerMutation}
            type="number"
            inputProps={{ step: 1, min: 0 }}
            validationSchema={validateCMECredits}
        >
            <Typography variant="body2" component="div">
                {`${cmePoints}: ${course.cmeCredits}`}
            </Typography>
        </EditableText>
    )
}
