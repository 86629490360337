/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useAtom } from 'jotai'
import { useState, useCallback } from 'react'

import { editModeAtom } from 'components/editable/globals'
import { useModuleActions } from 'hooks/data/coursemodules'
import { useCourseActions } from 'hooks/data/courses'
import type { CourseModule } from 'hooks/data/modules'
import { useActiveUser } from 'hooks/data/user'

const canEditLookup = {
    Course: useCanEditCourse,
    List: useCanEditList,
    Case: useCanEditCase,
    Choice: useCanEditChoice,
    Question: useCanEditQuestion
}

function useCanEditCase(theCase: Case) {
    return Boolean(theCase.actions.edit)
}

function useCanEditChoice() {
    return false
}

function useCanEditQuestion() {
    return false
}

function useCanEditCourse(course: Course, mouseEntered: boolean) {
    const { data: user } = useActiveUser()
    const isAuthenticated = Boolean(user?.id)
    // Some responses already include the course actions
    const { data: courseActions } = useCourseActions(course.id, isAuthenticated && mouseEntered && !course.actions)

    return Boolean(course.actions?.editCourseDetails || courseActions?.editCourseDetails)
}

function useCanEditModule(module: CourseModule, mouseEntered: boolean) {
    const { data: user } = useActiveUser()
    const isAuthenticated = Boolean(user?.id)
    const { data: moduleActions } = useModuleActions(module, isAuthenticated && mouseEntered)

    // Some modules do not have an edit page
    return Boolean(moduleActions?.edit || moduleActions?.delete)
}

function useCanEditList(list: List) {
    const { data: user } = useActiveUser()

    return user?.id === list.user
}

export default function useEditableInput(contentItem: Record<string, any>) {
    const [editMode] = useAtom(editModeAtom)
    const [isEditing, setIsEditing] = useState(false)
    const leaveEditingMode = useCallback(() => setIsEditing(false), [])
    const [mouseEntered, setMouseEntered] = useState(false)
    const canEdit =
        (contentItem.canEdit || (canEditLookup[contentItem.model] || useCanEditModule)(contentItem, mouseEntered)) &&
        editMode

    const enterEditingMode = useCallback(
        (event: MouseEvent) => {
            if (!canEdit) {
                return
            }
            event.stopPropagation()
            setIsEditing(true)
        },
        [canEdit]
    )

    return {
        canEdit,
        enterEditingMode,
        isEditing,
        leaveEditingMode,
        setMouseEntered
    }
}
