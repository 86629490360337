/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import path from 'path'

import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import { fromEvent } from 'file-selector'
import { flatten, flattenDeep } from 'lodash'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'

import { Errors, ProgressState } from 'components/dicom_upload/constants'
import { isJpeg, isMlimage, isPng, stripExtension, toNiceAscii } from 'components/dicom_upload/fileUtils'
import InfoCirclePopover from 'components/dicom_upload/InfoCirclePopover'
import {
    anonymizeDicomFile,
    checkErrorMessage,
    computeProgress,
    convertFiles,
    prepareConvertingFiles,
    readFile,
    receiveProcessingMetaData,
    renameFile,
    uploadFile,
    waitForProcessingIsFinished
} from 'components/dicom_upload/processFile'
import StatusPopover from 'components/dicom_upload/StatusPopover'
import styles, { ProgressIndicator } from 'components/dicom_upload/styles/DicomUploadCore'
import type { ViewerCourseModule } from 'hooks/data/modules'
import useUrls from 'hooks/useUrls'

import UploadSpinner from './UploadSpinner'

const useStyles = makeStyles(styles)

type FileData = {
    file: File
    path: string
    name: string
    originalFilename?: string
    ignored: boolean
    size: number
    uploaded: boolean
    uploadedBytes: number
}

type SortedFiles = Record<string, FileData[]>

export default function UploadCasesCore({
    course,
    dicomReceiverUrl,
    progressStateChanged,
    templateCase
}: {
    course: { id: number }
    dicomReceiverUrl: string
    progressStateChanged: (...args: any) => any
    templateCase: number
}) {
    const classes = useStyles()
    const urls = useUrls()

    const [progressState, setProgressState] = useState(ProgressState.IDLE)
    const [caseProgressState, setCaseProgressState] = useState<Record<string, string>>({})
    const [progressSummary, setProgressSummary] = useState('')
    const [statusMessages, setStatusMessages] = useState<string[]>([])
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    const [, forceUpdate] = useReducer(x => x + 1, 0)

    const signedTokenInterval = useRef<number>(null)
    const uploadId = useRef<number>(null)
    const signedToken = useRef<string>(null)
    const sortedFileData = useRef<SortedFiles>({})
    const fileData = useRef<FileData[]>([])
    const viewerCourseModule = useRef<ViewerCourseModule>(null)

    useEffect(() => {
        window.clearInterval(signedTokenInterval.current)
    }, [])

    useEffect(() => {
        progressStateChanged(progressState)
    }, [progressState, progressStateChanged])

    const resetState = () => {
        window.clearInterval(signedTokenInterval.current)
        sortedFileData.current = {}
        fileData.current = []
        setProgressState(ProgressState.IDLE)
        setProgressSummary('')
        setCaseProgressState({})
    }

    const logMessage = (message: string) => {
        setProgressSummary(message)
        setStatusMessages(prevStatusMessages => [...prevStatusMessages, message])
    }

    const logDetail = (message: string) => {
        setStatusMessages(prevStatusMessages => [...prevStatusMessages, message])
    }

    const logError = (message: string) => {
        const errorMessage = interpolate(gettext('Error: %s'), [message])
        setStatusMessages(prevStatusMessages => [...prevStatusMessages, errorMessage])
        setProgressSummary(errorMessage)
    }

    const anonymizeAndUploadFiles = async (caseId: number, caseTitle: string, caseFileData: FileData[]) => {
        await getSignedToken()
        if (!uploadId.current) {
            uploadId.current = new Date().getTime()
        }

        for (const [index, data] of caseFileData.entries()) {
            if (!data.uploaded && !data.ignored) {
                // We do want sequential upload in this case, so suppress the warning
                // eslint-disable-next-line no-await-in-loop
                await anonymizeAndUploadFile(index, data, caseId, caseTitle)
            }
        }
        if (caseFileData.every(f => f.uploaded || f.ignored)) {
            await startProcessing(caseId, caseFileData, caseTitle)
        }
    }

    const getSignedToken = async () => {
        if (signedToken.current) {
            return signedToken.current
        }
        try {
            await refreshSignedToken()
            signedTokenInterval.current = window.setInterval(refreshSignedToken, 10 * 60 * 1000)
            return signedToken.current
        } catch (e) {
            throw new Error(gettext('Uploading files is currently not possible. Please reload the page and try again.'))
        }
    }

    const refreshSignedToken = async () => {
        signedToken.current = (await axios.get(urls.getSignedToken())).data
    }

    const clearSignedToken = () => {
        window.clearInterval(signedTokenInterval.current)
        signedToken.current = null
        signedTokenInterval.current = null
    }

    const anonymizeAndUploadFile = async (index: number, fdata: FileData, caseId: number, caseTitle: string) => {
        const file = fdata.file
        try {
            const byteArray = await readFile(file)
            let resultFile = null

            if (isJpeg(byteArray)) {
                resultFile = renameFile({
                    byteArray,
                    fileEnding: '.jpg',
                    index,
                    originalFilename: file.name
                })
            } else if (isPng(byteArray)) {
                resultFile = renameFile({
                    byteArray,
                    fileEnding: '.png',
                    index,
                    originalFilename: file.name
                })
            } else if (isMlimage(byteArray)) {
                // keep original filename converted to ASCII, but ensure that extension is '.mlimage'
                const asciiOnlyFilenameWithoutExtension = toNiceAscii(stripExtension(file.name)) || index
                resultFile = renameFile({
                    byteArray,
                    fileEnding: '.mlimage',
                    index: asciiOnlyFilenameWithoutExtension,
                    originalFilename: file.name
                })
            } else {
                resultFile = anonymizeDicomFile({ byteArray, caseId, file, index })
            }

            sortedFileData.current[caseTitle][index].name = resultFile.name
            sortedFileData.current[caseTitle][index].originalFilename = resultFile.originalFilename
            forceUpdate()

            const onUploadProgress = (progressEvent: ProgressEvent) => {
                sortedFileData.current[caseTitle][index].uploadedBytes = progressEvent.loaded
                forceUpdate()
            }

            const token = signedToken.current
            const id = uploadId.current
            await uploadFile({
                caseId,
                dicomReceiverUrl,
                file: resultFile,
                index,
                onUploadProgress,
                signedToken: token,
                uploadId: id
            })
            sortedFileData.current[caseTitle][index].uploaded = true
            forceUpdate()
        } catch (err) {
            if (err.message === Errors.NotASupportedImageFile) {
                logMessage(
                    interpolate(gettext('"%s" does not appear to be a supported image file. Ignoring.'), [
                        // @ts-ignore
                        file.originalFilename || file.name
                    ])
                )
                sortedFileData.current[caseTitle][index].ignored = true
                forceUpdate()
            } else checkErrorMessage(err, file)
        }
    }

    const startProcessing = async (caseId: number, caseFileData: FileData[], caseTitle: string) => {
        setCaseProgressState(prevState => ({ ...prevState, [caseTitle]: gettext('Processing') }))
        if (!caseFileData.some(f => f.uploaded)) {
            setProgressState(ProgressState.SUCCESS)
            setCaseProgressState({})
            return
        }
        logMessage(
            interpolate(
                ngettext(
                    'Upload of %s file for case %s finished.',
                    'Upload of %s files for case %s finished.',
                    caseFileData.length
                ),
                [caseFileData.length, caseTitle]
            )
        )
        logMessage(
            interpolate(
                ngettext('Processing file for case %s...', 'Processing files for case %s ...', caseFileData.length),
                [caseTitle]
            )
        )
        setProgressState(ProgressState.PROCESSING)

        const token = signedToken.current
        const id = uploadId.current
        await prepareConvertingFiles({ caseId, dicomReceiverUrl, signedToken: token, uploadId: id })
        convertFiles({ caseId, dicomReceiverUrl, signedToken: token, uploadId: id })
        await waitForProcessingIsFinished({
            caseId,
            dicomReceiverUrl,
            signedToken: token,
            uploadId: id
        })
        const processingMetaData = await receiveProcessingMetaData({
            caseId,
            dicomReceiverUrl,
            signedToken: token,
            uploadId: id
        })
        const numberOfNewImages = processingMetaData.numberOfGeneratedImages + processingMetaData.numberOfNonDicomFiles
        setCaseProgressState(prevState => ({ ...prevState, [caseTitle]: gettext('Finished') }))
        logMessage(
            interpolate(
                ngettext(
                    'Processing finished. Added %s image to case %s.',
                    'Processing finished. Added %s images to case %s.',
                    numberOfNewImages
                ),
                [numberOfNewImages, caseTitle]
            )
        )
        if (processingMetaData.numberOfGeneratedImages > 0) {
            logDetail(
                interpolate(
                    ngettext(
                        'Generated %s image from the DICOM files for case %s.',
                        'Generated %s images from the DICOM files for case %s.',
                        processingMetaData.numberOfGeneratedImages
                    ),
                    [processingMetaData.numberOfGeneratedImages, caseTitle]
                )
            )
        }
        if (processingMetaData.numberOfIgnoredFiles > 0) {
            logDetail(
                interpolate(
                    ngettext(
                        '%s file was ignored (e.g. text files) or could not be processed (e.g. unsupported DICOM files).',
                        '%s files were ignored (e.g. text files) or could not be processed (e.g. unsupported DICOM files).',
                        processingMetaData.numberOfIgnoredFiles
                    ),
                    [processingMetaData.numberOfIgnoredFiles]
                )
            )
        }
        if (processingMetaData.numberOfNonDicomFiles > 0) {
            logDetail(
                interpolate(
                    ngettext(
                        '%s non-DICOM image file was recognized and added as-is.',
                        '%s non-DICOM image files were recognized and added as-is.',
                        processingMetaData.numberOfNonDicomFiles
                    ),
                    [processingMetaData.numberOfNonDicomFiles]
                )
            )
        }
    }

    const createCase = async (caseTitle: string) => {
        const newCaseResponse = await axios.post(urls.caseList(), {
            title: caseTitle,
            status: 'new',
            viewerCourseModule: viewerCourseModule.current.id
        })

        setCaseProgressState(prevState => ({ ...prevState, [caseTitle]: gettext('Created') }))

        logMessage(interpolate(gettext('Created new case with title %s'), [caseTitle]))

        return newCaseResponse.data
    }

    const sortFiles = () => {
        const sortedFiles: Record<string, FileData[]> = {}

        fileData.current.forEach(f => {
            const pathComponents = f.path.split(path.sep).filter(x => x)
            const newcaseTitle = pathComponents.length > 1 ? pathComponents[0] : 'New Case'
            if (sortedFiles[newcaseTitle]) {
                sortedFiles[newcaseTitle] = [...sortedFiles[newcaseTitle], f]
            } else {
                sortedFiles[newcaseTitle] = [f]
            }
        })
        return sortedFiles
    }

    const processDroppedFiles = async (fileAndFolderList: File[]) => {
        if (progressState !== ProgressState.UPLOADING) {
            resetState()
        }

        const files: File[] = flattenDeep(fileAndFolderList)
        setProgressState(ProgressState.UPLOADING)
        setProgressSummary(gettext('Uploading...'))

        const newFileDatas: FileData[] = files.map(file => ({
            file,
            // @ts-ignore
            path: file.path,
            name: file.name,
            ignored: false,
            size: file.size,
            uploaded: false,
            uploadedBytes: 0
        }))
        fileData.current = [...fileData.current, ...newFileDatas]
        sortedFileData.current = sortFiles()
        const newCaseIds: number[] = []

        try {
            const viewerCourseModules = (
                await axios.get(urls.basicViewercoursemoduleList(), {
                    params: { course: course.id }
                })
            ).data
            if (viewerCourseModules.length === 0) {
                const newViewerCourseModuleResponse = await axios.post(urls.viewercoursemoduleList(), {
                    title: gettext('New Module'),
                    status: 'new',
                    course: course.id
                })
                viewerCourseModule.current = newViewerCourseModuleResponse.data
            } else {
                viewerCourseModule.current = viewerCourseModules[0]
            }
            for (const entry of Object.entries(sortedFileData.current)) {
                /* eslint-disable no-await-in-loop */
                const newCase = await createCase(entry[0])
                newCaseIds.push(newCase.id)
                logMessage(interpolate(gettext('Started uploading files for case %s'), [newCase.title]))
                await anonymizeAndUploadFiles(newCase.id, entry[0], entry[1])
                if (templateCase) {
                    await axios.post(urls.casewithworkflowstepsCloneCase(newCase.id), {
                        templateCaseId: templateCase
                    })
                } else {
                    await axios.post(urls.casewithworkflowstepsAddDefaultWorkflowstep(newCase.id))
                }
                /* eslint-enable no-await-in-loop */
            }
            setProgressState(ProgressState.SUCCESS)

            // if only one case has been created redirect to edit case page after import has been finished, otherwise
            // redirect to my_content page
            if (newCaseIds.length === 1) {
                window.location.assign(
                    path.join(urls['publishing:editCaseRedirect'](newCaseIds[0]), '#', 'define_sequence')
                )
            } else {
                const myContentPath = path.join(urls.myContent(), '#', `${course.id}`)

                if (window.location.href.includes(myContentPath)) {
                    window.location.reload()
                } else {
                    window.location.assign(myContentPath)
                }
            }
        } catch (err) {
            logError(err.message)
            sortedFileData.current = {}
            fileData.current = []
            setProgressState(ProgressState.ERROR)
        } finally {
            clearSignedToken()
            uploadId.current = null
        }
    }

    const anyCaseProcessing = () => Object.values(caseProgressState).some(x => x === gettext('Processing'))

    const caseProgressMessages = () => {
        const created: string[] = []
        const processing: string[] = []
        const finished: string[] = []

        if (!caseProgressState) {
            return []
        }

        Object.entries(caseProgressState).forEach(e => {
            if (e[1] === gettext('Created')) {
                created.push(e[0])
            } else if (e[1] === gettext('Processing')) {
                processing.push(e[0])
            } else if (e[1] === gettext('Finished')) {
                finished.push(e[0])
            }
        })

        const result = []
        if (created.length > 0) {
            result.push(`${gettext('Cases Created')}: ${created.join(' --- ')}`)
        }
        if (processing.length > 0) {
            result.push(`${gettext('Cases Processing')}: ${processing.join(' --- ')}`)
        }
        if (finished.length > 0) {
            result.push(`${gettext('Cases Finished')}: ${finished.join(' --- ')}`)
        }

        return result
    }

    return (
        <Dropzone
            disabled={anyCaseProcessing()}
            // @ts-ignore
            getFilesFromEvent={event => fromEvent(event)}
            noClick
            onDrop={processDroppedFiles}
        >
            {({ getRootProps, getInputProps, inputRef, open }) => (
                <div {...getRootProps()} className={classes.Dropzone} style={{ height: 220 }}>
                    <InfoCirclePopover termsUrl={urls['termsofuse:show']()} anonymizeStudyDate uploadFiles={false} />
                    <StatusPopover statusMessages={statusMessages} />
                    <input {...getInputProps()} data-test="dicomUploadInput" />
                    <div className={classes.DropzoneText}>
                        {gettext(
                            'Drop image files (DICOM, JPEG, PNG) here to upload them.\nSome browsers (Chrome, Firefox) also support folders.\nOr'
                        )}
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                inputRef.current.webkitdirectory = false
                                open()
                            }}
                            type="button"
                            disabled={anyCaseProcessing()}
                            variant="outlined"
                        >
                            {gettext('Select Files')}
                        </Button>
                        <Button
                            onClick={() => {
                                inputRef.current.webkitdirectory = true
                                open()
                            }}
                            type="button"
                            disabled={anyCaseProcessing()}
                            className={classes.OpenFolderButton}
                            variant="outlined"
                        >
                            {gettext('Select a Folder')}
                        </Button>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                        {caseProgressMessages().map(m => (
                            <div key={m}>{m}</div>
                        ))}
                    </div>
                    <div className={classes.ProgressSummary}>{progressSummary}</div>
                    {anyCaseProcessing() && <UploadSpinner />}
                    <div
                        style={ProgressIndicator(
                            computeProgress(
                                flatten(Object.values(sortedFileData.current)).filter((f: FileData) => !f.ignored)
                            ),
                            progressState
                        )}
                        data-test={`progressIndicator.${progressState}`}
                    />
                </div>
            )}
        </Dropzone>
    )
}
