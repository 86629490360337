/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { DragIndicator as DragIcon } from '@mui/icons-material'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import React from 'react'

import EditableText from 'components/editable/EditableText'
import EditableThumbnail from 'components/editable/EditableThumbnail'
import type { mutateModule } from 'components/editable/useModuleMutation'
import Highlighter from 'components/highlighter/Highlighter'
import ContentRating from 'components/ratings/ContentRating'
import type { CourseModule } from 'hooks/data/modules'
import useCardStyles from 'styles/card'
import { preventFollowingLink } from 'utils/misc'
import { validateTitle as schema } from 'utils/validation'

import ModuleActionsButton from './ModuleActionsButton'

const useModuleStyles = makeStyles(theme => ({
    moduleNotApproved: {
        border: `1px solid ${theme.palette.primary.main} !important`
    },
    moduleApproved: {
        border: `1px solid transparent !important`
    },
    dragIndicator: {
        position: 'absolute',
        width: '48px',
        height: '48px',
        top: 0,
        left: 0,
        opacity: 0.6,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    typeAndContentRating: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    disabled: {
        color: `${theme.palette.text.disabled} !important`,
        cursor: 'not-allowed',
        '& a': { cursor: 'not-allowed' },
        '& button': { display: 'none' }
    }
}))

const useStyles = () => ({ ...useModuleStyles(), ...useCardStyles() })

interface ModuleTileProps {
    module: CourseModule
    onDelete?: (m: CourseModule) => void
    disabled?: boolean
    triggerMutation?: mutateModule
}

export function ModuleTile({ module, onDelete, disabled = false, triggerMutation = noop }: ModuleTileProps) {
    const classes = useStyles()
    const noAccessTitle = disabled
        ? `${gettext('You do not have access to this module')}. ${module.accessInformation || ''}`
        : undefined
    const moduleTitle = noAccessTitle || module.title
    const metadataTitle = noAccessTitle || module.metadata

    return (
        <React.Fragment>
            <div
                className={clsx([
                    module.status === 'new' || module.status === 'draft'
                        ? classes.moduleNotApproved
                        : classes.moduleApproved,
                    classes.card,
                    disabled && classes.disabled
                ])}
                title={noAccessTitle}
                data-testid="moduleTile"
            >
                <EditableThumbnail
                    className="cardImage"
                    contentItem={module}
                    name="thumbnail"
                    //@ts-ignore
                    triggerMutation={({ changes }) => triggerMutation({ module, changes })}
                >
                    <a href={disabled ? undefined : module.url} rel="noreferrer">
                        <img src={module.thumbnail} />
                        <div className="narrowProgress">
                            <span className="progressBar" style={{ width: `${module.completionInPercent ?? 0}%` }} />
                        </div>
                    </a>
                </EditableThumbnail>

                <div className={classes.dragIndicator} title={gettext('Drag the module tiles to change the order')}>
                    <DragIcon />
                </div>
                <a
                    href={disabled ? undefined : module.url}
                    className="cardContent"
                    rel="noreferrer"
                    onClick={preventFollowingLink}
                >
                    <hgroup>
                        <div className={classes.typeAndContentRating}>
                            <Typography component="h4" className="overtitle">
                                {module.type}
                            </Typography>
                            <ContentRating
                                content={module}
                                onContentRated={m =>
                                    triggerMutation({
                                        module,
                                        changes: { ratingsAvg: m.ratingsAvg, ratingsCount: m.ratingsCount }
                                    })
                                }
                                starSize="small"
                            />
                        </div>
                        <EditableText
                            contentItem={module}
                            defaultValue={module.title}
                            name="title"
                            //@ts-ignore
                            triggerMutation={({ changes }, ...rest) => triggerMutation({ module, changes }, ...rest)}
                            validationSchema={schema}
                        >
                            <Typography component="h3" className="title" title={moduleTitle} noWrap>
                                <Highlighter searchWords={module.highlights} textToHighlight={module.title} />
                            </Typography>
                        </EditableText>
                    </hgroup>
                    <Typography title={metadataTitle?.replaceAll(' // ', '\x0A')} className="metadata">
                        <Highlighter searchWords={module.highlights} textToHighlight={module.metadata} />
                    </Typography>
                </a>
                <div className="wideProgress">
                    <span className="progressBar" style={{ width: `${module.completionInPercent ?? 0}%` }} />
                </div>
                <div className="cardFooter">
                    <ModuleActionsButton module={module} onDelete={onDelete} triggerMutation={triggerMutation} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ModuleTile, isEqual)
