/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { alpha, buttonClasses } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { ThemeOptions } from '@mui/material/styles'
import { createTheme, darken } from '@mui/material/styles'
import merge from 'lodash/merge'

import { themeOptions, getPrimaryColor } from './commonTheme'

const createDarkThemeForDRG = () =>
    createTheme(
        merge(themeOptions('drg'), {
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            [`&.${buttonClasses.text}.${buttonClasses.colorPrimary}`]: {
                                color: 'white'
                            },
                            [`&.${buttonClasses.outlined}.${buttonClasses.colorPrimary}`]: {
                                color: 'white',
                                borderColor: alpha('#fff', 0.25)
                            },

                            [`&.${buttonClasses.contained}.${buttonClasses.colorSecondary}`]: {
                                color: grey.A100,
                                [`&:is(a.${buttonClasses.root})`]: { color: grey.A100 },
                                backgroundColor: grey['800'],
                                '&:hover': {
                                    backgroundColor: darken(grey['800'], 0.2)
                                }
                            }
                        }
                    }
                },
                MuiInputBase: {
                    styleOverrides: {
                        // Bootstrap nonsense
                        input: {
                            color: 'white !important',
                            // Cover white fill color set by chrome webkit on autofill fields
                            // by rendering an inner box shadow over the complete field.
                            // E.g. see https://github.com/mui-org/material-ui/issues/283
                            '&:-webkit-autofill': {
                                filter: 'none', // Needed for firefox
                                borderRadius: '0 !important',
                                WebkitBoxShadow: `0 0 0 100px #191919 inset !important`,
                                WebkitTextFillColor: 'white !important'
                            },
                            '&:disabled': {
                                color: 'rgba(255, 255, 255, 0.5) !important'
                            }
                        }
                    }
                }
            },
            palette: {
                mode: 'dark',
                primary: {
                    main: getPrimaryColor('drg'),
                    contrastText: '#fff'
                },
                footer: {
                    text: '#a0a0a0',
                    border: grey[800]
                },
                link: {
                    background: 'rgb(55, 55, 55)'
                },
                navbar: {
                    backgroundColor: '#333'
                },
                invertImage: 'invert(0)'
            }
        } as ThemeOptions)
    )

export default createDarkThemeForDRG
