/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'

import EditableText from 'components/editable/EditableText'

export default function CourseAccessInformation({
    course,
    triggerMutation
}: {
    course: Course
    triggerMutation: (...args: any) => any
}) {
    const informationString = interpolate(gettext('Access information: %s'), [course.accessInformation])

    if (course.accessInformation) {
        return (
            <EditableText
                contentItem={course}
                triggerMutation={triggerMutation}
                name="accessInformation"
                defaultValue={course.accessInformation}
            >
                <Typography variant="body2" component="div">
                    {informationString}
                </Typography>
            </EditableText>
        )
    }

    return null
}
