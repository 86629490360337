/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'

import useUrls from '../useUrls'

export function useList(id: number) {
    const urls = useUrls()
    return useReactQuery<List>(urls['lists:listDetail'](id), ['List', 'detail', id])
}

export function useMyLists() {
    const urls = useUrls()
    return useReactQuery<List[]>(urls['lists:myList_list'](), ['List', 'list', 'my'])
}

export function useLists(filters, enabled = true) {
    const urls = useUrls()
    return useReactQuery<List[]>(urls['lists:listList'](), ['List', 'list', filters], {
        queryParams: filters,
        reactQueryOptions: { enabled }
    })
}

export function useListEntries(filters, enabled = true) {
    const urls = useUrls()
    return useReactQuery<any[]>(urls['lists:listentryList'](), ['ListEntry', 'list', filters], {
        queryParams: filters,
        reactQueryOptions: { enabled }
    })
}

export function useListInvitationTemplate(listId) {
    const urls = useUrls()
    return useReactQuery<{ subject: string; body: string }>(urls['lists:listinvitation_template'](listId), [
        'ListInvitationTemplate',
        'detail',
        listId
    ])
}
