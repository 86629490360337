/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import {
    FirstPage as TopIcon,
    LastPage as BottomIcon,
    KeyboardArrowDown as DownIcon,
    KeyboardArrowUp as UpIcon,
    Settings as SettingsIcon
} from '@mui/icons-material'
import { Button, Divider, IconButton, Menu, MenuItem, Link as MuiLink, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import ConfirmDeletionModal from 'components/modals/ConfirmDeletionModal'
import useUrls from 'hooks/useUrls'

export const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        height: '42px',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '26px',
        paddingBottom: '32px',
        [theme.breakpoints.only('xs')]: {
            maxWidth: '100vw'
        }
    },
    flex: { display: 'flex' },
    alignCenter: { alignSelf: 'center' },
    editWrapper: { [theme.breakpoints.down('md')]: { display: 'none' } },
    button: {
        textTransform: 'lowercase',
        padding: '10px 24px',
        borderRadius: '3px',
        fontSize: '16px',
        opacity: 0.66,
        color: `${theme.palette.getContrastText(theme.palette.background.default)} !important`,
        lineHeight: 1.33
    },
    rotate: { transform: 'rotate(90deg)' },
    menuLink: {
        '&, & *': { color: `${theme.palette.secondary.main} !important` },
        '& a:hover': { backgroundColor: theme.palette.link.background }
    }
}))

export default function ConfigurableCourseCarouselHeader({
    carouselConfig,
    isEditable = false,
    showShowAllButton = false
}: {
    carouselConfig: CarouselConfig
    isEditable?: boolean
    showShowAllButton?: boolean
}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const isCarouselSettingsMenuOpen = Boolean(anchorEl)
    const [carouselBeingDeleted, setCarouselBeingDeleted] = useState(null)
    const urls = useUrls()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const classes = useStyles()
    const queryClient = useQueryClient()

    const openCarouselSettingsMenu = event => {
        setAnchorEl(event.currentTarget)
    }
    const closeCarouselSettingsMenu = () => {
        setAnchorEl(null)
    }

    const invalidateQueries = () => queryClient.invalidateQueries({ queryKey: ['CarouselConfig'] })

    const deleteCarousel = useMutation({
        mutationFn: () => axios.delete(urls.carouselconfigDetail(carouselConfig.id)),
        onSuccess: invalidateQueries
    })

    const moveTop = useMutation({
        mutationFn: () => axios.post(urls.carouselconfigMoveTop(carouselConfig.id)).then(res => res.data),
        onSuccess: invalidateQueries
    })

    const moveUp = useMutation({
        mutationFn: () => axios.post(urls.carouselconfigMoveUp(carouselConfig.id)).then(res => res.data),
        onSuccess: invalidateQueries
    })

    const moveDown = useMutation({
        mutationFn: () => axios.post(urls.carouselconfigMoveDown(carouselConfig.id)).then(res => res.data),
        onSuccess: invalidateQueries
    })

    const moveBottom = useMutation({
        mutationFn: () => axios.post(urls.carouselconfigMoveBottom(carouselConfig.id)).then(res => res.data),
        onSuccess: invalidateQueries
    })

    return (
        <React.Fragment>
            <div className={classes.header}>
                <Typography variant="subtitle1">{carouselConfig.title}</Typography>
                <div className={classes.flex}>
                    {isEditable && (
                        <div className={classes.editWrapper}>
                            <IconButton size="medium" className={classes.rotate} onClick={() => moveTop.mutate()}>
                                <TopIcon />
                            </IconButton>
                            <IconButton size="medium" onClick={() => moveUp.mutate()}>
                                <UpIcon />
                            </IconButton>
                            <IconButton size="medium" onClick={() => moveDown.mutate()}>
                                <DownIcon />
                            </IconButton>
                            <IconButton size="medium" className={classes.rotate} onClick={() => moveBottom.mutate()}>
                                <BottomIcon />
                            </IconButton>
                            <IconButton size="medium" onClick={openCarouselSettingsMenu} data-testid="carouselSettings">
                                <SettingsIcon />
                            </IconButton>
                        </div>
                    )}

                    {showShowAllButton && (
                        <Link className={classes.alignCenter} to={`single_carousel/${carouselConfig.id}/`}>
                            <Button size="small" variant="outlined" color="secondary" className={classes.button}>
                                {isSmallScreen ? gettext('all') : gettext('show all')}
                            </Button>
                        </Link>
                    )}
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={isCarouselSettingsMenuOpen}
                onClose={closeCarouselSettingsMenu}
                disableAutoFocusItem
            >
                <MenuItem component={Link} to={`add_carousel/${carouselConfig.order}`} className={classes.menuLink}>
                    {gettext('add new carousel above')}
                </MenuItem>
                <Divider light />
                <MenuItem component={Link} to={`add_carousel/${carouselConfig.order + 1}`} className={classes.menuLink}>
                    {gettext('add new carousel below')}
                </MenuItem>
                <Divider light />
                <MenuItem component={Link} to={`edit_carousel/${carouselConfig.id}`} className={classes.menuLink}>
                    {gettext('edit carousel')}
                </MenuItem>
                <Divider light />
                <MenuItem
                    component={MuiLink}
                    onClick={() => {
                        closeCarouselSettingsMenu()
                        setCarouselBeingDeleted(carouselConfig)
                    }}
                    className={classes.menuLink}
                >
                    {gettext('delete carousel')}
                </MenuItem>
            </Menu>
            {carouselBeingDeleted && (
                <ConfirmDeletionModal
                    contentText={interpolate(gettext('Do you really want to delete carousel "%s"?'), [
                        carouselBeingDeleted.title
                    ])}
                    onReject={() => setCarouselBeingDeleted(null)}
                    onSubmit={() => {
                        deleteCarousel.mutate()
                        setCarouselBeingDeleted(null)
                    }}
                />
            )}
        </React.Fragment>
    )
}
