/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import React from 'react'

import { LocalizedDatePicker } from 'components/materialUIWrappers'

export default function ExpirationDateInput() {
    return (
        <LocalizedDatePicker
            name="expirationDate"
            data-testid="expirationDate"
            label={gettext('Expiration date')}
            // Not implemented in this version :(
            // clearable
        />
    )
}
