/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import pick from 'lodash/pick'
import type { RefObject } from 'react'
import { useState, useCallback } from 'react'

export default function useApplyStyles(containerRef: RefObject<HTMLElement>) {
    const [inputStyle, setInputStyle] = useState({})
    const [formStyle, setFormStyle] = useState({})
    const [iconStyle, setIconStyle] = useState({})
    const [buttonStyle, setButtonStyle] = useState({})

    const onClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()
            const computedStyle = getComputedStyle(
                containerRef.current.querySelector('[class*=Typography]') || containerRef.current
            )
            setInputStyle({
                ...pick(computedStyle, ['fontFamily', 'fontSize', 'fontWeight', 'lineHeight', 'textTransform']),
                height: computedStyle.lineHeight
            })
            setFormStyle({
                height: containerRef.current.getBoundingClientRect().height,
                overflow: 'hidden',
                width: '100%'
            })
        },
        [containerRef]
    )

    const onMouseEnter = useCallback(() => {
        const computedStyle = getComputedStyle(
            containerRef.current.querySelector('[class*=Typography]') || containerRef.current
        )
        setButtonStyle({ height: computedStyle.lineHeight })
        setIconStyle({ height: computedStyle.lineHeight })
    }, [containerRef])

    return {
        inputStyle,
        formStyle,
        iconStyle,
        buttonStyle,
        applyStylesOnClick: onClick,
        copyStylesOnMouseEnter: onMouseEnter
    }
}
