/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import { cardMediaQueries } from 'components/course_tile/mediaQueries'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex'
    },
    placeholder: {
        flex: 'none',
        marginRight: '30px',
        marginBottom: '32px',
        ...cardMediaQueries(theme)
    }
}))

export default function CarouselPlaceholder() {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Skeleton variant="rectangular" className={classes.placeholder} />
            <Skeleton variant="rectangular" className={classes.placeholder} />
            <Skeleton variant="rectangular" className={classes.placeholder} />
            <Skeleton variant="rectangular" className={classes.placeholder} />
            <Skeleton variant="rectangular" className={classes.placeholder} />
        </div>
    )
}
