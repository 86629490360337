/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Autocomplete, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import noop from 'lodash/noop'
import React, { useCallback, useState, useRef } from 'react'

import useEditableInput from 'components/editable/useEditableInput'

import EditableComponentContainer from './EditableComponentContainer'
import useApplyStyles from './useApplyStyles'

const useStyles = makeStyles(theme => ({
    rootMenuItem: {
        '&:focus': {
            backgroundColor: theme.palette.action.hover
        }
    },
    root: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.background.default
    }
}))

interface EditableMultiSelectProps {
    contentItem: Record<string, any>
    children: React.ReactNode
    triggerMutation: (opts: { id: number; changes: Record<string, any> }, postMutation: { onSettled: Function }) => void
    options: Array<any>
    defaultSelection: Array<any>
    name: string
    editOnClick?: boolean
    freeSolo?: boolean
    label?: string
}

/** EditableMultiSelect is a React component that allows to select value inline */
export default function EditableMultiSelect({
    contentItem,
    children,
    triggerMutation = noop,
    options,
    defaultSelection,
    name,
    editOnClick = true,
    freeSolo = false,
    label = ''
}: EditableMultiSelectProps) {
    const classes = useStyles()
    const { canEdit, isEditing, enterEditingMode, leaveEditingMode, setMouseEntered } = useEditableInput(contentItem)

    const containerRef = useRef<HTMLDivElement>()
    const { iconStyle, buttonStyle, applyStylesOnClick, copyStylesOnMouseEnter } = useApplyStyles(containerRef)
    const [selectedValues, setSelectedValues] = useState(defaultSelection)

    const onClick = useCallback(
        event => {
            applyStylesOnClick(event)
            enterEditingMode(event)
        },
        [applyStylesOnClick, enterEditingMode]
    )

    const onMouseEnter = useCallback(() => {
        copyStylesOnMouseEnter()
        setMouseEntered(true)
    }, [copyStylesOnMouseEnter, setMouseEntered])

    const onClose = event => {
        if (event.key === 'Escape') {
            setSelectedValues(defaultSelection)
            leaveEditingMode()
        } else {
            triggerMutation(
                { id: contentItem.id, changes: { [name]: selectedValues } },
                { onSettled: leaveEditingMode }
            )
        }
    }

    return isEditing ? (
        <div className={classes.root} onClick={event => event.stopPropagation()}>
            <Autocomplete
                open
                size="small"
                multiple
                disableCloseOnSelect
                freeSolo={freeSolo}
                fullWidth
                forcePopupIcon
                disableClearable
                options={options || []}
                value={selectedValues}
                sx={{ padding: '0 important' }}
                onChange={(_, value) => {
                    // @ts-ignore
                    setSelectedValues(value)
                }}
                onClose={onClose}
                renderInput={params => (
                    <TextField
                        autoFocus
                        label={label}
                        variant="outlined"
                        placeholder={interpolate(gettext('Type or select %s'), [label])}
                        {...params}
                    />
                )}
            />
        </div>
    ) : (
        <EditableComponentContainer
            containerRef={containerRef}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            editOnClick={editOnClick}
            canEdit={canEdit}
            buttonStyle={buttonStyle}
            iconStyle={iconStyle}
        >
            {children}
        </EditableComponentContainer>
    )
}
