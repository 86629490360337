/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { FORM_ERROR } from 'final-form'
import React from 'react'

export function reactFinalFormAdapterOnSubmit(postForm) {
    return async function (values) {
        const formData = new FormData()
        for (const [key, value] of Object.entries<any>(values)) {
            formData.append(key, value)
        }
        const { errors, redirect, url } = await postForm({ data: formData })
        if (redirect) {
            return window.location.assign(url)
        }
        for (const [key, value] of Object.entries<any>(errors)) {
            errors[key] = value.map(e => e.message).join('/n')
        }
        if (errors.__all__) {
            errors[FORM_ERROR] = errors.__all__
            delete errors.__all__
        }
        return errors
    }
}

/**
 * Closes the page and returns to the previous page (i.e. the referrer) or a fallback URL
 */
export function closePage(fallbackUrl: string, referrer?: string) {
    try {
        // We don't want to redirect to the login page as it will just tell the user they are already logged in
        if (referrer && !referrer.includes(window.Urls.authLogin())) {
            return window.location.assign(referrer)
        }
        if (
            document.referrer &&
            !document.referrer.includes(window.Urls.authLogin()) &&
            document.referrer !== document.URL
        ) {
            return window.location.assign(document.referrer)
        }
        return window.location.assign(fallbackUrl)
    } catch (e) {
        return window.location.assign(fallbackUrl)
    }
}

export function Progress({ progress }: { progress: number }) {
    if (!progress) {
        return null
    }
    let progressString = ''
    if (progress === 100) {
        progressString = gettext('Almost done')
    } else {
        progressString = `${progress} %`
    }
    return <span style={{ marginLeft: 4, marginRight: 0, display: 'inline-block' }}>{`(${progressString})`}</span>
}
