/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import identity from 'lodash/identity'
import type { TextFieldProps } from 'mui-rff'
import { TextField } from 'mui-rff'
import React from 'react'

/**
 * This is a thin wrapper around mui-rff TextField
 * All it does is apply a workaround so that empty values are set to the empty string
 * instead of undefined.
 * See https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
 */
export default function TextFieldWrapper(props: TextFieldProps) {
    return (
        <TextField
            {...props}
            /* eslint-disable-next-line react/prop-types */
            fieldProps={{ ...props.fieldProps, parse: identity }}
            /* eslint-disable-next-line react/prop-types */
            inputProps={{ ...props.inputProps, 'aria-label': props.name }}
        />
    )
}
