/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Dialog, DialogContentText } from '@mui/material'
import type { ReactNode } from 'react'
import React from 'react'

import { ActionsArea, ContentArea, TitleArea } from './FormModal'

export default function InformationModal({
    title,
    subtitle,
    contentText = '',
    children,
    closeText = gettext('Close'),
    onClose,
    open = true
}: {
    title: ReactNode
    subtitle?: ReactNode
    contentText?: string
    children?: React.ReactNode
    closeText?: string
    onClose: (...args: any) => any
    open?: boolean
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            container={document.getElementById('content-main')}
            fullWidth
            data-testid="form-modal"
        >
            <TitleArea
                title={title}
                subtitle={
                    <DialogContentText component="span" variant="body1">
                        {subtitle}
                    </DialogContentText>
                }
                onReject={onClose}
            />
            <ContentArea contentText={contentText}>{children}</ContentArea>
            <ActionsArea onReject={onClose} rejectText={closeText} />
        </Dialog>
    )
}
