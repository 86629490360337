/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import useReactQuery from 'hooks/react_query'

const urls = window.Urls

export function usePrivacyLabel() {
    return useReactQuery<{ dataPrivacyLabel: string }>(urls.privacyLabel(), ['PrivacyLabel'])
}

export function useCarouselConfigs() {
    return useReactQuery<CarouselConfig[]>(urls.carouselconfigList(), ['CarouselConfig', 'list'])
}

export function useCarouselConfigContext() {
    return useReactQuery<{ dataPrivacyLabel: string; canEditCarouselConfig: boolean }>(urls.carouselConfigContext(), [
        'CarouselConfigContext'
    ])
}

export function useSlideShowConfigs() {
    return useReactQuery<Record<string, any>[]>(urls.slideshowconfigList(), ['SlideshowConfig', 'list'])
}

export function useDefaultKeywords() {
    return useReactQuery<DefaultKeyword[]>(urls['keywords:defaultkeyword_list'](), ['Keywords', 'list'])
}

export function useContentTypes(params?: any) {
    return useReactQuery<ContentType[]>(urls.contenttypeList(), ['ContentType', 'list', params], {
        queryParams: params
    })
}

function fetchAdditionalFilters() {
    return axios
        .get<
            { id: number; name: string; definition: { key: string; label: string; value: string } }[]
        >(urls.additionalfilterList())
        .then(res => res.data)
}

export function useAdditionalFilters() {
    return useQuery({ queryFn: fetchAdditionalFilters, queryKey: ['AdditionalFilter', 'list'] })
}

export function useSubscriptionFilters() {
    return useQuery({
        queryFn: fetchAdditionalFilters,
        queryKey: ['AdditionalFilter', 'list'],
        select: filters => filters.filter(f => f.definition)
    })
}

export function useTargetAudiences() {
    return useReactQuery<TargetAudience[]>(urls.targetaudienceList(), ['TargetAudience', 'list'])
}

export function useEmailTemplates(params) {
    return useReactQuery<EmailTemplate[]>(urls.emailtemplateList(), ['EmailTemplate', 'list', params], {
        queryParams: params
    })
}

export function useLocalizedTerms() {
    return useReactQuery<{
        content: string
        language: string
        markdownContent: string
        version: number
    }>(urls['termsofuse:localized_terms'](), ['Terms', 'list'])
}

export function useOverviewPages() {
    return useReactQuery<{ content: string; id: string; markdownContent: string }[]>(urls.overviewpageList(), [
        'OverviewPage',
        'list'
    ])
}

export function useDicomReceiverServerURL(): useQuery<string> {
    return useReactQuery(urls.dicomReceiverUrl(), ['DicomReceiverURL'])
}

export type NavbarContext = {
    isAuthenticated: boolean
    logoUrl: string
    registrationOpen: boolean
    userIsOnlyInTraineeGroup: boolean
    username: string
}

export function useNavbarContext() {
    return useReactQuery<NavbarContext>(urls.navbarContext(), ['NavbarContext'])
}
