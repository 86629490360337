/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { idSelectorOptions, newestFirst } from '../utils'

import type { StateType } from './store'

const urls = window.Urls

function fetchCourses() {
    return axios.get<object>(urls.courseContentCourseTileList()).then(res => res.data)
}

function fetchCourse(id) {
    return axios.get<object>(urls.courseContentCourseTileDetail(id)).then(res => res.data)
}

export const useCourseIds = () => {
    const fetchedAllCourses = useSelector((state: StateType) => state.courses.fetchedAllCourses)
    const result = useQuery({ queryFn: fetchCourses, queryKey: ['CourseTile', 'list'], enabled: !fetchedAllCourses })
    const dispatch = useDispatch()
    useEffect(() => {
        if (result.data) {
            dispatch(setAllCourses(result.data))
        }
    }, [dispatch, result.data])

    const courseIds = useSelector(selectCourseIds)
    return {
        ...result,
        data: fetchedAllCourses ? courseIds : undefined
    }
}

export const useCourse = id => {
    const course = useSelector((state: StateType) => selectCourseById(state, id))
    const result = useQuery({
        queryFn: () => fetchCourse(id),
        queryKey: ['CourseTile', 'detail', id],
        enabled: !course
    })
    const dispatch = useDispatch()
    useEffect(() => {
        if (result.data) {
            dispatch(addOrUpdateCourse(result.data))
        }
    }, [dispatch, result.data])

    return {
        ...result,
        data: course || result.data
    }
}

export const approveCourse = createAsyncThunk('courses/approveCourse', async (id: number, thunkAPI) => {
    await axios.post(window.Urls.courseApprove(id))
    return {
        ...selectCourseById(thunkAPI.getState() as StateType, id),
        status: 'approved'
    }
})

export const publishCourse = createAsyncThunk('courses/publishCourse', async (id: number, thunkAPI) => {
    await axios.post(window.Urls.coursePublish(id))
    return {
        ...selectCourseById(thunkAPI.getState() as StateType, id),
        status: 'published'
    }
})

const coursesAdapter = createEntityAdapter({
    sortComparer: newestFirst
})

const coursesSlice = createSlice({
    name: 'courses',
    initialState: coursesAdapter.getInitialState({ fetchedAllCourses: false }),
    reducers: {
        setAllCourses(state, action) {
            coursesAdapter.setAll(state, action.payload)
            state.fetchedAllCourses = true
        },
        addOrUpdateCourse(state, action) {
            coursesAdapter.upsertOne(state, action.payload)
        },
        resetCoursesSlice(state) {
            coursesAdapter.removeAll(state)
            state.fetchedAllCourses = false
        }
    },
    extraReducers: builder => {
        builder.addCase(approveCourse.fulfilled, coursesAdapter.setOne)
        builder.addCase(publishCourse.fulfilled, coursesAdapter.setOne)
    }
})

export default coursesSlice

export const { addOrUpdateCourse, setAllCourses, resetCoursesSlice } = coursesSlice.actions

export const {
    selectAll: selectAllCourses,
    selectIds: selectCourseIds,
    selectById: selectCourseById
} = coursesAdapter.getSelectors((state: StateType) => state.courses)

export const selectRegularCourses = createSelector(selectAllCourses, courses =>
    courses.filter(course => !course.videocollection)
)

export const selectUnpublishedCoursesIds = createSelector(
    selectRegularCourses,
    courses =>
        courses
            .filter(
                course => (course.invited || course.authored) && (course.status === 'draft' || course.status === 'new')
            )
            .map(c => c.id),
    idSelectorOptions
)

export const selectCoursesReadyForPublishingIds = createSelector(
    selectRegularCourses,
    courses => courses.filter(course => course.status === 'approved').map(c => c.id),
    idSelectorOptions
)

export const selectPublishedCoursesIds = createSelector(
    selectRegularCourses,
    courses =>
        courses.filter(course => course.status === 'published' && (course.authored || course.invited)).map(c => c.id),
    idSelectorOptions
)

export const selectMetafusionCoursesIds = createSelector(
    selectAllCourses,
    courses => courses.filter(course => course.videocollection).map(c => c.id),
    idSelectorOptions
)
