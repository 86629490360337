/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Link, Menu, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import React from 'react'

import type { mutateModule } from 'components/editable/useModuleMutation'
import { useModuleActions } from 'hooks/data/coursemodules'
import type { CourseModule } from 'hooks/data/modules'
import { useActiveUser } from 'hooks/data/user'

const useStyles = makeStyles(theme => ({
    menu: {
        '& li': {
            color: theme.palette.secondary.main
        }
    }
}))

function AcceptModule({ module, triggerMutation }: { module: Partial<CourseModule>; triggerMutation: mutateModule }) {
    const { enqueueSnackbar } = useSnackbar()
    return (
        <Link
            onClick={() =>
                triggerMutation(
                    { module, changes: { status: 'approved' } },
                    {
                        onSuccess: () =>
                            enqueueSnackbar(interpolate(gettext('%s has been accepted'), [module.title]), {
                                variant: 'success'
                            })
                    }
                )
            }
        >
            <MenuItem>{gettext('Accept Module')}</MenuItem>
        </Link>
    )
}

export default function ModuleActionsMenu({
    module,
    anchorEl,
    onDelete,
    onClone,
    onClose,
    onResetToDraft,
    onSaveModule,
    triggerMutation
}: {
    module: Partial<CourseModule>
    anchorEl: Element
    onDelete?: (m: Partial<CourseModule>) => void
    onClone?: () => void
    onClose: () => void
    onResetToDraft?: () => void
    onSaveModule: () => void
    triggerMutation: mutateModule
}) {
    const classes = useStyles()
    const open = Boolean(anchorEl)
    const { data: actions } = useModuleActions(module)
    const { data: user } = useActiveUser()
    const isAuthenticated = Boolean(user?.id)

    if (!actions) {
        return null
    }

    function getMenuItems() {
        const menuItems = []

        if (actions.accept) {
            menuItems.push(<AcceptModule module={module} triggerMutation={triggerMutation} />)
        }
        if (onClone && actions.clone) {
            menuItems.push(
                <Link onClick={() => onClone()} style={{ lineHeight: 0 }} key="Clone Module">
                    <MenuItem>{gettext('Clone Module')}</MenuItem>
                </Link>
            )
        }
        if (onResetToDraft && actions.resetToDraft) {
            menuItems.push(
                <Link onClick={() => onResetToDraft()} style={{ lineHeight: 0 }} key="Reset Module To Draft">
                    <MenuItem>{gettext('Reset Module To Draft')}</MenuItem>
                </Link>
            )
        }
        if (actions.edit) {
            menuItems.push(
                <Link href={actions.edit} style={{ lineHeight: 0 }} key="Edit Module">
                    <MenuItem>{gettext('Edit Module')}</MenuItem>
                </Link>
            )
        }
        if (actions.editViewerCourseModules) {
            menuItems.push(
                <Link href={actions.editViewerCourseModules} key="Edit case and module order">
                    <MenuItem>{gettext('Edit case and module order')}</MenuItem>
                </Link>
            )
        }
        if (onDelete && actions.delete) {
            menuItems.push(
                <MenuItem onClick={() => onDelete(module)} key="Delete Module">
                    {gettext('Delete Module')}
                </MenuItem>
            )
        }
        if (isAuthenticated) {
            menuItems.push(
                <MenuItem onClick={onSaveModule} key="Save to List">
                    {gettext('Add to / Remove from list')}
                </MenuItem>
            )
        }

        if (isEmpty(menuItems)) {
            return [<MenuItem key={0}>{gettext('No available actions')}</MenuItem>]
        }
        return menuItems
    }

    const menuItems = getMenuItems()
    return (
        <React.Fragment>
            <Menu className={classes.menu} onClick={onClose} open={open} anchorEl={anchorEl} onClose={onClose}>
                {menuItems}
            </Menu>
        </React.Fragment>
    )
}
