/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import React from 'react'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import { cardMediaQueries } from 'components/course_tile/mediaQueries'
import { usePublicCourses } from 'hooks/data/courses'
import { useSubscriptionFilters } from 'hooks/data/misc'

import CarouselPlaceholder from './CarouselPlaceholder'
import Header from './ConfigurableCourseCarouselHeader'
import CourseTile from './course_tile/CourseTile'
import useCourseMutation from './editable/useCourseMutation'

import 'swiper/swiper-bundle.min.css'

SwiperCore.use([Navigation])

export const useStyles = makeStyles(theme => ({
    swiper: {
        [theme.breakpoints.only('xs')]: {
            marginLeft: '0 !important',
            marginRight: '0 !important',
            maxWidth: '100vw'
        },
        '& .swiper-button-next, & .swiper-button-prev': {
            color: theme.palette.text.primary,
            background: `${alpha(theme.palette.background.card, 0.66)}`,
            borderColor: `${alpha(theme.palette.text.primary, 0.44)}`,
            borderWidth: 1,
            borderStyle: 'solid',
            width: 50,
            height: 50,
            transition: '.6s cubic-bezier(.25,.8,.25,1)'
        },
        '& .swiper-button-disabled': {
            display: 'none'
        },
        '& .swiper-button-next:after, & .swiper-button-prev:after': {
            fontSize: 18,
            fontWeight: 'bold'
        },
        '& .swiper-button-next': {
            right: -51,
            transitionProperty: 'right',
            borderRadius: '4px 0 0 4px',
            borderRight: 'none',
            [theme.breakpoints.down('md')]: {
                right: 0
            }
        },
        '& .swiper-button-prev': {
            left: -51,
            transitionProperty: 'left',
            borderRadius: '0 4px 4px 0',
            borderLeft: 'none',
            [theme.breakpoints.down('md')]: {
                left: 0
            }
        },
        '&:hover .swiper-button-next': {
            right: 0
        },
        '&:hover .swiper-button-prev': {
            left: 0
        }
    },
    slide: {
        width: 'auto !important' // Override rule from swiper library. It caused a bug in Firefox.
    },
    courseId: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: theme.spacing(0.5),
        padding: `0 ${theme.spacing(2)}`,
        borderRadius: '2px',
        backgroundColor: theme.palette.background.paper,
        opacity: 0.8,
        zIndex: theme.zIndex.drawer + 1
    },
    emptyPreview: {
        ...cardMediaQueries(theme),
        display: 'flex',
        width: 'auto !important',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        marginBottom: '10px',
        background: 'transparent'
    }
}))

export function useCarouselCourses(carouselConfig = { definition: {} }) {
    const { data: subscriptionFilters } = useSubscriptionFilters()
    // Assume 0 or 1 subscription filters
    const { data } = usePublicCourses(getQueryParams(carouselConfig.definition, subscriptionFilters?.[0]), {
        enabled: Boolean(subscriptionFilters)
    })

    return data
}

export function getQueryParams(carouselConfigDefinition, subscriptionFilter?) {
    let params = {} as Record<string, any>
    const { courses, keywords, additionalFilters, types, targetAudiences, publishingYears } = carouselConfigDefinition
    if (Array.isArray(courses) && courses.length > 0) {
        params.id__in = courses.join(',')
    } else {
        if (Array.isArray(keywords) && keywords.length > 0) {
            params.keywords__name__in = keywords.join(',')
        }
        if (Array.isArray(additionalFilters) && additionalFilters.length > 0) {
            const regularFilters = additionalFilters.filter(f => f !== subscriptionFilter?.name)
            if (regularFilters.length) {
                params.additionalFilters__name__in = regularFilters.join(',')
            }
            if (subscriptionFilter && additionalFilters.includes(subscriptionFilter.name)) {
                params.subscriptions__code = subscriptionFilter.definition.value
            }
        }
        if (Array.isArray(types) && types.length > 0) {
            params.type__name__in = types.join(',')
        }
        if (Array.isArray(targetAudiences) && targetAudiences.length > 0) {
            params.targetAudiences__name__in = targetAudiences.join(',')
        }
        if (Array.isArray(publishingYears) && publishingYears.length > 0) {
            params.publishingYear__in = publishingYears.join(',')
        }
    }
    if (isEmpty(params)) {
        params = { id__in: [-1] }
    }
    return params
}

export function ConfigurableCourseCarousel({
    carouselConfig,
    componentProps,
    userIsAuthenticated = false,
    preview = false,
    isEditable = false,
    showShowAllButton = false
}: {
    carouselConfig: CarouselConfig
    componentProps?: object
    userIsAuthenticated?: boolean
    preview?: boolean
    isEditable?: boolean
    showShowAllButton?: boolean
}) {
    const courses = useCarouselCourses(carouselConfig)
    const classes = useStyles()
    const patchCourse = useCourseMutation()

    if (!courses && !preview) {
        return (
            <React.Fragment>
                <Header carouselConfig={carouselConfig} />
                <CarouselPlaceholder />
            </React.Fragment>
        )
    }

    if (!courses || courses.length === 0) {
        if (preview) {
            return (
                <div className={classes.emptyPreview}>
                    <Typography variant="h2">{gettext('No course matches the filter criteria')}</Typography>
                </div>
            )
        }
        return null
    }

    return (
        <Box mb={2}>
            {!preview && (
                <Header carouselConfig={carouselConfig} isEditable={isEditable} showShowAllButton={showShowAllButton} />
            )}
            <Swiper slidesPerView="auto" spaceBetween={30} navigation className={classes.swiper}>
                {courses.map(course => (
                    <SwiperSlide className={classes.slide} key={course.id}>
                        {preview && (
                            <div className={classes.courseId}>
                                <Typography>{course.id}</Typography>
                            </div>
                        )}
                        <CourseTile
                            course={course}
                            preview={preview}
                            userIsAuthenticated={userIsAuthenticated}
                            triggerMutation={patchCourse.mutate}
                            {...componentProps}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    )
}

export default React.memo(ConfigurableCourseCarousel, isEqual)
