/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import useReactQuery from 'hooks/react_query'
import useUrls from 'hooks/useUrls'

export default function useCaseActions(caseId: number, enabled: boolean) {
    const urls = useUrls()
    return useReactQuery<{
        clone?: string
        delete?: string
        edit?: string
        editViewerCourseModules?: string
        preview?: string
        resetToDraft?: string
    }>(urls.caseActions(caseId), ['caseActions', 'detail', caseId], {
        reactQueryOptions: { enabled }
    })
}
