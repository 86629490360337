/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Typography } from '@mui/material'
import React from 'react'
import * as Yup from 'yup'

import EditableText from 'components/editable/EditableText'

const currentYear = new Date().getFullYear()
const schema = Yup.object().shape({
    publishingYear: Yup.number()
        .min(1900, gettext('Earliest allowed year is 1900'))
        .max(currentYear + 1, gettext('Latest allowed year is ') + (currentYear + 1))
        .required(gettext('Required'))
        .typeError(interpolate(gettext('Publishing year must be between 1900 and %s'), [currentYear + 1]))
})

interface PublishingYearProps {
    contentItem: Record<string, any>
    triggerMutation: (...args: any) => void
}

export default function PublishingYear({ contentItem, triggerMutation }: PublishingYearProps) {
    if ('publishingYear' in contentItem && contentItem.publishingYear) {
        return (
            <EditableText
                contentItem={contentItem}
                triggerMutation={triggerMutation}
                name="publishingYear"
                defaultValue={contentItem.publishingYear}
                type="number"
                inputProps={{ min: 1900, max: new Date().getFullYear() + 1, step: 1 }}
                validationSchema={schema}
            >
                <Typography variant="body2" component="div">{`${gettext('Publishing year')}: ${
                    contentItem.publishingYear
                }`}</Typography>
            </EditableText>
        )
    }
    return null
}
