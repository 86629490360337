/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import PageContainer from 'components/react_components/PageContainer'
import useUrls from 'hooks/useUrls'

import CarouselConfigForm from './CarouselConfigForm'

export default function AddCarousel() {
    const { index } = useParams<{ index: string }>()

    const [title, setTitle] = useState(gettext('New carousel'))
    const [courses, setCourses] = useState('')
    const [keywords, setKeywords] = useState([])
    const [types, setTypes] = useState([])
    const [additionalFilters, setAdditionalFilters] = useState([])
    const [targetAudiences, setTargetAudiences] = useState([])
    const [publishingYears, setPublishingYears] = useState([])
    const queryClient = useQueryClient()

    const urls = useUrls()
    const history = useHistory()

    const saveNewConfig = useMutation({
        mutationFn: async () => {
            let newConfig = (
                await axios.post(urls.carouselconfigList(), {
                    title,
                    definition: isEmpty(courses)
                        ? {
                              keywords: keywords.length > 0 ? keywords : undefined,
                              types: types.length > 0 ? types : undefined,
                              additionalFilters: additionalFilters.length > 0 ? additionalFilters : undefined,
                              targetAudiences: targetAudiences.length > 0 ? targetAudiences : undefined,
                              publishingYears: publishingYears.length > 0 ? publishingYears : undefined
                          }
                        : { courses: JSON.parse(`[${courses}]`) }
                })
            ).data
            newConfig = (await axios.post(urls.carouselconfigMoveTo(newConfig.id), { to: Number(index) })).data
            return newConfig
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['CarouselConfig'] })
    })

    const handleSave = () => {
        saveNewConfig.mutate(undefined, { onSuccess: newConfig => history.push(`/edit_carousel/${newConfig.id}`) })
    }

    const handleSaveAndClose = () => {
        saveNewConfig.mutate(undefined, { onSuccess: () => history.push('/') })
    }

    return (
        <PageContainer fullWidth>
            <CarouselConfigForm
                formTitle={gettext('Add new carousel')}
                values={{ courses, title, keywords, types, additionalFilters, targetAudiences, publishingYears }}
                setters={{
                    setCourses,
                    setTitle,
                    setKeywords,
                    setTypes,
                    setAdditionalFilters,
                    setTargetAudiences,
                    setPublishingYears
                }}
                handleSave={handleSave}
                handleSaveAndClose={handleSaveAndClose}
            />
        </PageContainer>
    )
}
