/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { atom } from 'jotai'

// eslint-disable-next-line import/prefer-default-export
export const editModeAtom = atom(true)
